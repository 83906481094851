import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MInsuranceAggregation from "../../Pages/Mobile/MProductPages/MInsuranceAggregation/MInsuranceAggregation";
import InsuranceAggregation from "../../Pages/Desktop/ProductPages/InsuranceAggregation/InsuranceAggregation";

const IGContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MInsuranceAggregation/> : <InsuranceAggregation />;
};

export default IGContainer;
