import React, { useState } from 'react'
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./MHomepage.scss"
import MNavbar from '../../../Components/Mobile/MNavbar/MNavbar';
import MFooter from '../../../Components/Mobile/MFooter/MFooter';
import CountUp from "react-countup";
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import RKTextField from '../../../Components/FieldTypes/RKTextField/RKTextField';
import CustomButton from '../../../Components/CustomButton/CustomButton';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ALL_ROUTES } from '../../../Routes/AllRoutes';
import { useNavigate } from 'react-router-dom';

const MHomepage = () => {
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate()

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const brandsresponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 6,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 3,
      slidesToSlide: 1,
    },
  };
  const headerSliderArray = [
    {
      header: (
        <>
          Global Digital Insurance <span>Distribution</span>
        </>
      ),
      desc: "Leveraging technology to revolutionize the insurance industry and provide accessible, efficient, and customer-centric insurance solutions.",
      img_url: "./images/header_img2.svg",
    },
    {
      header: (
        <>
          Elevating efficiency, empowering insurance{" "}
          <span>Business & Sales</span>{" "}
        </>
      ),
      desc: "Harnessing advanced technologies and data-driven strategies to optimize operations, enhance productivity, and enable insurance professionals to thrive in their business",
      img_url: "./images/header_img3.svg",
    },
    {
      header: (
        <>
          Accelerating <span>insurance innovation-</span> simplicity meets
          agility
        </>
      ),
      desc: "Combining simplicity and agility to create streamlined processes and flexible solutions that meet the evolving needs of the insurance industry and its customers.",
      img_url: "./images/header_img1.svg",
    },
  ];
  const ourPartners = [
    {
      partner_img_url: "images/ahalia.svg",
    },
    {
      partner_img_url: "images/motilal.svg",
    },
    {
      partner_img_url: "images/jbboda.svg",
    },
    {
      partner_img_url: "images/jrk.svg",
    },
    {
      partner_img_url: "images/quickbima.svg",
    },
    {
      partner_img_url: "images/samyag.svg",
    },
    {
      partner_img_url: "images/auxilium.svg",
    },
    {
      partner_img_url: "images/shareIndia.svg",
    },
    {
      partner_img_url: "images/swastika.svg",
    },
    {
      partner_img_url: "images/bharatre.svg",
    },
    {
      partner_img_url: "images/policyPariwaar.svg",
    },
    {
      partner_img_url: "images/masuraksha.svg",
    },
    {
      partner_img_url: "images/instantbeema.svg",
    },
  ];
  const ourFeatureBoxArray = [
    {
      img_url: "./images/ibd_icon1.svg",
      heading: 'Comprehensive Insurance Product Basket',
      desc: 'Our comprehensive insurance product basket ensures the brokers to offer a wide range of coverage options to their clients, making them a one-stop-shop for insurance needs. This not only enhances customer satisfaction but also increases revenue opportunities for brokers.',
      bluebackground: '',
    },
    {
      img_url: "./images/ibd_icon2.svg",
      heading: 'Functional & Business Expertise',
      desc: 'Our expertise across product lines equips insurance brokers with the knowledge and tools needed to navigate the complexities of different insurance products effectively. This further allows brokers to provide valuable advice to their clients, boosting their credibility and client trust.',
      bluebackground: 'blugbg',
    },
    {
      img_url: "./images/ibd_icon3.svg",
      heading: 'Innovation & Future Proofing',
      desc: 'We are committed to continuous innovation, ensuring that insurance brokers are equipped with the latest technological advancements, keeping them ahead of industry trends and future-proofing their operations. Our innovation centric teams endleslly work towards education, training & adoption of such advanced systems.',
      bluebackground: '',
    },
    {
      img_url: "./images/ibd_icon4.svg",
      heading: 'Collaborative Tech Ecosystem',
      desc: 'Insurance brokers rely on us as their technology providers because our collaborative tech ecosystem not only fosters industry innovation but also ensures the utmost data security and sanctity, giving them the confidence to operate in an increasingly data-driven landscape.',
      bluebackground: 'blugbg',
    },
  ]
  const servicesOverViewArray = [
    {
      image_url: "./images/service_img_one.png",
      heading: "Customer Product Managment",
      para: "Tailored insurance solutions designed to meet unique needs, leveraging technology and innovation - Product Strategy & Designing, Prototyping, Software Development & Testing",
    },
    {
      image_url: "./images/service_img_two.png",
      heading: "Digitisation of Insurance Business",
      para: "Embracing digital innovation, driving the digitization of insurance business, revolutionizing processes, enhancing customer experience, and optimizing operational efficiency",
    },
    {
      image_url: "./images/service_img_three.png",
      heading: "Agency Manangement Services",
      para: "Revolutionizing insurance agency operations through cutting-edge tools & solutions, empowering agents to effectively manage policies, sales, & customer relationships in a streamlined and efficient manner",
    },
    {
      image_url: "./images/service_img_four.png",
      heading: "Lead Generation & Digital Marketing",
      para: "Harnesses targeted marketing strategies to generate high-intent, high-quality leads, driving customer acquisition and maximizing ROI",
    },
  ]
  const clientFeedbackArray = [
    {
      client_profile_pic: './images/testimonial_icon1.svg',
      client_name: "Mr. Manmoar lal",
      organization_name: "- JB BODA",
      feedback_desc: "Evervent's platforms are a game-changer in the insurance industry. They offers a user-friendly interface, real-time quotes, and customizable coverage options. Their platforms have simplified the insurance purchasing process, saving us time and money. I highly recommend Evervent for a hassle-free and convenient insurance experience.",
      rating_points: 5,
    },
    {
      client_profile_pic: "./images/testimonial_icon2.svg",
      client_name: "Mr. Baljit Singh",
      organization_name: "- BHARAT RE",
      feedback_desc:
        "I have been a client of Evervent for many years now, and I cannot recommend them enough. Their products and services are top-notch and have exceeded my expectations every time. The team is highly professional and always goes above and beyond to ensure my satisfaction. They truly care about their customers and it shows in their work. I have never had any issues with Evervent and I have complete trust in their abilities. I highly recommend them to anyone looking for quality and reliability.",
      rating_points: 4,
    },
    {
      client_profile_pic: "./images/testimonial_icon3.svg",
      client_name: "  Mr. Bharampal Singh",
      organization_name: " - Instant Beema",
      feedback_desc:
        "Evervent's platforms simplify the insurance buying process and allows us to provide our customers with instant quotes and personalized coverage options. The platform's integration with multiple insurance providers has expanded our reach and increased our ability to cater to diverse client needs. We are pleased to partner with InurTech and leverage their innovative solutions to better serve our clients.",
      rating_points: 5,
    },
  ]
  const insurventDifferenceArray = [
    {
      image_url: "./images/insurevent_img_one.png",
      heading: "Work with Digital Insurance Industry Veterans",
      para: "Insurevent has been at the forefront of digitalisation revolution of Insurance. With a decade's experience in running insurance distribution business and developing technology, we have built knowledge and acquire skills required to understand the magnitude of depth at which the insurance industry is operating.",
    },
    {
      image_url: "./images/insurevent_img_two.png",
      heading: "Platform not just built for scale but has handled scale",
      para: "Insurevent has exhibited efficiency in handling large volume of digital traffic/clients with over 60 projects. Building products with the inherent trait of accommodating evolving nature of sales volume so that your business never suffers is our forte.",
    },
    {
      image_url: "./images/insurevent_img_three.png",
      heading: "360° policy and customer management system",
      para: "Having started out as Digital Insurance Aggregators, Insurevent understands even the most minuscule detail of your insurance business. Insurevent excels at creating business models that maintain equity across all your business KPIs like customers, policies, renewals and Quotations",
    },
    {
      image_url: "./images/insurevent_img_four.png",
      heading: "User Experience at the heart of every product",
      para: "From the basics to the ins & outs you'll find ample guidance to help you select the right life insurance policy, get the complete know-how, learn to ask the right questions, watch out for the tiny details and don’t just sign up for any policy.",
    },
    {
      image_url: "./images/insurevent_img_five.png",
      heading: "One Stop Place for all your business needs",
      para: "Insurevent's digital solutions are built in such a way that you need not look outside of our capable network for problem solving. Product modules of Insurevent ensure that your business get's logged in timely and leave your end users content.",
    },
    {
      image_url: "./images/insurevent_img_six.png",
      heading: "Platform with largest number of integration in India",
      para: "With India becoming increasingly digital by the day, so why shouldn't its insurance be ? With a mindset that understands that digital is the only way to go forward, Insurevent has successfully integrated itself with over 40 insurers and this number is only growing.",
    },
    {
      image_url: "./images/insurevent_img_seven.png",
      heading: "Your Business stays compliant and secure",
      para: "With the everchanging nature of the Insurance industry and the digital space, Insurevent assuredly stays on top of every recent and current amendment and regulation that may affect your business. It is in Insurevent's very nature to stay compliant, digitally secure and keep the goodwill factor afloat.",
    },
    {
      image_url: "./images/insurevent_img_eight.png",
      heading: "We Build Robust Systems",
      para: "Insurevent's journey has stood the test of time and delivered highly functional enterprise products with immense capability to handle a multitude of technical and business glitches with the shortest turnaround time. Building tough and robust systems drives our product capabilities further.",
    },
  ]
  const ourinsuranceTypeCatalogue = [
    {
      boxColor: 'health',
      insurancetypeIcon: "./images/health_insurance_icon.svg",
      insuranceType: <>Health <br />Insurance</>,
    },
    {
      boxColor: 'term',
      insurancetypeIcon: "./images/term_insurance_icon.svg",
      insuranceType: <>Term <br />Insurance</>,
    },
    {
      boxColor: 'car',
      insurancetypeIcon: "./images/car_insurance_icon.svg",
      insuranceType: <>Car <br />Insurance</>,
    },
    {
      boxColor: 'bike',
      insurancetypeIcon: "./images/bike_insurance_icon.svg",
      insuranceType: <>Bike <br />Insurance</>,
    },
    {
      boxColor: 'travel',
      insurancetypeIcon: "./images/travel_insurance_icon.svg",
      insuranceType: <>Travel <br />Insurance</>,
    },
    {
      boxColor: 'investment',
      insurancetypeIcon: "./images/investment_plan_icon.svg",
      insuranceType: <>Investment <br />Plans</>,
    },
    {
      boxColor: 'shopkeeper',
      insurancetypeIcon: "./images/shopkeeper_insurance_icon.svg",
      insuranceType: <>Bike <br />Insurance</>,
    },
    {
      boxColor: 'home',
      insurancetypeIcon: "./images/home_insurance_icon.svg",
      insuranceType: <>Bike <br />Insurance</>,
    },
    {
      boxColor: 'commercial',
      insurancetypeIcon: "./images/commercialVehicle_insurance_icon.svg",
      insuranceType: <>Bike <br />Insurance</>,
    },
  ]
  const IntermediariesArray = [
    {
      url: ALL_ROUTES.ERP,
      img_url: "../images/best_in_market_icon1.svg",
      heading: "Enterprise Resource Planning /BMS",
      desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
    },
    {
      url: ALL_ROUTES.POSP,
      img_url: "../images/best_in_market_icon2.svg",
      heading: "Point of Sales Person",
      desc: "Compliant Paperless Onboarding of Agents, 24/7 Instant  Policy Issuance, integrations with ERP & CRM designed to  empower salesperson, aiding them in maximizing their  policy sales & management.",
    },
    {
      url: ALL_ROUTES.CRM,
      img_url: "../images/best_in_market_icon3.svg",
      heading: "Customer Relationship Management",
      desc: " Enhancing communication via interactive dashboard, streamlining policy data collection & providing real-time insights into leads & clients interactions resulting in elevated client satisfaction and increased  sales.",
    },
    {
      url: ALL_ROUTES.INSURANCE_AGGREGATION,
      img_url: "../images/best_in_market_icon4.svg",
      heading: "Insurance Aggregation Platform & Application",
      desc: "Centralized interface for comparing and purchasing policy from multiple carriers, saving time and improving  service for their clients",
    },
    {
      url: ALL_ROUTES.SELF_INSPECTION,
      img_url: "../images/best_in_market_icon5.svg",
      heading: "Self Inspection Application",
      desc: "Application providing Break-In Handling, Easy Inspection, Real-Time Data Collection, Multimedia Support, & integrations with Back-End Systems for real-time assessments and automated underwriting enabling faster policy issuance.",
    },
    {
      url: ALL_ROUTES.EB,
      img_url: "../images/best_in_market_icon6.svg",
      heading: "Employee Benefit Platform",
      desc: "Boost your corporate sales by 35% with our employee benefits platform that is comprehensive, user-friendly & cost-effective in managing & rationanlizing the process of offering various insurance options to employees leading to their increased satisfaction & rentention rate.",
    },
  ];
  const insurersArray = [
    {
      img_url: "../images/best_in_market_icon1.svg",
      heading: "Integration Kits & SDK's",
      desc: "Advanced API Kits complete with Quotation, Proposal, Policy Issuance, Policy Document, Schedulers",
    },
    {
      img_url: "../images/best_in_market_icon2.svg",
      heading: "Online Transaction Journey",
      desc: "Instant Policy Issuance, Real-Time Premium Calculation, Policy & Customer Lifecycle Management",
    },
    {
      img_url: "../images/best_in_market_icon3.svg",
      heading: "Customer Enterprise Software Systems",
      desc: "Customer Retention System, Advanced Data Security, Pipeline/Lead Management Systems, Realtime Interactive Emailers/SMS",
    },
    {
      img_url: "../images/best_in_market_icon4.svg",
      heading: "Integration Support for Sales Partners",
      desc: "Automated Processes, Develop need-pay Off, Dialer Systems, Real time Quotation System",
    },
    {
      img_url: "../images/best_in_market_icon5.svg",
      heading: "Development Services",
      desc: "Product Designing, Prototyping, Software Development & Testing",
    },
  ];
  const BankstabArray = [
    {
      url: ALL_ROUTES.ERP,
      img_url: "../images/navbar_erp.svg",
      heading: "Enterprise Resource Planning /BMS",
      desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
    },
    {
      url: ALL_ROUTES.CRM,
      img_url: "../images/navbar_intermediaries.svg",
      heading: "Customer Relationship Manager",
      desc: " Enhancing communication via interactive dashboard, streamlining policy data collection & providing real-time insights into leads & clients interactions resulting in elevated client satisfaction and increased  sales.",
    },
  ];
  const nbfctabArray = [
    {
      url: ALL_ROUTES.SELF_INSPECTION,
      img_url: "../images/best_in_market_icon5.svg",
      heading: "Self Inspection Application",
      desc: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.",
    },
    {
      url: ALL_ROUTES.ERP,
      img_url: "../images/best_in_market_icon1.svg",
      heading: "Enterprise Resource Planning /BMS",
      desc: "A holistic enterprise solution to manage your Digital Insurance Business right from a dedicated client dashboard complete with end to end business lifecycle management, advanced business analytics, to renewal and retention management system and Agent validation & activation modules assured to make your work flow is smooth and consistent.",
    },
    {
      url: ALL_ROUTES.EB,
      img_url: "../images/best_in_market_icon6.svg",
      heading: "Employee Benefit Platform",
      desc: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.",
    },
    {
      url: ALL_ROUTES.POSP,
      img_url: "../images/best_in_market_icon2.svg",
      heading: "Point of Sales Person",
      desc: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.",
    },
  ];
  const oemtabArray = [
    {
      url: ALL_ROUTES.ERP,
      img_url: "../images/best_in_market_icon1.svg",
      heading: "Enterprise Resource Planning /BMS",
      desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
    },
    {
      url: ALL_ROUTES.CRM,
      img_url: "../images/best_in_market_icon3.svg",
      heading: "Customer Relationship Management",
      desc: " Enhancing communication via interactive dashboard, streamlining policy data collection & providing real-time insights into leads & clients interactions resulting in elevated client satisfaction and increased  sales.",
    },
  ];
  const corporatestabArray = [
    {
      url: ALL_ROUTES.SELF_INSPECTION,
      img_url: "../images/best_in_market_icon5.svg",
      heading: "Self Inspection Application",
      desc: "Application providing Break-In Handling, Easy Inspection, Real-Time Data Collection, Multimedia Support, & integrations with Back-End Systems for real-time assessments and automated underwriting enabling faster policy issuance.",
    },
    {
      url: ALL_ROUTES.ERP,
      img_url: "../images/best_in_market_icon1.svg",
      heading: "Enterprise Resource Planning /BMS",
      desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
    },
    {
      url: ALL_ROUTES.INSURANCE_AGGREGATION,
      img_url: "../images/best_in_market_icon4.svg",
      heading: "Insurance Aggregation Platform & Application",
      desc: "Centralized interface for comparing and purchasing policy from multiple carriers, saving time and improving  service for their clients",
    },
  ];
  const misptabArray = [
    {
      url: ALL_ROUTES.SELF_INSPECTION,
      img_url: "../images/best_in_market_icon5.svg",
      heading: "Self Inspection Application",
      desc: "Application providing Break-In Handling, Easy Inspection, Real-Time Data Collection, Multimedia Support, & integrations with Back-End Systems for real-time assessments and automated underwriting enabling faster policy issuance.",
    },
    {
      url: ALL_ROUTES.ERP,
      img_url: "../images/best_in_market_icon1.svg",
      heading: "Enterprise Resource Planning /BMS",
      desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
    },
    {
      url: ALL_ROUTES.EB,
      img_url: "../images/best_in_market_icon6.svg",
      heading: "Employee Benefit Platform",
      desc: "Boost your corporate sales by 35% with our employee benefits platform that is comprehensive, user-friendly & cost-effective in managing & rationanlizing the process of offering various insurance options to employees leading to their increased satisfaction & rentention rate.",
    },
    {
      url: ALL_ROUTES.POSP,
      img_url: "../images/best_in_market_icon2.svg",
      heading: "Point of Sales Person",
      desc: "Compliant Paperless Onboarding of Agents, 24/7 Instant  Policy Issuance, integrations with ERP & CRM designed to  empower salesperson, aiding them in maximizing their  policy sales & management.",
    },
  ];

  const [quoteFields, setQuoteFields] = useState<{
    name: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    email: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const [activePage, setActivePage] = useState<string>("reinsurancePlatfrom");
  const changePage = (page: any) => {
    setActivePage(page);
  };

  return (
    <>
      <MNavbar />

      <Box className="homepage-wrapper">
        <Box
          className="top-header"
          padding={"0px 12px"}
          marginTop={"40px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12}>
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                autoPlaySpeed={3000}
                arrows={false}
                customTransition={"transform 800ms ease-in-out"}
              >
                {headerSliderArray.map((data: any, index: any) => (
                  <Box className="carousel-box" textAlign={"center"}>
                    <h2 className="mb-3">{data.header}</h2>
                    <p className="mb-3">{data.desc}</p>
                    <img src={data.img_url} alt="" />
                  </Box>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="big_brand_business"
          padding={"0px 12px"}
          marginTop={"40px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3>
                Trusted by the <span>biggest</span> Brands in the business
              </h3>
            </Grid>
            <Grid xs={12}>
              <Box>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={brandsresponsive}
                  infinite={true}
                  autoPlay={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  autoPlaySpeed={2000}
                  arrows={false}
                  customTransition={"transform 800ms ease-in-out"}
                >
                  {ourPartners.map((data) => (
                    <Box className="partner_box">
                      <img src={data.partner_img_url} alt="" />
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="li_brokers_dependency"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3 className="mb-2">
                Why <span>Leading Insurance Brokers</span> depend on us !
              </h3>
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
            </Grid>
            <Grid xs={12}>
              {ourFeatureBoxArray.map((data) => (
                <Box className={`our_feature_box ${data.bluebackground}`}>
                  <img src={data.img_url} alt="" />
                  <h5>{data.heading}</h5>
                  <p>{data.desc}</p>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>

        <Box
          className="best_in_market"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3>
                Bouquet of <span>Best-in- Market Digital Insurance</span>{" "}
                Offerings
              </h3>
            </Grid>
            <TabContext value={value}>
              <Grid xs={12} className="tabsBox">
                <Box height={"44px"} marginTop={"0px"}>
                  <TabList
                    onChange={(e, a) => {
                      setValue(a);
                    }}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Intermediaries" value="1" />
                    <Tab label="Insurers" value="2" />
                    <Tab label="Banks" value="3" />
                    <Tab label="NBFCs" value="4" />
                    <Tab label="OEM" value="5" />
                    <Tab label="Corporates" value="6" />
                    <Tab label="MISP Dealers" value="7" />
                  </TabList>
                </Box>
              </Grid>
              <Grid xs={12}>
                <TabPanel className="tabPanel" value="1">
                  {IntermediariesArray.map((data) => (
                    <Box className="bim_box" onClick={() => navigate(data.url)}>
                      <Box className="top_part">
                        <img src={data.img_url} alt="" />
                        <h5>{data.heading}</h5>
                      </Box>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </TabPanel>

                <TabPanel className="tabPanel" value="2">
                  {insurersArray.map((data) => (
                    <Box className="bim_box" >
                      <Box className="top_part">
                        <img src={data.img_url} alt="" />
                        <h5>{data.heading}</h5>
                      </Box>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </TabPanel>

                <TabPanel className="tabPanel" value="3">
                  {BankstabArray.map((data) => (
                    <Box className="bim_box" onClick={() => navigate(data.url)}>
                      <Box className="top_part">
                        <img src={data.img_url} alt="" />
                        <h5>{data.heading}</h5>
                      </Box>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </TabPanel>

                <TabPanel className="tabPanel" value="4">
                  {nbfctabArray.map((data) => (
                    <Box className="bim_box" onClick={() => navigate(data.url)}>
                      <Box className="top_part">
                        <img src={data.img_url} alt="" />
                        <h5>{data.heading}</h5>
                      </Box>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </TabPanel>
                <TabPanel className="tabPanel" value="5">
                  {oemtabArray.map((data) => (
                    <Box className="bim_box" onClick={() => navigate(data.url)}>
                      <Box className="top_part">
                        <img src={data.img_url} alt="" />
                        <h5>{data.heading}</h5>
                      </Box>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </TabPanel>
                <TabPanel className="tabPanel" value="6">
                  {corporatestabArray.map((data) => (
                    <Box className="bim_box" onClick={() => navigate(data.url)}>
                      <Box className="top_part">
                        <img src={data.img_url} alt="" />
                        <h5>{data.heading}</h5>
                      </Box>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </TabPanel>
                <TabPanel className="tabPanel" value="7">
                  {misptabArray.map((data) => (
                    <Box className="bim_box" onClick={() => navigate(data.url)}>
                      <Box className="top_part">
                        <img src={data.img_url} alt="" />
                        <h5>{data.heading}</h5>
                      </Box>
                      <p>{data.desc}</p>
                    </Box>
                  ))}
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
        </Box>

        <Box
          className="our_insurance_catalogue"
          marginBottom={"40px"}
          padding={"30px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"60px"}>
              <h3>
                <span>Comprehensive Insurance </span>Product Basket
              </h3>
            </Grid>
            <Grid xs={12}>
              <ul>
                {ourinsuranceTypeCatalogue.map((data) => (
                  <li>
                    <span className={data.boxColor}>
                      <img src={data.insurancetypeIcon} alt="" />
                    </span>
                    <p>{data.insuranceType}</p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="upcoming_products"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3>
                Upcoming <span>Products</span>
              </h3>
            </Grid>

            <Grid xs={12}>
              <Box className="Tabs">
                <Button
                  className={
                    activePage === "dialPro" ? " nav-link active" : " nav-link"
                  }
                  onClick={() => changePage("dialPro")}
                >
                  <Box className="product_box">
                    <img src="./images/dial_pro.svg" alt="" />
                  </Box>
                  Dial Pro <br />
                  CRM
                </Button>

                <Button
                  className={
                    activePage === "reinsurancePlatfrom"
                      ? " nav-link active"
                      : "nav-link"
                  }
                  onClick={() => changePage("reinsurancePlatfrom")}
                >
                  <Box className="product_box">
                    <img src="./images/reinsurance.svg" alt="" />
                  </Box>
                  Re-Insurance <br /> Platform
                </Button>

                <Button
                  className={
                    activePage === "liabilityInsurance"
                      ? " nav-link active"
                      : "nav-link"
                  }
                  onClick={() => changePage("liabilityInsurance")}
                >
                  <Box className="product_box">
                    <img src="./images/liability.svg" alt="" />
                  </Box>
                  Liability <br /> Insurance
                </Button>

                <Button
                  className={
                    activePage === "marineInsurance"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() => changePage("marineInsurance")}
                >
                  <Box className="product_box">
                    <img src="./images/marine.svg" alt="" />
                  </Box>
                  Marine <br />
                  Insurance
                </Button>

                <Button
                  className={
                    activePage === "fireInsurance"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() => changePage("fireInsurance")}
                >
                  <Box className="product_box">
                    <img src="./images/fire.svg" alt="" />
                  </Box>
                  Fire <br />
                  Insurance
                </Button>
              </Box>

              <Box className="TabsWrapper">
                {activePage == "dialPro" ? (
                  <>
                    <Box className="productdetailBox">
                      <img src="./images/dial_pro.svg" alt="" />
                      <h5>Dial Pro CRM</h5>
                      <p>
                        Digital solution to make and receive phone calls
                        directly from within the CRM platform, integrating with
                        telephony systems or Voice over IP (VoIP) services,
                        enabling sales and customer service teams to efficiently
                        manage & track their communications, automate call
                        logging, & streamline their workflow without leaving the
                        CRM environment.
                      </p>
                    </Box>
                  </>
                ) : null}
                {activePage == "reinsurancePlatfrom" ? (
                  <>
                    <Box className="productdetailBox">
                      <img src="./images/reinsurance.svg" alt="" />
                      <h5>Re-Insurance Platform</h5>
                      <p>
                        Seamless integraions for insurance companies to purchase
                        & transfer a portion of their risk to other insurers,
                        allowing them to mitigate their exposure to large or
                        catastrophic losses.
                      </p>
                    </Box>
                  </>
                ) : null}
                {activePage == "liabilityInsurance" ? (
                  <>
                    <Box className="productdetailBox">
                      <img src="./images/liability.svg" alt="" />
                      <h5>Liability Insurance</h5>
                      <p>
                        Seamless integrations for coverage for legal liabilities
                        and financial obligations arising from third-party
                        claims, protecting individuals and businesses against
                        costs related to bodily injury, property damage, or
                        other legal claims
                      </p>
                    </Box>
                  </>
                ) : null}
                {activePage == "marineInsurance" ? (
                  <>
                    <Box className="productdetailBox">
                      <img src="./images/marine.svg" alt="" />
                      <h5>Marine Insurance</h5>
                      <p>
                        Seamless integraions for covering the loss/damage of
                        ships, cargo, terminals, and includes any other means of
                        transport by which goods are transferred, acquired, or
                        held between the points of origin and the final
                        destination.
                      </p>
                    </Box>
                  </>
                ) : null}
                {activePage == "fireInsurance" ? (
                  <>
                    <Box className="productdetailBox">
                      <img src="./images/fire.svg" alt="" />
                      <h5>Fire Insurance</h5>
                      <p>
                        Seamless integrations for property insurance that
                        provides coverage against damages and losses caused by
                        fire, including damage to buildings, contents, and
                        associated expenses
                      </p>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="insurvent_differnce_sec"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h3 className="mb-2">
                The <span>Insurvent Difference !</span>
              </h3>
              <p>"Explore our products, experience our benefits"-</p>
            </Grid>
            <Grid xs={12} padding={0}>
              <Box>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  autoPlaySpeed={3000}
                  arrows={false}
                  customTransition={"transform 800ms ease-in-out"}
                >
                  {insurventDifferenceArray.map((data) => (
                    <Box className="id_box">
                      <Box className="icon_box">
                        <span>
                          <img src={data.image_url} alt="" />
                        </span>
                      </Box>

                      <h5>{data.heading}</h5>
                      <p>{data.para}</p>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="services_overview"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h3 className="mb-2">
                Services <span>Overview</span>
              </h3>
              <p>
                We provide the best range of plans from various insurance
                companies for you to compare.
              </p>
            </Grid>
            <Grid xs={12} padding={0}>
              <Box>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  autoPlaySpeed={3000}
                  arrows={false}
                  customTransition={"transform 800ms ease-in-out"}
                >
                  {servicesOverViewArray.map((data) => (
                    <Box className="so_box">
                      <img src={data.image_url} alt="" />
                      <h4>{data.heading}</h4>
                      <p>{data.para}</p>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="achievements_counter"
          marginBottom={"40px"}
          padding={"30px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3 className="mb-5">
                We <span>promise, grow</span> & <span>retain</span> the best
                practices in the market !
              </h3>
              <img
                src="./images/counter_achievements.svg"
                alt=""
                style={{ margin: "auto" }}
              />
            </Grid>
            <Grid xs={12}>
              <ul>
                <li>
                  <span>
                    <CountUp end={10} duration={5} /> +
                  </span>
                  <br />
                  Years of Experience
                </li>
                <li>
                  <span>
                    <CountUp end={2500} duration={5} />{" "}
                    <span className="inner_span">+</span>
                  </span>
                  <br />
                  Integrations
                </li>
                <li>
                  <span>
                    <CountUp end={25} duration={5} />{" "}
                    <span className="inner_span">+</span>
                  </span>
                  <br />
                  Projects Completed
                </li>
                <li>
                  <span>
                    <CountUp end={100} duration={5} />
                    <span className="inner_span">+</span>
                  </span>
                  <br />
                  Complex Customisations
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="clients_feedback"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h3 className="mb-2">
                Client <span>Feedback</span>
              </h3>
              <p>"Explore our products, experience our benefits"-</p>
            </Grid>
            <Grid xs={12} padding={0}>
              <Box>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  autoPlaySpeed={3000}
                  arrows={false}
                  // customTransition={"transform 800ms ease-in-out"}
                >
                  {clientFeedbackArray.map((data) => (
                    <Box className="cf_box">
                      <Box className="client_profile_pic" marginBottom={"30px"}>
                        <img
                          className="profile"
                          src={data.client_profile_pic}
                          alt=""
                        />
                        <span>
                          <img src="./images/quotes.svg" alt="" />
                        </span>
                      </Box>
                      <h5>{data.client_name}</h5>
                      <h6>{data.organization_name}</h6>
                      <p>{data.feedback_desc}</p>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating"
                          defaultValue={data.rating_points}
                          readOnly
                        />
                      </Stack>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="company_quote"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"}>
              <Box className="cq_box">
                <p>
                  “Forging unbreakable bonds of trust with insurers, we power a
                  future where technology and partnership redefine the insurance
                  landscape”
                </p>
                <EastRoundedIcon
                  onClick={() => navigate(ALL_ROUTES.INSURANCE_PARTNERS)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="getintouch_sec"
          marginBottom={"40px"}
          padding={"0px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"30px"}>
              <h3 className="mb-2">
                Get In <span>Touch</span>
              </h3>
              <p>
                "Unleash the thrill of insurance innovation by embracing a world
                of enhanced insurance solutions & personalized support – take
                the first step by getting in touch with our team of experts."
              </p>
            </Grid>
            <Grid xs={12}>
              <Box className="img-sec" marginBottom={"24px"}>
                <img src="./images/boy_1.svg" alt="" />
              </Box>
              <Box className="field-sec">
                <Grid container rowSpacing={0} columnSpacing={3}>
                  <Grid xs={12} className="mb-5">
                    <RKTextField
                      class_name="inputField"
                      title={"Name"}
                      value={quoteFields.name.value}
                      attrName={["name", "value", quoteFields, setQuoteFields]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.name.warning}
                      error_messg="Please enter name"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-5">
                    <RKTextField
                      class_name="inputField"
                      title={"Email"}
                      value={quoteFields.email.value}
                      attrName={["email", "value", quoteFields, setQuoteFields]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.email.warning}
                      error_messg="Please enter email"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-5">
                    <RKTextField
                      class_name="inputField"
                      title={"Phone Number"}
                      value={quoteFields.mobile.value}
                      attrName={[
                        "mobile",
                        "value",
                        quoteFields,
                        setQuoteFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.mobile.warning}
                      error_messg="Please enter mobile number"
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton text_name={"Submit"} class_name="blueLgBtn" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <MFooter />
    </>
  );
}

export default MHomepage