import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Erp from "../../Pages/Desktop/ProductPages/ERP/Erp";
import MErp from "../../Pages/Mobile/MProductPages/MErp/MErp";

const ErpContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MErp /> : <Erp />;
};

export default ErpContainer;
