import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./Navbar.scss";
import { Box } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../Routes/AllRoutes";
import CustomButton from "../../CustomButton/CustomButton";
import GetInTouchModal from "../GetInTouchModal/GetInTouchModal";

interface NavbarProps {
  style?: any;
  image?: string;
  class1?: string;
  border?: string;
}
const Navbar: React.FC<NavbarProps> = ({ style, image, class1, border }) => {
  const [listOne, setListOne] = React.useState(0);
  const [listTwo, setListTwo] = React.useState(0);
  // const navigate = useNavigate();
  console.log("class", class1);

  const [activeClass, setActiveClass] = useState(0);

  const [showContent1, setShowContent1] = useState({
    firstList: {
      first: true,
    },
  });

  const [showContent2, setShowContent2] = useState([
    {
      img: "./images/navbar_intermediaries.svg",
      title: "intermediaries",
      desc: "For Brokers, Corporate Agencies, Web Aggregaters",
      submenu: [
        {
          title: "Enterprise Resource Planning /BMS",
          desc: "",
          img: "./images/navbar_erp.svg",
          contentone: "Enterprise Resource Planning /BMS",
          contenttwo:
            "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.ERP,
        },
        {
          title: "Point of Sales Person",
          desc: "",
          img: "./images/navbar_posp.svg",
          contentone:
            "PoSP/Agent Examination & Certification, Complete Business Engagement & Integration with BMS",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.POSP,
        },
        {
          title: "Customer Relationship Manager",
          desc: "",
          img: "./images/navbar_intermediaries.svg",
          contentone:
            "Lead Management System, Dialer Integrations & Advanced Assistance Features",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.CRM,
        },
        {
          title: "Insurance Aggregation Platform & Application",
          desc: "",
          img: "./images/navbar_iap.svg",
          contentone:
            "End User Website & Application, Real time Quotation System, Advanced API Integrations",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.INSURANCE_AGGREGATION,
        },
        {
          title: "Self Inspection Application",
          desc: "",
          img: "./images/navbar_sia.svg",
          contentone:
            "Break-In Handling System, Advanced Assistive Features & Integrations",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.SELF_INSPECTION,
        },
        {
          title: "Employee Benefit Platform",
          desc: "",
          img: "./images/navbar_ebplatform.svg",
          contentone:
            "Group Insurance Product Aggregator/Platform, Automated Renewals & Balance Triggers, Policy Wallets & Lead Management System",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.EB,
        },
      ],
    },
    {
      img: "./images/navbar_insurer.svg",
      title: "Insurers",
      desc: "",
      submenu: [
        {
          title: "Integration Kits & SDK's",
          desc: "",
          img: "./images/navbar_erp.svg",
          contentone:
            "Advanced API Kits complete with Quotation, Proposal, Policy Issuance, Policy Document, Schedulers",
          bigImage: "./images/products-nav-icons.svg",
          url:'',
        },
        {
          title: "Online Transaction Journey",
          desc: "",
          img: "./images/navbar_sia.svg",
          contentone:
            "Instant Policy Issuance, Real-Time Premium Calculation, Policy & Customer Lifecycle Management",
          bigImage: "./images/products-nav-icons.svg",
        },
        {
          title: "Integration Support for Sales Partners",
          desc: "",
          img: "./images/navbar_iap.svg",
          contentone:
            "Automated Processes, Develop need-pay Off, Dialer Systems, Real time Quotation System",
          bigImage: "./images/products-nav-icons.svg",
        },
      ],
    },
    // {
    //   img: "./images/navbar_general_products.svg",
    //   title: "General Products",
    //   desc: "",
    //   submenu: [
    //     {
    //       title: "Self Inspection Application",
    //       desc: "",
    //       img: "./images/navbar_sia.svg",
    //       contentone:
    //         "Break-In Handling System, Advanced Assistive Features & Integrations",
    //       bigImage: "./images/products-nav-icons.svg",
    //     },
    //     {
    //       title: "Enterprise Resource Planning /BMS",
    //       desc: "",
    //       img: "./images/navbar_erp.svg",
    //       contentone:
    //         "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
    //       bigImage: "./images/products-nav-icons.svg",
    //     },
    //     {
    //       title: "Employee Benefit Platform",
    //       desc: "",
    //       img: "./images/navbar_ebplatform.svg",
    //       contentone:
    //         "Group Insurance Product Aggregator/Platform, Automated Renewals & Balance Triggers, Policy Wallets & Lead Management System",
    //       bigImage: "./images/products-nav-icons.svg",
    //     },
    //     {
    //       title: "Point of Sales Person",
    //       desc: "",
    //       img: "./images/navbar_posp.svg",
    //       contentone:
    //         "PoSP/Agent Examination & Certification, Complete Business Engagement & Integration with BMS",
    //       bigImage: "./images/products-nav-icons.svg",
    //     },
    //     {
    //       title: "Customer Relationship Manager",
    //       desc: "",
    //       img: "./images/navbar_intermediaries.svg",
    //       contentone:
    //         "Lead Management System, Dialer Integrations & Advanced Assistance Features",
    //       bigImage: "./images/products-nav-icons.svg",
    //     },
    //     {
    //       title: "Insurance Aggregation Platform & Application",
    //       desc: "",
    //       img: "./images/navbar_iap.svg",
    //       contentone:
    //         "End User Website & Application, Real time Quotation System, Advanced API Integrations",
    //       bigImage: "./images/products-nav-icons.svg",
    //     },
    //   ],
    // },
    {
      img: "./images/navbar_banks.svg",
      title: "Banks",
      desc: "",
      submenu: [
        {
          title: "Enterprise Resource Planning /BMS",
          desc: "",
          img: "./images/navbar_erp.svg",
          contentone:
            "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.ERP,
        },
        {
          title: "Customer Relationship Manager",
          desc: "",
          img: "./images/navbar_intermediaries.svg",
          contentone:
            "Lead Management System, Dialer Integrations & Advanced Assistance Features",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.CRM,
        },
      ],
    },
    {
      img: "./images/navbar_nbfc.svg",
      title: "NBFCs",
      desc: "",
      submenu: [
        {
          title: "Self Inspection Application",
          desc: "",
          img: "./images/navbar_sia.svg",
          contentone:
            "Break-In Handling System, Advanced Assistive Features & Integrations",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.SELF_INSPECTION,
        },
        {
          title: "Enterprise Resource Planning /BMS",
          desc: "",
          img: "./images/navbar_erp.svg",
          contentone:
            "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.ERP,
        },
        {
          title: "Employee Benefit Platform",
          desc: "",
          img: "./images/navbar_ebplatform.svg",
          contentone:
            "Group Insurance Product Aggregator/Platform, Automated Renewals & Balance Triggers, Policy Wallets & Lead Management System",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.EB,
        },
        {
          title: "Point of Sales Person",
          desc: "",
          img: "./images/navbar_posp.svg",
          contentone:
            "PoSP/Agent Examination & Certification, Complete Business Engagement & Integration with BMS",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.POSP,
        },
      ],
    },
    {
      img: "./images/navbar_original_equipment.svg",
      title: "Original Equipment Manufacturers",
      desc: "",
      submenu: [
        {
          title: "Enterprise Resource Planning /BMS",
          desc: "",
          img: "./images/navbar_erp.svg",
          contentone:
            "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.ERP,
        },
        {
          title: "Customer Relationship Manager",
          desc: "",
          img: "./images/navbar_intermediaries.svg",
          contentone:
            "Lead Management System, Dialer Integrations & Advanced Assistance Features",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.CRM,
        },
      ],
    },
    {
      img: "./images/navbar_corporates.svg",
      title: "Corporates",
      desc: "",
      submenu: [
        {
          title: "Self Inspection Application",
          desc: "",
          img: "./images/navbar_sia.svg",
          contentone:
            "Break-In Handling System, Advanced Assistive Features & Integrations",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.SELF_INSPECTION,
        },
        {
          title: "Enterprise Resource Planning /BMS",
          desc: "",
          img: "./images/navbar_erp.svg",
          contentone:
            "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.ERP,
        },
        {
          title: "Insurance Aggregation Platform & Application",
          desc: "",
          img: "./images/navbar_iap.svg",
          contentone:
            "End User Website & Application, Real time Quotation System, Advanced API Integrations",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.INSURANCE_AGGREGATION,
        },
      ],
    },
    {
      img: "./images/navbar_mispdealer.svg",
      title: "MISP Dealers",
      desc: "",
      submenu: [
        {
          title: "Self Inspection Application",
          desc: "",
          img: "./images/navbar_sia.svg",
          contentone:
            "Break-In Handling System, Advanced Assistive Features & Integrations",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.SELF_INSPECTION,
        },
        {
          title: "Enterprise Resource Planning /BMS",
          desc: "",
          img: "./images/navbar_erp.svg",
          contentone:
            "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.ERP,
        },
        {
          title: "Employee Benefit Platform",
          desc: "",
          img: "./images/navbar_ebplatform.svg",
          contentone:
            "Interactive Policy Lifecycle Management, Automated renewals, Lead management process.",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.EB,
        },
        {
          title: "Point of Sales Person",
          desc: "",
          img: "./images/navbar_posp.svg",
          contentone:
            "PoSP/Agent Examination & Certification, Complete Business Engagement & Integration with BMS",
          bigImage: "./images/products-nav-icons.svg",
          url: ALL_ROUTES.POSP,
        },
      ],
    },
  ]);
  const [showContent3, setShowContent3] = useState([
    {
      img: "./images/navbar_servicesicon1.svg",
      title: "Custom Product Development",
      desc: "",
      submenu: [
        {
          title: "Performance Marketing",
          desc: "",
          img: "./images/navbar_servicesicon5.svg",
          contentone: "Performance Marketing",
          contenttwo:
            "High Intent , high Quality Lead Generation through target Marketing Campaigns.",
          bigImage: "./images/products-nav-icons_two.svg",
        },
        {
          title: "Branding Campaigns",
          desc: "",
          img: "./images/navbar_servicesicon6.svg",
          contentone:
            "Banner Ads, Interstitial Ads, Native Ads, Feature Advertising",
          bigImage: "./images/products-nav-icons_two.svg",
        },
      ],
    },
    {
      img: "./images/navbar_servicesicon2.svg",
      title: "Digitization of Insurance Business",
      desc: "",
      submenu: [
        {
          title: "Branding Campaigns",
          desc: "",
          img: "./images/navbar_servicesicon6.svg",
          contentone:
            "Banner Ads, Interstitial Ads, Native Ads, Feature Advertising",
          bigImage: "./images/products-nav-icons_two.svg",
        },
        {
          title: "Performance Marketing",
          desc: "",
          img: "./images/navbar_servicesicon5.svg",
          contentone: "Performance Marketing",
          contenttwo:
            "High Intent , high Quality Lead Generation through target Marketing Campaigns.",
          bigImage: "./images/products-nav-icons_two.svg",
        },
      ],
    },
    {
      img: "./images/navbar_servicesicon3.svg",
      title: "Agency Management Services",
      desc: "",
      submenu: [
        {
          title: "Performance Marketing",
          desc: "",
          img: "./images/navbar_servicesicon5.svg",
          contentone: "Performance Marketing",
          contenttwo:
            "High Intent , high Quality Lead Generation through target Marketing Campaigns.",
          bigImage: "./images/products-nav-icons_two.svg",
        },
        {
          title: "Branding Campaigns",
          desc: "",
          img: "./images/navbar_servicesicon6.svg",
          contentone:
            "Banner Ads, Interstitial Ads, Native Ads, Feature Advertising",
          bigImage: "./images/products-nav-icons_two.svg",
        },
      ],
    },
    {
      img: "./images/navbar_servicesicon4.svg",
      title: "Lead Generation & Digital Marketing",
      desc: "For Brokers, Corporate Agencies, Web Aggregaters",
      submenu: [
        {
          title: "Branding Campaigns",
          desc: "",
          img: "./images/navbar_servicesicon6.svg",
          contentone:
            "Banner Ads, Interstitial Ads, Native Ads, Feature Advertising",
          bigImage: "./images/products-nav-icons_two.svg",
        },
        {
          title: "Performance Marketing",
          desc: "",
          img: "./images/navbar_servicesicon5.svg",
          contentone: "Performance Marketing",
          contenttwo:
            "High Intent , high Quality Lead Generation through target Marketing Campaigns.",
          bigImage: "./images/products-nav-icons_two.svg",
        },
      ],
    },
  ]);

  const [currentFirstList, setCurrentFirstList] = useState<string>(
    showContent2[0].title
  );
  const [currentSecondList, setCurrentSecondList] = useState<{
    title: string;
    desc: string;
    img: string;
    contentone?: string;
    contenttwo?: string;
    bigImage: string;
  }>(showContent2[0].submenu[0]);

  const [servicesListOne, setServicesListOne] = useState<string>(
    showContent3[0].title
  );
  const [servicesListTwo, setServicesListTwo] = useState<{
    title: string;
    desc: string;
    img: string;
    contentone?: string;
    contenttwo?: string;
    bigImage: string;
  }>(showContent3[0].submenu[0]);
  const [openGetintouch, setOpenGetintouch] = useState(false);
  return (
    <Box className={`navbar row ${class1}`} style={style}>
      <GetInTouchModal 
      open={openGetintouch}
      setOpen={setOpenGetintouch}
      />
      <Grid container spacing={3} sx={{ alignItems: "center" }} className="row">
        <Grid xs={3}>
          <a
            href="javascript:void(0)"
            onClick={() => {
              window.location.href=ALL_ROUTES.HOMEPAGE;
            }}
          >
            <img src={image} alt="" height="60px" />
          </a>
        </Grid>
        <Grid xs={9}>
          <ul className="linksList">
            <li>
              <a href="javascript:void(0)" style={style}>
                Products
              </a>

              <div className="bigMenu">
                <ul className="bigMenu_list">
                  {showContent2.map((data: any, index: number) => (
                    <li
                      style={{ display: "flex", alignItems: "center" }}
                      className={`${index === activeClass ? "active " : ""}`}
                      onClick={() => {
                        setCurrentFirstList(data.title);
                        setActiveClass(index);
                      }}
                    >
                      <img
                        src={data.img}
                        height={22}
                        style={{ marginRight: "8px" }}
                      />
                      <a
                        href="javascript:void(0)"
                        onClick={() => setListOne(0)}
                      >
                        {data.title}
                        <span>{data.desc}</span>
                      </a>
                    </li>
                  ))}
                </ul>
                {listOne == 0 ? (
                  <ul className="bigMenu_list purple">
                    {showContent2.map((data) => {
                      if (currentFirstList === data.title) {
                        return data.submenu.map((subItem) => {
                          return (
                            <li
                              style={{ display: "flex", alignItems: "center" }}
                              className=""
                              onMouseEnter={(e) => {
                                e.preventDefault();
                                setCurrentSecondList(subItem);
                              }}
                            >
                              <img
                                src={subItem.img}
                                height={22}
                                style={{ marginRight: "8px" }}
                              />
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  if (subItem.url !== undefined) {
                                    window.location.href = subItem?.url;
                                  }
                                }}
                              >
                                {subItem.title}
                              </a>
                            </li>
                          );
                        });
                      }
                    })}
                  </ul>
                ) : null}

                <ul
                  className={`bigMenu_list light_yellow ${
                    showContent1.firstList.first === true ? "show-content" : ""
                  }`}
                >
                  <li>{currentSecondList.contentone}</li>
                  <li>{currentSecondList.contenttwo}</li>
                  <li className="text-center">
                    <img src={currentSecondList.bigImage} alt="" height="182" />
                  </li>
                </ul>
              </div>
            </li>
            {/* services section start  */}
            <li>
              <a href="javascript:void(0)" style={style}>
                Services
              </a>

              <div className="bigMenu">
                <ul className="bigMenu_list">
                  {showContent3.map((data: any, index: number) => (
                    <li
                      style={{ display: "flex", alignItems: "center" }}
                      className={`${index === activeClass ? "active " : ""}`}
                      onClick={() => {
                        setServicesListOne(data.title);
                        setActiveClass(index);
                      }}
                    >
                      <img
                        src={data.img}
                        height={22}
                        style={{ marginRight: "8px" }}
                      />
                      <a
                        href="javascript:void(0)"
                        onClick={() => setListOne(0)}
                      >
                        {data.title}
                        <span>{data.desc}</span>
                      </a>
                    </li>
                  ))}
                </ul>
                {listOne == 0 ? (
                  <ul className="bigMenu_list purple">
                    {showContent3.map((data) => {
                      if (servicesListOne === data.title) {
                        return data.submenu.map((subItem) => {
                          return (
                            <li
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className=""
                              onMouseEnter={(e) => {
                                e.preventDefault();
                                setServicesListTwo(subItem);
                              }}
                            >
                              <img
                                src={subItem.img}
                                height={22}
                                style={{ marginRight: "8px" }}
                              />
                              <a href="javascript:void(0)">{subItem.title}</a>
                            </li>
                          );
                        });
                      }
                    })}
                  </ul>
                ) : null}

                <ul
                  className={`bigMenu_list light_yellow ${
                    showContent1.firstList.first === true ? "show-content" : ""
                  }`}
                >
                  <li>{servicesListTwo.contentone}</li>
                  <li>{servicesListTwo.contenttwo}</li>
                  <li className="text-center">
                    <img src={servicesListTwo.bigImage} alt="" height="182" />
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                style={style}
                onClick={() => {
                  window.location.href = ALL_ROUTES.ABOUT;
                }}
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="pr-6"
                style={style}
                onClick={() => {
                  window.location.href = ALL_ROUTES.CONTACTUS;
                }}
              >
                Contact
              </a>
            </li>
            <li
              className="ctaBtn"
              style={{ display: "flex", alignItems: "center" }}
            >
              <CustomButton class_name={`blueMdBtn ${border}`} text_name="Get in touch" onClickFunction={()=>setOpenGetintouch(true)}/>
              {/* <a href="javascript:void(0)" className={`blueBtn ${border}`}>
                Get in touch
              </a> */}
            </li>
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
