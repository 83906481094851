import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CMSPages/CMSPages.scss";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import RKTextField from "../../../../Components/FieldTypes/RKTextField/RKTextField";
import TextArea from "../../../../Components/FieldTypes/TextArea/TextArea";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
function Contact() {
  const image = "./images/insurevent_logo.svg";

  const [formFields, setFormFields] = useState<{
    name: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    message: { value: string; warning: boolean };
    subject: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    email: { value: "", warning: false },
    subject: { value: "", warning: false },
    message: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  return (
    <>
      <Navbar image={image} />
      <Box className="cms_wrapper">
        {/* header section */}
        <Box
          className="contactus_header"
          marginBottom={"80px"}
          marginTop={"24px"}
        >
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={12} lg={6}>
              <h5 className="heading">
                <span>Contact</span> US
              </h5>
              <p className="para">
                "Step into the future of policy management - lets make insurance
                exciting together!"
              </p>
              <div className="form_section">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Name"}
                      value={formFields.name.value}
                      attrName={["name", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.name.warning}
                      error_messg="Please enter name"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Subject"}
                      value={formFields.subject.value}
                      attrName={["subject", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.subject.warning}
                      error_messg="Please enter subject"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Phone Number"}
                      value={formFields.mobile.value}
                      attrName={["mobile", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.mobile.warning}
                      error_messg="Please enter mobile"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Email"}
                      value={formFields.email.value}
                      attrName={["email", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.email.warning}
                      error_messg="Please enter email"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextArea
                      class_name="inputField "
                      title={"Message"}
                      value={formFields.message.value}
                      placeholder={"Enter description here"}
                      attrName={["message", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.message.warning}
                      error_messg="Please enter message"
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton
                      class_name="blueXlBtn"
                      text_name="Submit Details"
                      //   onClickFunction={validateForm}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid sm={12} md={12} lg={6} className="contact_img">
              <img src="./images/contactus_img.svg" alt="contact" />
            </Grid>
          </Grid>
        </Box>

        {/* get in touch section */}
        <Box className="getin_touch" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h5 className="heading">
                Get In
                <span>Touch</span>
              </h5>
              <p className="para">
                "Unleash the thrill of insurance innovation by embracing a world
                of enhanced insurance solutions & personalized support – take
                the first step by getting in touch with our team of experts."
              </p>
            </Grid>
            <Grid xs={12}>
              <ul className="address_section">
                <li>
                  <img src="./images/address_icon.png" alt="address" />
                  <p>
                    #341, 4th Floor, Phase-9, <br /> Mohali, Punjab, 160062
                  </p>
                </li>
                <li>
                  <img src="./images/call_icon.png" alt="call" />
                  <p>
                    +01267777654434 <br /> 091834465555
                  </p>
                </li>
                <li>
                  <img src="./images/social_icon.png" alt="social" />
                  <a
                    href="https://in.linkedin.com/company/evervent1"
                    target="_blank"
                  >
                    www.linkdin.com
                  </a>
                  <a
                    href="https://www.instagram.com/accounts/login/?next=/evervent_/"
                    target="_blank"
                  >
                    www.instagram.com
                  </a>
                </li>
              </ul>
              <div className="maps">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2005.3936444841415!2d76.74291228094363!3d30.673114197726708!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fedbfa5e9dbcf%3A0x5d06f611fa13fbb3!2sEvervent!5e0!3m2!1sen!2sin!4v1653026267690!5m2!1sen!2sin"
                  width="100%"
                  height="600"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Contact;
