import React, { useState } from "react";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import { Box, Tab, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../ProductPages/ProductPages.scss";
import RequestDemo from "../../../../Components/Desktop/RequestDemo/RequestDemo";
import Footer from "../../../../Components/Desktop/Footer/Footer";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function EmployeeBenefit() {
  // const navbarStyle = { backgroundColor: "#6458D8", color: "#ffffff" };
  // const image = "./images/insurevent_logo_white.svg";
  // const uspPOSArray = [
  //   {
  //     point_desc:
  //       "Seamless onboarding & documentation of POSPs with certification & examination.",
  //   },
  //   {
  //     point_desc:
  //       "Streamlined sales process Simplify and expedite insurance sales for intermediaries.",
  //   },
  //   {
  //     point_desc:
  //       "Seamless integration Integrate with carriers and systems for real-time access to information.",
  //   },
  //   {
  //     point_desc:
  //       "Enhanced customer experience: Deliver personalized and efficient service to customers.",
  //   },
  //   {
  //     point_desc:
  //       "Compliance and regulatory adherence: Ensure adherence to IRDAI regulations and standards for POSPs.",
  //   },
  //   {
  //     point_desc:
  //       "Data-driven insights: Gain valuable insights for informed decision-making.",
  //   },
  //   {
  //     point_desc:
  //       " Seamless integration Integrate with carriers and systems for real-time access to information.",
  //   },
  //   {
  //     point_desc:
  //       "Enhanced customer experience: Deliver personalized and efficient service to customers.",
  //   },
  //   {
  //     point_desc:
  //       "Compliance and regulatory adherence: Ensure adherence to IRDAI regulations and standards for POSPs.",
  //   },
  //   {
  //     point_desc:
  //       "Data-driven insights: Gain valuable insights for informed decision-making.",
  //   },
  // ];
  // const [showMoreListPoints, setShowMoreListPoints] = useState(false);
  // const defaultItemsShow = 5;
  // const modulelist = [
  //   {
  //     image: "./images/posp_module_img_one.png",
  //     headingone: "Pos Agent Profile",
  //     headingtwo: "  POS Agent Profile",
  //     para: "  Manage and update professional information, including contact details and licensing information, ensuring accurate and up-to-date profiles.",
  //   },
  //   {
  //     image: "./images/posp_module_img_two.png",
  //     headingone: "Dashboard",
  //     headingtwo: "Dashboard",
  //     para: "Monitor sales performance, commissions, and incentives through a comprehensive account management section.",
  //   },
  //   {
  //     image: "./images/posp_module_img_three.png",
  //     headingone: "Pos Agent Onboarding",
  //     headingtwo: "Pos Agent Onboarding",
  //     para: "Effortlessly onboard agents and fast-track their success with our streamlined and user-friendly agent onboarding process.",
  //   },
  //   {
  //     image: "./images/posp_module_img_four.png",
  //     headingone: "Policy Comparision",
  //     headingtwo: "Policy Comparision",
  //     para: "Compare coverage options to recommend the most suitable policies.",
  //   },
  //   {
  //     image: "./images/posp_module_img_five.png",
  //     headingone: "Policy Issuance",
  //     headingtwo: "Policy Issuance",
  //     para: "Streamline policy issuance with automated documentation processes.",
  //   },
  //   {
  //     image: "./images/posp_module_img_six.png",
  //     headingone: "Customer Management",
  //     headingtwo: "Customer Management",
  //     para: " Maintain a centralized database for organized customer information.",
  //   },
  //   {
  //     image: "./images/posp_module_img_seven.png",
  //     headingone: "Renewals",
  //     headingtwo: "Renewals",
  //     para: "Simplify policy renewals and handle endorsements efficiently.",
  //   },
  //   {
  //     image: "./images/posp_module_img_eight.png",
  //     headingone: "Digital Repository",
  //     headingtwo: "Digital Repository",
  //     para: "Store and retrieve policy documents securely in a digital repository.",
  //   },
  //   {
  //     image: "./images/posp_module_img_nine.png",
  //     headingone: "Communication and Notifications",
  //     headingtwo: "Communication and Notifications",
  //     para: " Facilitate seamless communication with clients through integrated messaging systems.",
  //   },
  //   {
  //     image: "./images/posp_module_img_ten.png",
  //     headingone: "Reporting and Analytics",
  //     headingtwo: "Reporting and Analytics",
  //     para: " Access valuable insights to track performance and optimize sales strategies.",
  //   },
  //   {
  //     image: "./images/posp_module_img_eleven.png",
  //     headingone: "Quote Generation",
  //     headingtwo: "Quote Generation",
  //     para: " Generate instant and accurate quotes for various insurance products.",
  //   },
  //   {
  //     image: "./images/posp_module_img_one.png",
  //     headingone: "Leads Management",
  //     headingtwo: "Leads Management",
  //     para: "Efficient lead management system helps address the challenge of managing & effectively engaging prospective buyers",
  //   },
  // ];
  // const formHeading = (
  //   <>
  //     Request a<span> Demo</span>
  //   </>
  // );
  // const formDesc = "Experience the Future: Request a Demo and Witness the Power of Our Solutions in Action!";
  // const formImg_url = "./images/request_demo_img.svg";

  const [value, setValue] = useState("1");
  const [activeSpecBox, setActiveSpecBox] = useState({
    activeBrokerspecBox: 0,
    activeEmployerspecBox: 0,
    activeEmployeespecBox: 0,
  });
  const componentNav = {
    style: { backgroundColor: "#6458D8", color: "#ffffff" },
    image_url: "./images/insurevent_logo_white.svg"
  }
  const componentRequestDemo = {
    formHeading: (<>Request a<span> Demo</span></>),
    formDesc: "Experience the Future: Request a Demo and Witness the Power of Our Solutions in Action!",
    formImg_url: "./images/request_demo_img.svg",
  }
  const brokerSpecsArray = [
    {
      img_url: "../images/broker_dashboard_icon.svg",
      heading: "Dashboard",
      desc: "Real time dashboard offers empowering insights into group insurance plans & their types , client strength & their geographical representation & much more. Machine learning algorithms predict trends, helping intermediaries make proactive decisions and grow their business.",
    },
    {
      img_url: "../images/broker_productConfig_icon.svg",
      heading: "Product Configurator & Insurers",
      desc: "Intermediaries can tailor limitations & benefits for plans dynamically to create a unique repository for all the product types. This flexibility enhances Intermediaries' ability to offer personalized solutions. Insurers relationships & their contact details along with with email templates & claim forms are also maintained for effective policy servicing.",
    },
    {
      img_url: "../images/broker_clients_icon.svg",
      heading: "Clients & Contacts",
      desc: " Clients are created, modified & serviced with utmost ease & their parent-subsidiary relationship is also mainatined. Centralized contact information with data validation and deduplication ensures Intermediaries have easy access to client and insurer contacts, streamlining communication. ",
    },
    {
      img_url: "../images/broker_policies_icon.svg",
      heading: "Quotes & Policies",
      desc: "Streamline new client acquisition via dedicated RFQ management module. Secure communication channels with insurers protect confidential RFQ-related information.  Configure a multitude of policies with different grading & configuration patterns to best service clients & maximise their member coverage.",
    },
    {
      img_url: "../images/broker_members_icon.svg",
      heading: "Members & Enrollment",
      desc: "Employees receive an email invitation with a one-click link, making the enrollment process quick and hassle-free. Intermediaries can effortlessly monitor and manage employee enrollments, ensuring comprehensive coverage with minimal effort. Automation and integration with HR systems helps in managing member additions and deletions efficiently. Access controls protect member data and ensure data accuracy.",
    },
    {
      img_url: "../images/broker_renewals_icon.svg",
      heading: "Renewals & Endorsements",
      desc: "Automated renewal management with calendar reminders & policy history & claim dump helps Intermediaries never miss a renewal date. Endorsement processes are automated, enhancing responsiveness and efficiency for Intermediaries by timely providing the insurers, employers & members with requisite data . Secure data transmission protocols ensure the authenticity of endorsements.",
    },
    {
      img_url: "../images/broker_healthCards_icon.svg",
      heading: "Health Cards, Cashless Hospitals & Claims",
      desc: "Claims processing is streamlined through the platform, with secure communication to insurers. Intermediaries can offer clients timely benefits, enhancing satisfaction. Health Cards, streamline & expidite claim processes and enable cashless hospitalizations. This ensures a smooth process during medical emergencies.",
    },
    {
      img_url: "../images/broker_biAnalytical_icon.svg",
      heading: "BI & Analytical Reports",
      desc: "Intermediaries have access to powerful analytics tools that offer data-driven insights like enrolment scenarios, members additions, attrition rates and a lot more. Predictive analytics assist them in anticipating client needs and market trends.",
    },
    {
      img_url: "../images/broker_webhooks_icon.svg",
      heading: "Webhooks & Seamless Integration",
      desc: "Receive real-time notifications through Webhooks and seamlessly integrate external tools for efficient data analysis and automation, enhancing client services.",
    },
  ];
  const employerSpecsArray = [
    {
      img_url: "../images/broker_dashboard_icon.svg",
      heading: "Dashboard",
      desc: "The employer-centric Dashboard provides them with a comprehensive view of their members group insurance policies, member strength, endorsement & claim requests count & status and a lot more. It's a user-friendly tool that leverages advanced data analytics, allowing brokers to offer valuable insights to employers for informed decision-making.",
    },
    {
      img_url: "../images/employer_policies_icon.svg",
      heading: "Policies",
      desc: "Employers can conveniently monitor policies while ensuring data security through precise access controls. All the necessary details such as premium calculations, pro-rata rates, cash float balance etc are readily made avaliabe with rel time changes provided for them, whenever requseted. Workflow automation simplifies policy tracking and servicing, providing a technologically advanced yet user-friendly experience for optimizing members' benefits",
    },
    {
      img_url: "../images/employer_members_icon.svg",
      heading: "Members",
      desc: "Our platform automates member additions and deletions, self enrolment E-invitations, bulk addition & deletion, all while seamlessly integrating with HR systems. Employers can efficiently manage members, ensuring data accuracy and delivering maximum benefits with minimal effort. This feature simplifies the process of maintaining accurate coverage for employers' workforce.",
    },
    {
      img_url: "../images/employer_endorsement_icon.svg",
      heading: "Endorsement",
      desc: "The platform's endorsement capabilities enable employers to quickly adapt group policies to evolving member needs by raising requests on their behalf. This agility enhances employer's ability to provide responsive and member-focused services. Secure data transmission protocols guarantee the authenticity of endorsements, simplifying complex scenarios for the ultimate benefit of members",
    },
    {
      img_url: "../images/employer_claimassistant_icon.svg",
      heading: "Claim Assistant",
      desc: " Insurance brokers enhance their service to employers by leveraging the Claim Assistant,  Health Card & TPA integration features. It simplifies the claims process for members, reducing friction and ensuring a smoother experience, ultimately strengthening broker-employer-member relationship. Streamlined claims processing ensures that employers can provide members with timely benefits through secure communication with insurers",
    },
    {
      img_url: "../images/broker_biAnalytical_icon.svg",
      heading: "BI & Analytical Reports",
      desc: "Employers gain a competitive edge with our Business Intelligence (BI) and Analytics Reports. These tools offer in-depth insights into benefits programs, & predictive analytics anticipate member needs and market trends, enabling brokers to guide employers with data-driven strategies for optimized benefits offerings and member satisfaction.",
    },
    {
      img_url: "../images/employer_webhooks_icon.svg",
      heading: "Webhooks & Seamless Integration",
      desc: "Receive real-time notifications through Webhooks and seamlessly integrate external tools for efficient data analysis and automation, enhancing client services.",
    },
  ];
  const employeeSpecsArray = [
    {
      img_url: "../images/broker_dashboard_icon.svg",
      heading: "Dashboard & Cross-Selling Opportunities",
      desc: " The employee portal's Dashboard not only offers insights into benefits but also identifies cross-selling opportunities. It helps employees discover additional coverage options, ensuring comprehensive protection based on their evolving needs.",
    },
    {
      img_url: "../images/employee_riskProfiling_icon.svg",
      heading: "Risk Profiling",
      desc: " Our employee portal employs advanced risk profiling algorithms to help employees make informed insurance decisions. It provides personalized risk assessments and recommendations, ensuring that each employee's coverage aligns with their unique needs.",
    },
    {
      img_url: "../images/employee_policyWallet_icon.svg",
      heading: "Policy Wallet",
      desc: "With the Policy Wallet feature, employees have secure, digital access to all their insurance policies in one place. It simplifies policy management, offering a convenient and organized way to access essential coverage details.",
    },
    {
      img_url: "../images/employee_wellness_icon.svg",
      heading: "Wellness for Employee Engagement",
      desc: "Our platform promotes employee well-being through integrated wellness programs. It fosters engagement by providing access to health and wellness resources, enhancing overall employee satisfaction and productivity.",
    },
    {
      img_url: "../images/employee_requests_icon.svg",
      heading: "Requests",
      desc: "Employees can initiate policy endorsements and claim requests through the portal, streamlining the process. It ensures quick and efficient responses to policy changes and claims, enhancing the overall user experience.",
    },
    {
      img_url: "../images/employee_documentRepo_icon.svg",
      heading: "Document Repository",
      desc: "The document repository feature securely stores all insurance-related documents, ensuring easy access when needed. It simplifies paperwork and helps employees keep track of essential documents.",
    },
    {
      img_url: "../images/employee_profileMgmt_icon.svg",
      heading: "Profile Management",
      desc: " Employees can manage their profiles effortlessly, ensuring that their insurance information is up-to-date. This feature empowers users to maintain accurate records and make necessary adjustments when life changes occur.",
    },
    {
      img_url: "../images/employee_healthCards_icon.svg",
      heading: "Health Cards & Cashless Hospital Management",
      desc: "The employee portal offers TPA Integrated digital health cards, simplifying the claims process. It also streamlines cashless hospitalization management, ensuring that employees receive timely and hassle-free medical services.",
    },
    {
      img_url: "../images/employee_doctorConsultation_icon.svg",
      heading: "Doctor Consultation & Ancillary section",
      desc: "Employees can access doctor consultations and ancillary services through the portal. It enhances health support by offering convenient access to healthcare professionals and additional medical resources.",
    },
    {
      img_url: "../images/employee_support_icon.svg",
      heading: "Support",
      desc: "Our support feature provides employees with direct access to assistance and answers to insurance-related queries. It ensures that employees receive the guidance they need promptly, enhancing their overall experience with insurance coverage.",
    },
  ];
  const uspebListArray = [
    {
      heading: "Integrated Efficiency",
      desc: "Streamline your operations with a unified platform that seamlessly integrates tools and systems, boosting your overall efficiency.",
    },
    {
      heading: "Tailored Solutions",
      desc: "Customize benefit plans to meet the unique needs of each client, positioning yourself as a trusted advisor.",
    },
    {
      heading: "Data-Driven Decision-Making",
      desc: "Gain a competitive edge with real-time analytics, enabling you to make informed decisions and stay ahead of the curve.",
    },
    {
      heading: "Automated Workflows",
      desc: "Reduce administrative hassles and free up time for client-focused activities through automated processes.",
    },
    {
      heading: "Client-Centric Approach",
      desc: "Elevate client relationships with enhanced communication tools, contact & task management.",
    },
    {
      heading: "Simplified Member Management",
      desc: "Say goodbye to manual member additions and deletions, ensuring accuracy and saving valuable time.",
    },
    {
      heading: "Digital Policy Accessibility",
      desc: "Offer employees secure access to policy documents through our digital policy wallet.",
    },
    {
      heading: "Cross-Selling Insights",
      desc: "Identify revenue opportunities effortlessly through data-driven insights, expanding your portfolio.",
    },
    {
      heading: "Expertise Amplification",
      desc: "Enhance your services with risk profiling expertise, reinforcing employee trust.",
    },
    {
      heading: "Effortless Claims Processing",
      desc: "Enhance your services with risk profiling expertise, reinforcing employee trust.",
    },
    {
      heading: "Real-Time Updates",
      desc: "Stay in the know with instant notifications through webhooks, ensuring timely actions.",
    },
    {
      heading: "User-Friendly Enrollment",
      desc: "Make employee enrollment a breeze with simplified emailer links, improving the experience for all. ",
    },
  ];
   const [contentDescVisible, setContentDescVisible] = useState<{
     [key: number]: boolean;
   }>({});
   const handleMouseEnter = (index: any) => {
     setContentDescVisible((prev) => ({
       ...prev,
       [index]: true,
     }));
   };
   const handleMouseLeave = (index: any) => {
     setContentDescVisible((prev) => ({
       ...prev,
       [index]: false,
     }));
   };


  return (
    <>
      <Navbar
        style={componentNav.style}
        image={componentNav.image_url}
        class1="whiteMenu"
        border="border"
      />
      <Box className="productPage_wrapper">
        <Box className="productHeader_section" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={6} lg={6} alignSelf={"center"}>
              <h1>
                <span>Elevate Your Benefits Management</span> -Tri-Portal
                Advantage, Effortless Enrollment, Customization & Automation -
                Unleash Your Insurtech Potential!
              </h1>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              textAlign={"end"}
              className="text-md-center"
            >
              <img src="./images/eb_header_icon.svg" alt="posp" />
            </Grid>
          </Grid>
        </Box>

        <Box className="productInfo_wrapper" marginBottom={"80px"}>
          <Grid container columnSpacing={3} className="row">
            <Grid xs={12}>
              <Box className="productInfo_section">
                <Grid container columnSpacing={3} className="row">
                  <Grid xs={12} md={6} lg={5}>
                    <img src="./images/eb_info_sec.svg" alt="" />
                  </Grid>
                  <Grid xs={12} md={6} lg={7} alignSelf={"center"}>
                    <h6>
                      What is <span>EB?</span>
                    </h6>
                    <p>
                      Our Employee Benefits platform is designed to empower
                      intermediaries and brokers with a complete suite of
                      benefits management offerings . With dedicated portals for
                      Brokers, HR/Clients, and Employees, it offers seamless
                      integration and easy member enrollment. Tailor benefit
                      plans effortlessly with our versatile product
                      configuration module, allowing you to meet the unique
                      needs of each client. Redundant processed are automated
                      and member management is streamlined to elevate your
                      benefits management in the digital insurance landscape
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="module_desc_section" marginBottom={"40px"}>
          <Grid container columnSpacing={1.5} className="row module_comtainer">
            <Grid xs={12} textAlign={"center"}>
              <h6 className="heading mb-7">
                Modules & <span>Description</span>
              </h6>
            </Grid>
            <Grid xs={12}>
              <TabContext value={value}>
                <Grid xs={12}>
                  <Box className="tabsBox mb-7">
                    <Box height={"44px"} marginTop={"0px"}>
                      <TabList
                        onChange={(e, a) => {
                          setValue(a);
                          setActiveSpecBox({
                            activeBrokerspecBox: 0,
                            activeEmployeespecBox: 0,
                            activeEmployerspecBox: 0,
                          });
                        }}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Brokers" value="1" />
                        <Tab label="Employers" value="2" />
                        <Tab label="Employee" value="3" />
                      </TabList>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <TabPanel className="tabPanel" value="1">
                    {brokerSpecsArray.map((data, index) => (
                      <Box
                        className={`specification_box mb-7 ${
                          contentDescVisible[index] ? "active" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        <Box className="top_part">
                          <Box className="iconBox">
                            <img src={data.img_url} alt="" />
                          </Box>
                          <h5>{data.heading}</h5>
                        </Box>
                        <p>{data.desc}</p>
                      </Box>
                    ))}
                  </TabPanel>

                  <TabPanel className="tabPanel" value="2">
                    {employerSpecsArray.map((data, index) => (
                      <Box
                        className={`specification_box mb-7 ${
                          contentDescVisible[index] ? "active" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        <Box className="top_part">
                          <Box className="iconBox">
                            <img src={data.img_url} alt="" />
                          </Box>
                          <h5>{data.heading}</h5>
                        </Box>
                        <p>{data.desc}</p>
                      </Box>
                    ))}
                  </TabPanel>

                  <TabPanel className="tabPanel" value="3">
                    {employeeSpecsArray.map((data, index) => (
                      <Box
                        className={`specification_box mb-7 ${
                          contentDescVisible[index] ? "active" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        <Box className="top_part">
                          <Box className="iconBox">
                            <img src={data.img_url} alt="" />
                          </Box>
                          <h5>{data.heading}</h5>
                        </Box>
                        <p>{data.desc}</p>
                      </Box>
                    ))}
                  </TabPanel>
                </Grid>
              </TabContext>
            </Grid>
          </Grid>
        </Box>

        <Box className="usp_section_eb" marginBottom={"80px"}>
          <Grid container className="row">
            <Grid xs={12} textAlign={"center"}>
              <h6 className="heading mb-6">
                Our <span>Unique Selling Points</span>
              </h6>
            </Grid>
            <Box className="content">
              <Grid container>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <img
                    src="./images/usp_eb_img2.svg"
                    alt=""
                    width={"800px"}
                    style={{ margin: "auto", padding: "0 12px" }}
                  />
                </Grid>
              </Grid>

              <Box>
                <Grid container spacing={3}>
                  {uspebListArray.map((data) => (
                    <Grid
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      height={"100%"}
                      className="sec"
                    >
                      <ul className="uspEbList">
                        <li>
                          <h5>{data.heading}</h5>
                          <p>{data.desc}</p>
                        </li>
                      </ul>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
        <RequestDemo
          formHeading={componentRequestDemo.formHeading}
          formDesc={componentRequestDemo.formDesc}
          formImg_url={componentRequestDemo.formImg_url}
        />
      </Box>
      <Footer />
    </>
  );
}

export default EmployeeBenefit;
