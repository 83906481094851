import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MProductPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MRequestDemo from "../../../../Components/Mobile/MRequestDemo/MRequestDemo";

const MPosp = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const posmodulepoint_descArray = [
    {
      image: "./images/posp_module_img_one.png",
      heading: "  POS Agent Profile",
      para: "  Manage and update professional information, including contact details and licensing information, ensuring accurate and up-to-date profiles.",
    },
    {
      image: "./images/posp_module_img_two.png",
      heading: "Dashboard",
      para: "Monitor sales performance, commissions, and incentives through a comprehensive account management section.",
    },
    {
      image: "./images/posp_module_img_five.png",
      heading: "Policy Issuance",
      para: "Streamline policy issuance with automated documentation processes.",
    },
    {
      image: "./images/posp_module_img_three.png",
      heading: "Policy Comparision",
      para: "Compare coverage options to recommend the most suitable policies.",
    },
    {
      image: "./images/posp_module_img_seven.png",
      heading: "Renewals",
      para: "Simplify policy renewals and handle endorsements efficiently.",
    },
    {
      image: "./images/posp_module_img_eight.png",
      heading: "Digital Repository",
      para: "Store and retrieve policy documents securely in a digital repository.",
    },
    {
      image: "./images/posp_module_img_three.png",
      heading: "Pos Agent Onboarding",
      para: "Effortlessly onboard agents and fast-track their success with our streamlined and user-friendly agent onboarding process.",
    },
    {
      image: "./images/posp_module_img_one.png",
      heading: "Leads Management",
      para: "Efficient lead management system helps address the challenge of managing & effectively engaging prospective buyers",
    },
    {
      image: "./images/posp_module_img_nine.png",
      heading: "Communication and Notifications",
      para: " Facilitate seamless communication with clients through integrated messaging systems.",
    },
    {
      image: "./images/posp_module_img_ten.png",
      heading: "Reporting and Analytics",
      para: " Access valuable insights to track performance and optimize sales strategies.",
    },
    {
      image: "./images/posp_module_img_eleven.png",
      heading: "Quote Generation",
      para: " Generate instant and accurate quotes for various insurance products.",
    },
    {
      image: "./images/posp_module_img_six.png",
      heading: "Customer Management",
      para: " Maintain a centralized database for organized customer information.",
    },
  ];

  const uspPOSArray = [
    {
      point_desc:
        "Seamless onboarding & documentation of POSPs with certification & examination.",
    },
    {
      point_desc:
        "Streamlined sales process Simplify and expedite insurance sales for intermediaries.",
    },
    {
      point_desc:
        "Seamless integration Integrate with carriers and systems for real-time access to information.",
    },
    {
      point_desc:
        "Enhanced customer experience: Deliver personalized and efficient service to customers.",
    },
    {
      point_desc:
        "Compliance and regulatory adherence: Ensure adherence to IRDAI regulations and standards for POSPs.",
    },
    {
      point_desc:
        "Data-driven insights: Gain valuable insights for informed decision-making.",
    },
    {
      point_desc:
        " Seamless integration Integrate with carriers and systems for real-time access to information.",
    },
    {
      point_desc:
        "Enhanced customer experience: Deliver personalized and efficient service to customers.",
    },
    {
      point_desc:
        "Compliance and regulatory adherence: Ensure adherence to IRDAI regulations and standards for POSPs.",
    },
    {
      point_desc:
        "Data-driven insights: Gain valuable insights for informed decision-making.",
    },
  ];

  const [showMoreListPoints, setShowMoreListPoints] = useState(false);
  const defaultItemsShow = 5;

  return (
    <>
      <MNavbar />

      <Box className="productpage-wrapper">
        <Box
          className="prouct-header"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            paddingTop={"40px"}
            paddingBottom={"16px"}
          >
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h2>
                Transform Your Point of SalesAmplify Success with our
                Cutting-Edge <span>InsurTech POS Platform</span>
              </h2>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="product_header_img">
                <img src="./images/posp_header_icon.svg" alt="about" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="product_info_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"12px"}>
              <Box className="inner_box">
                <img src="./images/posp_sec_icon.svg" alt="" />
                <h3 className="mb-2 mt-3">
                  What is <span>POS?</span>
                </h3>
                <p>
                  Our cutting-edge InsurTech POS platform empowers Point of
                  Sales (POS) agents to effortlessly sell and efficiently manage
                  insurance policies. With our solution, POS agents gain a
                  comprehensive and user-friendly platform that simplifies the
                  entire sales process. Our platform streamlines policy
                  issuance, automates documentation, and enables seamless
                  communication with insurers. POS agents can efficiently track
                  policy status, handle renewals, and manage customer
                  information, ensuring a smooth and organized workflow.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="module_desc_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"40px"}>
              <h3>
                Module & its <span>Description</span>
              </h3>
            </Grid>
            <Grid xs={12}>
              <ul className="pos_module_pointsList">
                {posmodulepoint_descArray.map((data) => (
                  <li>
                    <img src={data.image} alt="" />
                    <h6 className="mt-3 mb-1">{data.heading}</h6>
                    <p>{data.para}</p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="usp_section"
          padding={"20px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h3>
                Our <span>Unique Selling Point!</span>
              </h3>
            </Grid>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <img
                src="./images/usp_posp.svg"
                alt=""
                style={{ margin: "auto" }}
              />
            </Grid>
            <Grid xs={12}>
              <ul className="posp_usp_points">
                {(showMoreListPoints === true
                  ? uspPOSArray
                  : uspPOSArray.slice(0, defaultItemsShow)
                ).map((data, index) => (
                  <li key={index}>{data.point_desc}</li>
                ))}
                {uspPOSArray.length > defaultItemsShow && (
                  <Link
                    color={"#6458D8"}
                    onClick={() => setShowMoreListPoints(!showMoreListPoints)}
                  >
                    {showMoreListPoints === true ? "Read Less" : "Read More"}
                  </Link>
                )}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <MRequestDemo />
      </Box>

      <MFooter />
    </>
  );
};

export default MPosp;
