import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MPosp from "../../Pages/Mobile/MProductPages/MPosp/MPosp";
import Posp from "../../Pages/Desktop/ProductPages/Posp/Posp";

const PospContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MPosp /> : <Posp />;
};

export default PospContainer;
