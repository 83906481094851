import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MCrm from "../../Pages/Mobile/MProductPages/MCrm/MCrm";
import Crm from "../../Pages/Desktop/ProductPages/Crm/Crm";

const CrmContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MCrm /> : <Crm />;
};

export default CrmContainer;
