import React from 'react'
import Homepage from '../../Pages/Desktop/Homepage/Homepage';
import MHomepage from '../../Pages/Mobile/MHomePage/MHomepage';
import useIsMobile from '../../SupportingFiles/MobileProvider';

const HomeContainer = () => {
    const isMobile = useIsMobile();
  return isMobile ? (
    <MHomepage  />
  ) : (
    <Homepage />
  );
}

export default HomeContainer