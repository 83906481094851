import React, { useState } from "react";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../ProductPages/ProductPages.scss";
import RequestDemo from "../../../../Components/Desktop/RequestDemo/RequestDemo";
import Footer from "../../../../Components/Desktop/Footer/Footer";

function InsuranceAggregation() {
  const navbarStyle = { backgroundColor: "#6458D8", color: "#ffffff" };
  const image = "./images/insurevent_logo_white.svg";

  const uspIaggregationList = [
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Seamless Digital Experience:</span> Simplify the insurance
          buying process for B2C clients with a frictionless online journey.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image2.png",
      heading: (
        <>
          <span>Comprehensive Product Offering:</span> Access a wide range of
          insurance products to meet diverse client needs.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image3.png",
      heading: (
        <>
          <span>Personalized Recommendations:</span> Utilize advanced algorithms
          for tailored coverage options based on client requirements.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image4.png",
      heading: (
        <>
          <span>Streamlined Application Process:</span> Make applying for
          insurance quick and easy, boosting conversions.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image5.png",
      heading: (
        <>
          <span>Real-time Quoting:</span> Provide instant, accurate pricing
          information for faster decision-making.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image6.png",
      heading: (
        <>
          <span>Secure Online Transactions:</span> Prioritize secure payment
          processing for safe financial transactions.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Automated Policy Issuance:</span> Eliminate manual paperwork
          with efficient policy delivery.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Enhanced Customer Service:</span> Deliver exceptional support,
          communication, and responsiveness.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Data-driven Insights:</span> Utilize valuable analytics for
          informed decision-making and optimization.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Scalability and Flexibility:</span> Adapt to evolving needs,
          ensuring long-term competitiveness.
        </>
      ),
    },
  ];

  const [showUspPoints, setShowUspPoints] = useState(false);
  const defaultItemshow = 6;

  const modulelist = [
    {
      image: "./images/aggregation_image_one.png",
      headingone: "B2C Website",
      headingtwo: "B2C Website",
      para: "  Design exceptional B2C websites with Intuitive UI/UX for intermediaries, revolutionizing their online presence",
    },
    {
      image: "./images/aggregation_image_two.png",
      headingone: "Online Application",
      headingtwo: "Online Application",
      para: " Enables B2C clients to complete insurance applications  online, providing a user-friendly interface to input necessary information and select desired coverage options.",
    },
    {
      image: "./images/aggregation_image_three.png",
      headingone: "Quote Generation",
      headingtwo: "Quote Generation",
      para: "Generates instant quotes based on the client's input, providing real-time pricing estimates for different insurance policies.",
    },
    {
      image: "./images/aggregation_image_four.png",
      headingone: "Product Catalog",
      headingtwo: "Product Catalog",
      para: " A module that allows brokers to showcase a comprehensive  range of insurance products, including details on coverage, benefits, and pricing.",
    },
    {
      image: "./images/aggregation_image_five.png",
      headingone: "Policy Issuance",
      headingtwo: "Policy Issuance",
      para: "  Facilitates the issuance of insurance policies digitally,  eliminating the need for manual paperwork and expediting the process.",
    },
    {
      image: "./images/aggregation_image_six.png",
      headingone: "Document Management",
      headingtwo: "Document Management",
      para: "Stores and manages digital documents related to insurance policies, endorsements, and other relevant files.",
    },
    {
      image: "./images/aggregation_image_seven.png",
      headingone: "Payment Processing",
      headingtwo: "Payment Processing",
      para: " Integrates secure online payment gateways to enable clients to make premium payments electronically.",
    },
    {
      image: "./images/aggregation_image_eight.png",
      headingone: "Customer Portal",
      headingtwo: "Customer Portal",
      para: " Provides B2C clients with a personalized portal to access policy details, make changes, and view documents online.",
    },
    {
      image: "./images/aggregation_image_nine.png",
      headingone: "Communication and Notifications",
      headingtwo: "Communication and Notifications",
      para: "   Enables automated communication and notifications to keep  clients informed about policy updates, renewal reminders, and important announcements.",
    },
    {
      image: "./images/aggregation_image_ten.png",
      headingone: "Reporting and Analytics",
      headingtwo: "Reporting and Analytics",
      para: " Offers robust reporting and analytics capabilities, providing insights into sales performance, customer trends,  and policy metrics.",
    },
    {
      image: "./images/aggregation_image_eleven.png",
      headingone: "Integration with Insurance Carriers",
      headingtwo: "Integration with Insurance Carriers",
      para: "Integrates with insurance carriers' systems to facilitate seamless data exchange and ensure accurate policy information.",
    },
  ];

  return (
    <>
      <Navbar
        style={navbarStyle}
        image={image}
        class1="whiteMenu"
        border="border"
      />

      <Box className="productPage_wrapper">
        <Box className="productHeader_section" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={6} lg={6} alignSelf={"center"}>
              <h1>
                Unleash the Power of Digital Insurance Empower Intermediaries to
                Deliver <span>Seamless B2C Insurance Solutions.</span>
              </h1>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              textAlign={"end"}
              className="text-md-center"
            >
              <img
                src="./images/aggregation_headerimg.svg"
                alt="insurance-aggr"
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="productInfo_wrapper" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="productInfo_section">
                <Grid container spacing={3} className="row">
                  <Grid xs={6}>
                    <img src="./images/aggregation_platform_img.svg" alt="" />
                  </Grid>
                  <Grid xs={6} alignSelf={"center"}>
                    <h6>
                      What is a <span>Web-Aggregation Platform?</span>
                    </h6>
                    <p>
                      A digital/online insurance buying platform for
                      intermediaries refers to a technology-driven solution that
                      enables the intermediaries to offer their B2C
                      (business-to-consumer) clients a convenient and
                      streamlined way to purchase insurance online. It provides
                      a digital interface where intermediaries can showcase
                      various insurance products, facilitate online
                      applications, collect customer information, and process
                      policy issuance. This platform leverages digital tools and
                      automation to enhance the insurance buying experience,
                      simplifying the process for clients while empowering
                      intermediaries to efficiently manage and deliver insurance
                      solutions to their B2C customers.
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="aggregationmodule_description" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h6 className="heading mb-6">
                Module & its <span>Description</span>
              </h6>
            </Grid>

            <Grid xs={12}>
              <ul className="aggregation_description_list">
                {modulelist.map((data, index) => (
                  <li>
                    <div className="module_content">
                      <img src={data.image} />
                      <h6>{data.headingone}</h6>
                    </div>
                    <div className="module_overlay">
                      <h5>{data.headingtwo}</h5>
                      <p>{data.para}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Grid container columnSpacing={3} rowSpacing={0} className="row">
          <Grid xs={12}>
            <Box className="usp_section " marginBottom={"80px"} padding={"40px 28px"}>
              <Grid
                container
                rowSpacing={0}
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid xs={6} marginBottom={"28px"}>
                  <h6 className="heading mb-6">
                    Our <span>Unique Selling Point!</span>
                  </h6>
                  <img
                    src="./images/sellingpoint_aggregation.svg"
                    alt=""
                    style={{ margin: "auto" }}
                  />
                </Grid>
                <Grid xs={6}>
                  <ul className="uspErpList">
                    {(showUspPoints === true
                      ? uspIaggregationList
                      : uspIaggregationList.slice(0, defaultItemshow)
                    ).map((data, index) => (
                      <li>
                        <Box className="iconBox2">
                          <img src={data.image} alt="" />
                        </Box>
                        <p className="para">{data.heading}</p>
                      </li>
                    ))}
                    {uspIaggregationList.length > defaultItemshow && (
                      <Link
                        color={"#6458D8"}
                        onClick={() => setShowUspPoints(!showUspPoints)}
                      >
                        {showUspPoints === true ? "Read Less" : "Read More"}
                      </Link>
                    )}
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <RequestDemo
          formHeading={
            <>
              Request a<span> Demo</span>
            </>
          }
          formDesc={
            "Experience the Future: Request a Demo and Witness the Power of Our Solutions in Action!"
          }
          formImg_url={"./images/request_demo_img.svg"}
        />
      </Box>
      <Footer />
    </>
  );
}

export default InsuranceAggregation;
