import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../Components/Desktop/Footer/Footer";
import GetInTouch from "../../../Components/Desktop/GetInTouch/GetInTouch";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import "./Homepage.scss";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../Routes/AllRoutes";
//HomePageUmesh
function Homepage() {
  const [selectedUpcomingProd, setSelectedUpcomingProd] = useState(0);
  const [viewTabsStatus, setViewTabsStatus] = React.useState(0);
  const navigate = useNavigate();
  const [isContentDescVisible, setContentDescVisible] = useState(true); // Set to true to show content_desc by default

  const image = "./images/insurevent_logo.svg";

  const ourPartners = [
    {
      partner_img_url: "images/ahalia.svg",
    },
    {
      partner_img_url: "images/motilal.svg",
    },
    {
      partner_img_url: "images/jbboda.svg",
    },
    {
      partner_img_url: "images/jrk.svg",
    },
    {
      partner_img_url: "images/quickbima.svg",
    },
    {
      partner_img_url: "images/samyag.svg",
    },
    {
      partner_img_url: "images/auxilium.svg",
    },
    {
      partner_img_url: "images/shareIndia.svg",
    },
    {
      partner_img_url: "images/swastika.svg",
    },
    {
      partner_img_url: "images/bharatre.svg",
    },
    {
      partner_img_url: "images/policyPariwaar.svg",
    },
    {
      partner_img_url: "images/masuraksha.svg",
    },
    {
      partner_img_url: "images/instantbeema.svg",
    },
  ];
  const partnersResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 8,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 4,
      slidesToSlide: 1,
    },
  };

  const services_overview = [
    {
      img: "./images/service_img_one.png",
      title: "Digitisation of Insurance Business",
      desc: "Enabling customers to purchase insurance policies online, and providing digital tools such as real-time quotes, policy comparison tools, and digital document signing.",
    },
    {
      img: "./images/service_img_two.png",
      title: "Customer Product  Managment",
      desc: "Tailored insurance solutions designed to meet unique needs, leveraging technology and innovation - Product Strategy & Designing, Prototyping, Software Development & Testing",
    },
    {
      img: "./images/service_img_three.png",
      title: "Digitisation of Insurance Business",
      desc: "Enabling customers to purchase insurance policies online, and providing digital tools such as real-time quotes, policy comparison tools, and digital document signing.",
    },
    {
      img: "./images/service_img_four.png",
      title: "Customer Product  Managment",
      desc: "Tailored insurance solutions designed to meet unique needs, leveraging technology and innovation - Product Strategy & Designing, Prototyping, Software Development & Testing",
    },
  ];
  const servicesResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const client_feedback = [
    {
      img: "./images/testimonial_icon1.svg",
      name: "Mr. Manmoar lal",
      title: "- JB BODA",
      desc: "I have been a client of “ Evervent ” for many years now, and I cannot recommend them enough. Their products and services are top-notch and have exceeded my expectations every time. ",
      rating: 2.5,
    },
    {
      img: "./images/testimonial_icon2.svg",
      name: "Mr. Baljit Singh",
      title: "- BHARAT RE",
      desc: "I have been a client of “ Evervent ” for many years now, and I cannot recommend them enough. Their products and services are top-notch and have exceeded my expectations every time. ",
      rating: 3,
    },
    {
      img: "./images/testimonial_icon3.svg",
      name: "  Mr. Bharampal Singh",
      title: " - Instant Beema",
      desc: "I have been a client of “ Evervent ” for many years now, and I cannot recommend them enough. Their products and services are top-notch and have exceeded my expectations every time. ",
      rating: 4,
    },
    {
      img: "./images/testimonial_icon1.svg",
      name: "Mr. Manmoar lal",
      title: "- JB BODA",
      desc: "I have been a client of “ Evervent ” for many years now, and I cannot recommend them enough. Their products and services are top-notch and have exceeded my expectations every time. ",
      rating: 2,
    },
    {
      img: "./images/testimonial_icon2.svg",
      name: "Mr. Baljit Singh",
      title: "- BHARAT RE",
      desc: "I have been a client of “ Evervent ” for many years now, and I cannot recommend them enough. Their products and services are top-notch and have exceeded my expectations every time. ",
      rating: 1,
    },
    {
      img: "./images/testimonial_icon3.svg",
      name: "  Mr. Bharampal Singh",
      title: " - Instant Beema",
      desc: "I have been a client of “ Evervent ” for many years now, and I cannot recommend them enough. Their products and services are top-notch and have exceeded my expectations every time. ",
      rating: 3.5,
    },
  ];
  const feedbackResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const difference = [
    {
      img: "./images/insurevent_img_one.png",
      title: "Work with Digital Insurance Industry Veterans",
      desc: "Insurevent's journey has stood the test of time and delivered highly functional enterprise products with immense capability to handle a multitude of technical and business glitches with the shortest turnaround time. Building tough and robust systems drives our product capabilities further.",
    },
    {
      img: "./images/insurevent_img_two.png",
      title: "Platform not just built for scale but has handled scale",
      desc: "Insurevent has exhibited efficiency in handling large volume of digital traffic/clients with over 60 projects. Building products with the inherent trait of accommodating evolving nature of sales volume so that your business never suffers is our forte.",
    },
    {
      img: "./images/insurevent_img_three.png",
      title: "Work with Digital Insurance Industry Veterans",
      desc: "Insurevent's journey has stood the test of time and delivered highly functional enterprise products with immense capability to handle a multitude of technical and business glitches with the shortest turnaround time. Building tough and robust systems drives our product capabilities further.",
    },
    {
      img: "./images/insurevent_img_four.png",
      title: "Platform not just built for scale but has handled scale",
      desc: "Insurevent has exhibited efficiency in handling large volume of digital traffic/clients with over 60 projects. Building products with the inherent trait of accommodating evolving nature of sales volume so that your business never suffers is our forte.",
    },
  ];

  const differenceResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 2,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const counterBox = [
    {
      counter: "10",
      counterBox_desc: "Years of Experience ",
    },
    {
      counter: "1.2K",
      counterBox_desc: "Integration till now",
    },
    {
      counter: "25",
      counterBox_desc: "Projected Completed",
    },
    {
      counter: "20",
      counterBox_desc: "Trusted Partners",
    },
  ];

  const insurance_broker = [
    {
      img: "./images/insurance_broker_img1.svg",
      title: "Comprehensive Insurance Product Basket",
      desc: "Our comprehensive insurance product basket ensures the brokers to offer a wide range of coverage options to their clients, making them a one-stop-shop for insurance needs. This not only enhances customer satisfaction but also increases revenue opportunities for brokers.",
    },
    {
      img: "./images/insurance_broker_img2.svg",
      title: "Functional & Business Expertise",
      desc: "Our expertise across product lines equips insurance brokers with the knowledge and tools needed to navigate the complexities of different insurance products effectively. This further allows brokers to provide valuable advice to their clients, boosting their credibility and client trust.",
    },
    {
      img: "./images/insurance_broker_img3.svg",
      title: "Innovation & Future Proofing",
      desc: "We are committed to continuous innovation, ensuring that insurance brokers are equipped with the latest technological advancements, keeping them ahead of industry trends and future-proofing their operations. Our innovation centric teams endleslly work towards education, training & adoption of such advanced systems.",
    },
    {
      img: "./images/insurance_broker_img4.svg",
      title: "Collaborative Tech Ecosystem",
      desc: "Insurance brokers rely on us as their technology providers because our collaborative tech ecosystem not only fosters industry innovation but also ensures the utmost data security and sanctity, giving them the confidence to operate in an increasingly data-driven landscape.",
    },
  ];

  const headerleftSection = [
    {
      heading: (
        <>
          Global Digital Insurance <span>Distribution</span>{" "}
        </>
      ),
      description:
        "Leveraging technology to revolutionize the insurance industry and provide accessible, efficient, and customer-centric insurance solutions",
      imageUrl: "./images/header_img5.svg",
    },
    {
      heading: (
        <>
          Accelerating <span>insurance innovation-</span> simplicity meets
        </>
      ),
      description:
        "Combining simplicity and agility to create streamlined processes and flexible solutions that meet the evolving needs of the insurance industry and its customers.",
      imageUrl: "./images/header-img4.svg",
    },
    {
      heading: (
        <>
          Elevating efficiency, empowering insurance{" "}
          <span> business & sales</span>
        </>
      ),
      description:
        "Harnessing advanced technologies and data-driven strategies to optimize operations, enhance productivity, and enable insurance professionals to thrive in their business",
      imageUrl: "./images/header_img3.svg",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  const header_responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 901 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 900, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const product_basket = [
    {
      img: "./images/health_ins.svg",
      title: "Health Insurance",
      class: "health",
    },
    {
      img: "./images/term_ins.svg",
      title: "Term Insurance",
      class: "term",
    },
    {
      img: "./images/car_ins.svg",
      title: "Car Insurance",
      class: "car",
    },
    {
      img: "./images/bike_ins.svg",
      title: "Bike Insurance",
      class: "bike",
    },
    {
      img: "./images/travel_ins.svg",
      title: "Travel Insurance",
      class: "travel",
    },
    {
      img: "./images/investment_ins.svg",
      title: "Investment Plans",
      class: "investment",
    },
    {
      img: "./images/shopkeeper_ins.svg",
      title: "Shopkeeper Insurance",
      class: "shopkeeper",
    },
    {
      img: "./images/home_ins.svg",
      title: "Home Insurance",
      class: "home",
    },
    {
      img: "./images/commercial_ins.svg",
      title: "Commercial Vehicle",
      class: "commercial",
    },
  ];

  const upcomingProduct = [
    // {
    //   bigimage_url: "./images/commercial_img.png",
    //   heading: "Commercial Vehicle",
    //   desc: "From the basics to the ins & outs you'll find ample guidance to help you select the right life insurance policy for you Get the complete know-how, learn to ask the right questions, watch out for the tiny details & don't just sign up for any policy",

    //   tabheadingone: "Commercial ",
    //   tabheadingtwo: "Vehicle",
    // },
    {
      bigimage_url: "./images/dialpro_crm1.svg",
      heading: "Dial Pro CRM",
      desc: "Digital solution to make and receive phone calls directly from within the CRM platform, integrating with telephony systems or Voice over IP (VoIP) services, enabling sales and customer service teams to efficiently manage & track their communications, automate call logging, & streamline their workflow without leaving the CRM environment.",

      tabheadingone: "Dial Pro",
      tabheadingtwo: "CRM",
    },
    {
      bigimage_url: "./images/reinsurance_platform1.svg",
      heading: "Re-Insurance Platform",
      desc: "Seamless integraions for insurance companies to purchase & transfer a portion of their risk to other insurers, allowing them to mitigate their exposure to large or catastrophic losses.",

      tabheadingone: "Re-Insurance",
      tabheadingtwo: "Platform",
    },
    {
      bigimage_url: "./images/liability_ins1.svg",
      heading: "Liability Insurance",
      desc: "Seamless integrations for coverage for legal liabilities and financial obligations arising from third-party claims, protecting individuals and businesses against costs related to bodily injury, property damage, or other legal claims",

      tabheadingone: "Liability",
      tabheadingtwo: "Insurance",
    },
    {
      bigimage_url: "./images/marine_ins1.svg",
      heading: "Marine Insurance",
      desc: "Seamless integraions for covering the loss/damage of ships, cargo, terminals, and includes any other means of transport by which goods are transferred, acquired, or held between the points of origin and the final destination.",

      tabheadingone: "Marine",
      tabheadingtwo: "Insurance",
    },
    {
      bigimage_url: "./images/fire_ins1.svg",
      heading: "Fire Insurance",
      desc: "Seamless integrations for property insurance that provides coverage against damages and losses caused by fire, including damage to buildings, contents, and associated expenses",

      tabheadingone: "Fire",
      tabheadingtwo: "Insurance",
    },
  ];

  const insurance_offering = [
    {
      tabHeading: "Intermediaries",

      array: [
        {
          url: ALL_ROUTES.ERP,
          img: "./images/navbar_erp.svg",
          title: "Enterprise Resource Planning /BMS",
          desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
        },
        {
          url: ALL_ROUTES.POSP,
          img: "./images/navbar_posp.svg",
          title: "Point of Sales Person",
          desc: " Compliant Paperless Onboarding of Agents, 24/7 Instant  Policy Issuance, integrations with ERP & CRM designed to  empower salesperson, aiding them in maximizing their  policy sales & management.",
        },
        {
          url: ALL_ROUTES.CRM,
          img: "./images/navbar_intermediaries.svg",
          title: "Customer Relationship Management",
          desc: " Enhancing communication via interactive dashboard, streamlining policy data collection & providing real-time insights into leads & clients interactions resulting in elevated client satisfaction and increased  sales.",
        },
        {
          url: ALL_ROUTES.INSURANCE_AGGREGATION,
          img: "./images/navbar_iap.svg",
          title: "Insurance Aggregation Platform & Application",
          desc: " Centralized interface for comparing and purchasing policy from multiple carriers, saving time and improving  service for their clients",
        },
        {
          url: ALL_ROUTES.SELF_INSPECTION,
          img: "./images/navbar_sia.svg",
          title: "Self Inspection Application",
          desc: "Application providing Break-In Handling, Easy Inspection, Real-Time Data Collection, Multimedia Support, & integrations with Back-End Systems for real-time assessments and automated underwriting enabling faster policy issuance.",
        },
        {
          url: ALL_ROUTES.EB,
          img: "./images/navbar_ebplatform.svg",
          title: "Employee Benefit Platform",
          desc: " Boost your corporate sales by 35% with our employee benefits platform that is comprehensive, user-friendly & cost-effective in managing & rationanlizing the process of offering various insurance options to employees leading to their increased satisfaction & rentention rate.",
        },
      ],
    },
    {
      tabHeading: "Insurers",
      array: [
        {
          url: "",
          img: "./images/navbar_erp.svg",
          title: "Integration Kits & SDK's",
          desc: "Advanced API Kits complete with Quotation, Proposal, Policy Issuance, Policy Document, Schedulers",
        },
        {
          url: "",
          img: "./images/navbar_sia.svg",
          title: "Online Transaction Journey",
          desc: "Instant Policy Issuance, Real-Time Premium Calculation, Policy & Customer Lifecycle Management",
        },
        {
          url: "",
          img: "./images/navbar_intermediaries.svg",
          title: "Customer Enterprise Software Systems",
          desc: "Customer Retention System, Advanced Data Security, Pipeline/Lead Management Systems, Realtime Interactive Emailers/SMS",
        },
        {
          url: "",
          img: "./images/navbar_iap.svg",
          title: "Integration Support for Sales Partners",
          desc: "Automated Processes, Develop need-pay Off, Dialer Systems, Real time Quotation System",
        },
        {
          url: "",
          img: "./images/navbar_ebplatform.svg",
          title: "Development Services",
          desc: "Product Designing, Prototyping, Software Development & Testing",
        },
      ],
    },
    {
      tabHeading: "Banks",
      array: [
        {
          url: ALL_ROUTES.ERP,
          img: "./images/navbar_erp.svg",
          title: "Enterprise Resource Planning /BMS",
          desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
        },
        {
          url: ALL_ROUTES.CRM,
          img: "./images/navbar_intermediaries.svg",
          title: "Customer Relationship Manager",
          desc: " Enhancing communication via interactive dashboard, streamlining policy data collection & providing real-time insights into leads & clients interactions resulting in elevated client satisfaction and increased  sales.",
        },
      ],
    },
    {
      tabHeading: "NBFCs",
      array: [
        {
          url: ALL_ROUTES.SELF_INSPECTION,
          img: "./images/navbar_sia.svg",
          title: "Self Inspection Application",
          desc: "Application providing Break-In Handling, Easy Inspection, Real-Time Data Collection, Multimedia Support, & integrations with Back-End Systems for real-time assessments and automated underwriting enabling faster policy issuance.",
        },
        {
          url: ALL_ROUTES.ERP,
          img: "./images/navbar_erp.svg",
          title: "Enterprise Resource Planning /BMS",
          desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
        },
        {
          url: ALL_ROUTES.EB,
          img: "./images/navbar_ebplatform.svg",
          title: "Employee Benefit Platform",
          desc: " Boost your corporate sales by 35% with our employee benefits platform that is comprehensive, user-friendly & cost-effective in managing & rationanlizing the process of offering various insurance options to employees leading to their increased satisfaction & rentention rate.",
        },
        {
          url: ALL_ROUTES.POSP,
          img: "./images/navbar_posp.svg",
          title: "Point of Sales Person",
          desc: " Compliant Paperless Onboarding of Agents, 24/7 Instant  Policy Issuance, integrations with ERP & CRM designed to  empower salesperson, aiding them in maximizing their  policy sales & management.",
        },
      ],
    },
    {
      tabHeading: "OEM",
      array: [
        {
          url: ALL_ROUTES.ERP,
          img: "./images/navbar_erp.svg",
          title: "Enterprise Resource Planning /BMS",
          desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
        },
        {
          url: ALL_ROUTES.CRM,
          img: "./images/navbar_intermediaries.svg",
          title: "Customer Relationship Manager",
          desc: " Enhancing communication via interactive dashboard, streamlining policy data collection & providing real-time insights into leads & clients interactions resulting in elevated client satisfaction and increased  sales.",
        },
      ],
    },
    {
      tabHeading: "Corporates",
      array: [
        {
          url: ALL_ROUTES.SELF_INSPECTION,
          img: "./images/navbar_sia.svg",
          title: "Self Inspection Application",
          desc: "Application providing Break-In Handling, Easy Inspection, Real-Time Data Collection, Multimedia Support, & integrations with Back-End Systems for real-time assessments and automated underwriting enabling faster policy issuance.",
        },
        {
          url: ALL_ROUTES.ERP,
          img: "./images/navbar_erp.svg",
          title: "Enterprise Resource Planning /BMS",
          desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
        },
        {
          url: ALL_ROUTES.INSURANCE_AGGREGATION,
          img: "./images/navbar_iap.svg",
          title: "Insurance Aggregation Platform & Application",
          desc: " Centralized interface for comparing and purchasing policy from multiple carriers, saving time and improving  service for their clients",
        },
      ],
    },
    {
      tabHeading: "MISP Dealers",
      array: [
        {
          url: ALL_ROUTES.SELF_INSPECTION,
          img: "./images/navbar_sia.svg",
          title: "Self Inspection Application",
          desc: "Application providing Break-In Handling, Easy Inspection, Real-Time Data Collection, Multimedia Support, & integrations with Back-End Systems for real-time assessments and automated underwriting enabling faster policy issuance.",
        },
        {
          url: ALL_ROUTES.ERP,
          img: "./images/navbar_erp.svg",
          title: "Enterprise Resource Planning /BMS",
          desc: " Comprehensive Policy Lifecycle, Automated renewal, Lead & Revenue Management System in a one fit all package to let you experience the future of insurance today.",
        },
        {
          url: ALL_ROUTES.EB,
          img: "./images/navbar_ebplatform.svg",
          title: "Employee Benefit Platform",
          desc: " Boost your corporate sales by 35% with our employee benefits platform that is comprehensive, user-friendly & cost-effective in managing & rationanlizing the process of offering various insurance options to employees leading to their increased satisfaction & rentention rate.",
        },
        {
          url: ALL_ROUTES.POSP,
          img: "./images/navbar_posp.svg",
          title: "Point of Sales Person",
          desc: " Compliant Paperless Onboarding of Agents, 24/7 Instant  Policy Issuance, integrations with ERP & CRM designed to  empower salesperson, aiding them in maximizing their  policy sales & management.",
        },
      ],
    },
  ];

  return (
    <>
      <Navbar image={image} />
      <Box className="homepage_wrapper">
        {/* header section */}
        <div className="header">
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={header_responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                customTransition="transform 800ms ease-in-out"
              >
                {headerleftSection.map((data, index) => (
                  <Grid container spacing={3} className="row" key={index}>
                    <Grid xs={12}>
                      <div
                        className={`header_outer_content ${
                          activeSlide === index ? "active" : ""
                        }`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="header_content">
                          <h2>{data.heading}</h2>
                          <p>{data.description}</p>
                        </div>
                        <div
                          className={`img_section ${
                            activeSlide === index ? "active" : ""
                          }`}
                        >
                          <div className={`headerimg slide slide${index}`}>
                            <img
                              src={data.imageUrl}
                              alt={`Slide ${index + 1}`}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </div>
        {/* partner section */}
        <Box className="partners_section" marginBottom="80px">
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="innerSection">
                <h2 className="text-center heading">
                  Trusted by the <span>biggest</span> Brands in the business
                </h2>
                <Box className="carousel-part">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={partnersResponsive}
                    infinite={true}
                    autoPlay={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    autoPlaySpeed={3000}
                    arrows={false}
                  >
                    {ourPartners.map((data, index) => (
                      <Box className="partner_box">
                        <img src={data.partner_img_url} alt="" />
                      </Box>
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* insurance broker section */}
        <Box className="insurance_broker" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h5 className="heading mb-1">
                Why <span>Leading Insurance Brokers</span> depend on us !
              </h5>
              <p className="para mb-4">
                Unlocking the Future of Brokerage Success: Our Commitment to
                Technological Excellence and Business Innovation
              </p>
            </Grid>

            {insurance_broker.map((data, index) => (
              <Grid sm={12} md={6} lg={6} xl={6}>
                <Box className="innerSection">
                  <Box className="img_section">
                    <img src={data.img} className="image" />
                  </Box>
                  <Box className="text_section">
                    <h6>{data.title}</h6>
                    <p>{data.desc}</p>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* insurance offering */}
        <Box className="insurance_offering" marginBottom={"40px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <h5 className="heading mb-5 text-center">
                Bouquet of <span> Best-in- Market Digital Insurance</span>{" "}
                Offerings
              </h5>
            </Grid>
            <Grid xs={12}>
              <ul className="tab_list">
                {insurance_offering.map((data, index) => (
                  <li>
                    <Button
                      className={
                        viewTabsStatus === index
                          ? "defaultTab active "
                          : "defaultTab "
                      }
                      onClick={() => {
                        setViewTabsStatus(index);
                      }}
                    >
                      {data.tabHeading}
                    </Button>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid xs={12}>
              {insurance_offering[viewTabsStatus]?.array.map((data, index) => (
                <div key={index}>
                  <Box
                    className={`content_box ${
                      isContentDescVisible ? "show_desc" : ""
                    }`}
                    onMouseEnter={() => setContentDescVisible(true)}
                    onMouseLeave={() => setContentDescVisible(false)}
                    onClick={() =>navigate(data.url) }
                  >
                    <Box className="content_title">
                      <img src={data.img} />
                      <h6>{data.title}</h6>
                    </Box>
                    <Box
                      className={`content_desc ${
                        isContentDescVisible ? "visible" : ""
                      }`}
                    >
                      <p>{data.desc}</p>
                    </Box>
                  </Box>
                </div>
              ))}
            </Grid>
          </Grid>
        </Box>

        {/* insurance product basket */}
        <Box className="product_basket" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <h5 className="heading mb-4 text-center">
                <span> Comprehensive Insurance</span> Product Basket
              </h5>
              <ul>
                {product_basket.map((data, index) => (
                  <li>
                    <Box className={`image_Section ${data.class}`}>
                      <img src={data.img} />
                    </Box>
                    <Box className="text_section">
                      <h6>{data.title.split(" ")[0]}</h6>
                      <h6>{data.title.split(" ")[1]}</h6>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        {/* upcoming product */}

        <Box className="upcomimg_product" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h5 className="heading mb-1">
                Upcoming <span>Products</span>
              </h5>
              <p className="para mb-4">
                We provide the best range of plans from various insurance
                companies for you to compare.
              </p>
            </Grid>
            <Grid xs={12} className="upcoming_innersection">
              <Grid container spacing={3}>
                <>
                  <Grid xs={6} className="img_section">
                    <img
                      src={upcomingProduct[selectedUpcomingProd].bigimage_url}
                      height="265"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <h5 className="upcoming_heading">
                      {upcomingProduct[selectedUpcomingProd].heading}
                    </h5>
                    <p className="upcoming_content">
                      {upcomingProduct[selectedUpcomingProd].desc}
                    </p>
                  </Grid>
                </>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid container spacing={3} style={{ width: "100%" }}>
                {upcomingProduct.map((data, index) => (
                  <Grid xs>
                    <div
                      style={{
                        // width: 170,
                        height: 150,
                        position: "relative",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedUpcomingProd(index);
                      }}
                    >
                      <img
                        className={
                          selectedUpcomingProd === index ? "" : "img_filter"
                        }
                        style={{
                          width: 100,
                          height: 100,
                          position: "relative",
                          zIndex: 99,
                          alignSelf: "center",
                          left: "calc(40% - 30px)",
                        }}
                        src={data.bigimage_url}
                      />
                      <div
                        className={
                          selectedUpcomingProd === index ? "" : "bg_filter"
                        }
                        style={{
                          borderBottomLeftRadius: 60,
                          borderBottomRightRadius: 60,
                          width: 120,
                          height: 80,
                          backgroundColor: "#6458D8",
                          position: "relative",
                          top: -50,
                          zIndex: 0,
                          display: "inline-block",
                        }}
                      />
                    </div>

                    <p style={{ textAlign: "center" }}>
                      {data.tabheadingone} <br />
                      {data.tabheadingtwo}
                    </p>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* insurvent difference */}
        <Box className="difference_section" marginBottom="80px">
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="difference_innerSection">
                <h2 className="text-center heading mb-2">
                  <span> Insurvent Differentiators !</span>
                </h2>
                <p className="para text-center">
                  "Explore our products, experience our benefits"-
                </p>
                <Box className="difference-carousel-part">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={differenceResponsive}
                    infinite={true}
                    autoPlay={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    autoPlaySpeed={3000}
                    arrows={false}
                    customTransition={"transform 800ms ease-in-out"}
                  >
                    {difference.map((data, index) => (
                      <Box className="difference_box">
                        <Box className="image_section">
                          <img src={data.img} alt="" />
                        </Box>
                        <Box className="text_section">
                          <h6>{data.title}</h6>
                          <p>{data.desc}</p>
                        </Box>
                      </Box>
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* overview section */}
        <Box className="services_section" marginBottom="80px">
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="services_innerSection">
                <h2 className="text-center heading mb-2">
                  Services <span>Overview</span>
                </h2>
                <p className="para text-center mb-10">
                  We provide the best range of plans from various insurance
                  companies for you to compare.
                </p>
                <Box className="services-carousel-part">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={servicesResponsive}
                    infinite={true}
                    autoPlay={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    autoPlaySpeed={3000}
                    arrows={false}
                    customTransition={"transform 800ms ease-in-out"}
                  >
                    {services_overview.map((data, index) => (
                      <Box className="services_box">
                        <img src={data.img} alt="" />
                        <h6>{data.title}</h6>
                        <p>{data.desc}</p>
                      </Box>
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* counter section */}
        <Box className="counter_section" marginBottom="80px">
          <Grid container spacing={3} className="row" padding={"24px"}>
            <Grid xs={6}>
              <Box className="counter_section_inner">
                <h6 className="heading mb-12">
                  We <span>promise, grow</span> & <span>retain</span> the best
                  practices in the market !
                </h6>
                <ul>
                  {counterBox.map((data, index) => (
                    <li>
                      <div className="counterdiv">
                        <h3>{`${data.counter}+`}</h3>
                        <p>{data.counterBox_desc}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
            <Grid xs={6} textAlign={"end"}>
              <img
                src="./images/counter_img.svg"
                alt=""
                height={500}
                style={{ display: "inline-block" }}
              />
            </Grid>
          </Grid>
        </Box>
        {/* client feedback */}
        <Box className="feedbacksection" marginBottom="80px">
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="feedback_innerSection">
                <h2 className="text-center heading mb-2">
                  Client <span>Feedback</span>
                </h2>
                <p className="para text-center">
                  "Explore our products, experience our benefits"-
                </p>
                <Box className="feedback-carousel-part">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={feedbackResponsive}
                    infinite={true}
                    autoPlay={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    autoPlaySpeed={3000}
                    arrows={false}
                    customTransition={"transform 800ms ease-in-out"}
                  >
                    {client_feedback.map((data, index) => (
                      <Box className="feedback_box">
                        <Box position={"relative"}>
                          <img src={data.img} />
                          <span className="quotes_img"></span>
                        </Box>
                        <h6>
                          {data.name} <span>{data.title}</span>
                        </h6>
                        <p>{data.desc}</p>
                        <Stack spacing={1} textAlign={"center"}>
                          <Rating
                            name="half-rating"
                            defaultValue={data.rating}
                            precision={0.5}
                            readOnly
                          />
                        </Stack>
                      </Box>
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* insurer partner section */}
        <Box>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="bluebg_box">
                <h5>
                  “Forging unbreakable bonds of trust with insurers, we power a
                  future where technology and partnership redefine the insurance
                  landscape”
                </h5>
                <ArrowForwardIcon
                  onClick={() => navigate(ALL_ROUTES.INSURANCE_PARTNERS)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* get in touch section */}

        <GetInTouch />
      </Box>
      <Footer />
    </>
  );
}

export default Homepage;
