import React, { useState } from "react";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../ProductPages/ProductPages.scss";
import RequestDemo from "../../../../Components/Desktop/RequestDemo/RequestDemo";
import Footer from "../../../../Components/Desktop/Footer/Footer";

function Posp() {
  const navbarStyle = { backgroundColor: "#6458D8", color: "#ffffff" };
  const image = "./images/insurevent_logo_white.svg";

  const uspPOSArray = [
    {
      point_desc:
        "Seamless onboarding & documentation of POSPs with certification & examination.",
    },
    {
      point_desc:
        "Streamlined sales process Simplify and expedite insurance sales for intermediaries.",
    },
    {
      point_desc:
        "Seamless integration Integrate with carriers and systems for real-time access to information.",
    },
    {
      point_desc:
        "Enhanced customer experience: Deliver personalized and efficient service to customers.",
    },
    {
      point_desc:
        "Compliance and regulatory adherence: Ensure adherence to IRDAI regulations and standards for POSPs.",
    },
    {
      point_desc:
        "Data-driven insights: Gain valuable insights for informed decision-making.",
    },
    {
      point_desc:
        " Seamless integration Integrate with carriers and systems for real-time access to information.",
    },
    {
      point_desc:
        "Enhanced customer experience: Deliver personalized and efficient service to customers.",
    },
    {
      point_desc:
        "Compliance and regulatory adherence: Ensure adherence to IRDAI regulations and standards for POSPs.",
    },
    {
      point_desc:
        "Data-driven insights: Gain valuable insights for informed decision-making.",
    },
  ];

  const [showMoreListPoints, setShowMoreListPoints] = useState(false);
  const defaultItemsShow = 5;

  const modulelist = [
    {
      image: "./images/posp_module_img_one.png",
      headingone: "Pos Agent Profile",
      headingtwo: "  POS Agent Profile",
      para: "  Manage and update professional information, including contact details and licensing information, ensuring accurate and up-to-date profiles.",
    },
    {
      image: "./images/posp_module_img_two.png",
      headingone: "Dashboard",
      headingtwo: "Dashboard",
      para: "Monitor sales performance, commissions, and incentives through a comprehensive account management section.",
    },
    {
      image: "./images/posp_module_img_three.png",
      headingone: "Pos Agent Onboarding",
      headingtwo: "Pos Agent Onboarding",
      para: "Effortlessly onboard agents and fast-track their success with our streamlined and user-friendly agent onboarding process.",
    },
    {
      image: "./images/posp_module_img_four.png",
      headingone: "Policy Comparision",
      headingtwo: "Policy Comparision",
      para: "Compare coverage options to recommend the most suitable policies.",
    },
    {
      image: "./images/posp_module_img_five.png",
      headingone: "Policy Issuance",
      headingtwo: "Policy Issuance",
      para: "Streamline policy issuance with automated documentation processes.",
    },
    {
      image: "./images/posp_module_img_six.png",
      headingone: "Customer Management",
      headingtwo: "Customer Management",
      para: " Maintain a centralized database for organized customer information.",
    },
    {
      image: "./images/posp_module_img_seven.png",
      headingone: "Renewals",
      headingtwo: "Renewals",
      para: "Simplify policy renewals and handle endorsements efficiently.",
    },

    {
      image: "./images/posp_module_img_one.png",
      headingone: "Leads Management",
      headingtwo: "Leads Management",
      para: "Efficient lead management system helps address the challenge of managing & effectively engaging prospective buyers",
    },
    {
      image: "./images/posp_module_img_eight.png",
      headingone: "Digital Repository",
      headingtwo: "Digital Repository",
      para: "Store and retrieve policy documents securely in a digital repository.",
    },
    {
      image: "./images/posp_module_img_nine.png",
      headingone: "Communication and Notifications",
      headingtwo: "Communication and Notifications",
      para: " Facilitate seamless communication with clients through integrated messaging systems.",
    },
    {
      image: "./images/posp_module_img_ten.png",
      headingone: "Reporting and Analytics",
      headingtwo: "Reporting and Analytics",
      para: " Access valuable insights to track performance and optimize sales strategies.",
    },
    {
      image: "./images/posp_module_img_eleven.png",
      headingone: "Quote Generation",
      headingtwo: "Quote Generation",
      para: " Generate instant and accurate quotes for various insurance products.",
    },
  ];

  const formHeading = (
    <>
      Request a<span> Demo</span>
    </>
  );
  const formDesc =
    "Experience the Future: Request a Demo and Witness the Power of Our Solutions in Action!";
  const formImg_url = "./images/request_demo_img.svg";
  return (
    <>
      <Navbar
        style={navbarStyle}
        image={image}
        class1="whiteMenu"
        border="border"
      />

      <Box className="productPage_wrapper">
        <Box className="productHeader_section" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={6} lg={6} alignSelf={"center"}>
              <h1>
                Transform Your <span>Point of Sales</span> Amplify Success with
                our Cutting-Edge InsurTech POS Platform
              </h1>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              textAlign={"end"}
              className="text-md-center"
            >
              <img src="./images/posp_header_icon.svg" alt="posp" />
            </Grid>
          </Grid>
        </Box>

        <Box className="productInfo_wrapper" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="productInfo_section">
                <Grid container spacing={3} className="row">
                  <Grid xs={6}>
                    <img src="./images/posp_sec_icon.svg" alt="" />
                  </Grid>
                  <Grid xs={6} alignSelf={"center"}>
                    <h6>
                      What is <span>POS?</span>
                    </h6>
                    <p>
                      Our cutting-edge InsurTech POS platform empowers Point of
                      Sales (POS) agents to effortlessly sell and efficiently
                      manage insurance policies. With our solution, POS agents
                      gain a comprehensive and user-friendly platform that
                      simplifies the entire sales process. Our platform
                      streamlines policy issuance, automates documentation, and
                      enables seamless communication with insurers. POS agents
                      can efficiently track policy status, handle renewals, and
                      manage customer information, ensuring a smooth and
                      organized workflow.
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="popsp_module_description" marginBottom={"80px"}>
          <Grid container columnSpacing={3} className="row ">
            <Grid xs={12} textAlign={"center"}>
              <h6 className="heading mb-6">
                Module & <span>Description</span>
              </h6>
            </Grid>
            <Grid xs={12}>
              <ul className="posp_module_description_list">
                {modulelist.map((data, index) => (
                  <li>
                    <div className="module_content">
                      <img src={data.image} />
                      <h6>{data.headingone}</h6>
                    </div>
                    <div className="module_overlay">
                      <h5>{data.headingtwo}</h5>
                      <p>{data.para}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="usp_section uspPosp" marginBottom={"80px"}>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={6}>
              <h6 className="heading mb-5">
                Our <span>Unique Selling Point!</span>
              </h6>
              <ul className="posp_usp_points">
                {(showMoreListPoints === true
                  ? uspPOSArray
                  : uspPOSArray.slice(0, defaultItemsShow)
                ).map((data, index) => (
                  <li key={index}>{data.point_desc}</li>
                ))}
                {uspPOSArray.length > defaultItemsShow && (
                  <Link
                    color={"#6458D8"}
                    onClick={() => setShowMoreListPoints(!showMoreListPoints)}
                  >
                    {showMoreListPoints === true ? "Read Less" : "Read More"}
                  </Link>
                )}
              </ul>
            </Grid>
            <Grid xs={6} textAlign={"center"} marginBottom={"28px"}>
              <img src="./images/selling_dashboard_image.svg" alt="" />
            </Grid>
          </Grid>
        </Box>

        <RequestDemo
          formHeading={formHeading}
          formDesc={formDesc}
          formImg_url={formImg_url}
        />
      </Box>
      <Footer />
    </>
  );
}

export default Posp;
