import React, { useState, useEffect } from "react";
import "./MNavbar.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import MenuPopup from "./MenuPopup/MenuPopup";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../Routes/AllRoutes";
import GetInTouchModal from "../../Desktop/GetInTouchModal/GetInTouchModal";

const MNavbar = () => {
  const [slideOpen, setSlideOpen] = useState(false);
  const navigate = useNavigate()

  const togglePanel = () => {
    setSlideOpen(!slideOpen);
  };
  if (slideOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  const [openGetintouch, setOpenGetintouch] = useState(false);
  
  return (
    <Box className="navbar_m">
      <MenuPopup open={slideOpen} setOpen={setSlideOpen} />
      <GetInTouchModal open={openGetintouch} setOpen={setOpenGetintouch} />
      <Grid container rowSpacing={0} columnSpacing={3} alignItems={"center"}>
        <Grid xs={7} className="logo-section">
          <Link onClick={() => navigate(ALL_ROUTES.HOMEPAGE)}>
            <img
              src="./images/insurevent_logo.svg"
              alt="insurvent"
              className="logo"
            />
          </Link>
        </Grid>
        <Grid
          xs={5}
          alignSelf={"center"}
          display={"flex"}
          justifyContent={"end"}
        >
          <Link className="menuIcon" onClick={() => setOpenGetintouch(true)}>
            <img src="./images/profile_icon.svg" alt="" />
          </Link>
          <Link
            className="menuIcon"
            marginLeft={"10px"}
            onClick={() => setSlideOpen(true)}
          >
            <img src="./images/menu_icon.svg" alt="" />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MNavbar;
