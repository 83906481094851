import React, { useState } from "react";
import "./RequestDemo.scss";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import RKTextField from "../../FieldTypes/RKTextField/RKTextField";
import TextArea from "../../FieldTypes/TextArea/TextArea";
import { ContactForm } from "../../../Service/Type/ContactForm";
import CustomButton from "../../CustomButton/CustomButton";

interface formProps {
  formHeading: any;
  formDesc: string;
  formImg_url: string;
}

const RequestDemo: React.FC<formProps> = ({
  formHeading,
  formDesc,
  formImg_url,
}) => {
  const [formFields, setFormFields] = useState<ContactForm>({
    name: { value: "", warning: false },
    email: { value: "", warning: false },
    organization: { value: "", warning: false },
    mobile: { value: "", warning: false },
    message: { value: "", warning: false },
  });
  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const validateform = () => {
    let contactFormFieldData: ContactForm = formFields;
    contactFormFieldData.name.warning =
      formFields.name.value.length === 0 ? true : false;
    contactFormFieldData.email.warning =
      formFields.email.value.length === 0 ? true : false;
    contactFormFieldData.organization.warning =
      formFields.organization.value.length === 0 ? true : false;
    contactFormFieldData.mobile.warning =
      formFields.mobile.value.length === 0 ? true : false;
    contactFormFieldData.message.warning =
      formFields.message.value.length === 0 ? true : false;

    setFormFields({ ...contactFormFieldData });
  };
  return (
    <Box className="request_wrapper">
      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <div className="request_demo">
            <Grid container spacing={3}>
              <Grid sm={12} md={12} lg={6} className="text-md-center">
                <h6>{formHeading}</h6>
                <p>{formDesc}</p>
                <img src={formImg_url} alt="" />
              </Grid>
              <Grid sm={12} md={12} lg={6} className="pb-0">
                <div className="crm_formSection">
                  <Grid container rowSpacing={2.5}>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField "
                        title={"Name"}
                        value={formFields.name.value}
                        attrName={["name", "value", formFields, setFormFields]}
                        value_update={fieldsUpdateState}
                        warn_status={formFields.name.warning}
                        error_messg="Please enter name"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField "
                        title={"Organization"}
                        value={formFields.organization.value}
                        attrName={[
                          "organization",
                          "value",
                          formFields,
                          setFormFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={formFields.organization.warning}
                        error_messg="Please enter organization"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField "
                        title={"Phone Number"}
                        value={formFields.mobile.value}
                        attrName={[
                          "mobile",
                          "value",
                          formFields,
                          setFormFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={formFields.mobile.warning}
                        error_messg="Please enter number"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField "
                        title={"Email ID"}
                        value={formFields.email.value}
                        attrName={["email", "value", formFields, setFormFields]}
                        value_update={fieldsUpdateState}
                        warn_status={formFields.email.warning}
                        error_messg="Please enter email"
                      />
                    </Grid>

                    <Grid xs={12}>
                      <TextArea
                        class_name="inputField "
                        title={"Message"}
                        value={formFields.message.value}
                        placeholder={"Enter description here"}
                        attrName={[
                          "message",
                          "value",
                          formFields,
                          setFormFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={formFields.message.warning}
                        error_messg="Please enter message"
                      />
                    </Grid>
                    <Grid xs={12} className="ctaBtn">
                      <CustomButton
                        class_name="blueXlBtn"
                        text_name="Submit Details"
                        onClickFunction={validateform}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RequestDemo;
