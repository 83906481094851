import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ALL_ROUTES } from "./Routes/AllRoutes";
import HomeContainer from "./Container/HomeContainer/HomeContainer";
import ErpContainer from "./Container/ProductPageContainer/ErpContainer";
import PospContainer from "./Container/ProductPageContainer/PospContainer";
import CrmContainer from "./Container/ProductPageContainer/CrmContainer";
import IGContainer from "./Container/ProductPageContainer/InsuranceAggregationContainer";
import EBContainer from "./Container/ProductPageContainer/EBContainer";
import AboutContainer from "./Container/CMSPagesContainer/AboutContainer";
import ContactContainer from "./Container/CMSPagesContainer/ContactContainer";
import IPContainer from "./Container/CMSPagesContainer/InsurancePartnersContainer";
import SIContainer from "./Container/ProductPageContainer/SelfInspectionContainer";
import IntegrationServicesContainer from "./Container/ProductPageContainer/IntegrationServicesContainer";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={ALL_ROUTES.HOMEPAGE} element={<HomeContainer />} />
          <Route path={ALL_ROUTES.CRM} element={<CrmContainer />} />
          <Route path={ALL_ROUTES.POSP} element={<PospContainer />} />
          <Route path={ALL_ROUTES.ERP} element={<ErpContainer />} />
          <Route path={ALL_ROUTES.EB} element={<EBContainer />} />
          <Route path={ALL_ROUTES.SELF_INSPECTION} element={<SIContainer />} />
          <Route
            path={ALL_ROUTES.INSURANCE_AGGREGATION}
            element={<IGContainer />}
          />
          <Route path={ALL_ROUTES.CONTACTUS} element={<ContactContainer />} />
          <Route path={ALL_ROUTES.ABOUT} element={<AboutContainer />} />
          <Route path={ALL_ROUTES.INSURANCE_PARTNERS} element={<IPContainer />} />
          <Route path={ALL_ROUTES.INTEGRATION_SERVICES} element={<IntegrationServicesContainer />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
