export enum ALL_ROUTES {
  HOMEPAGE = "/",
  CRM = "/crm",
  POSP = "/posp",
  ERP = "/erp",
  INSURANCE_AGGREGATION = "/insurance-aggregation",
  CONTACTUS = "/contact",
  ABOUT = "/About",
  EB = "/employee-benefit",
  SELF_INSPECTION = "/self-inspection",
  INSURANCE_PARTNERS = "/insurance-partners",
  INTEGRATION_SERVICES = "/integration-services",
}
