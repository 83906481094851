import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MAboutus from "../../Pages/Mobile/MCMSPages/MAboutus/MAboutus";
import AboutUs from "../../Pages/Desktop/CMSPages/AboutUs/AboutUs";
import MOurInsurancePartner from "../../Pages/Mobile/MCMSPages/MOurInsurancePartner/MOurInsurancePartner";
import OurInsurancePartner from "../../Pages/Desktop/CMSPages/OurInsurancePartner/OurInsurancePartner";

const IPContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MOurInsurancePartner /> : <OurInsurancePartner />;
};

export default IPContainer;
