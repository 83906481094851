import React, { useState } from "react";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../ProductPages/ProductPages.scss";
import RequestDemo from "../../../../Components/Desktop/RequestDemo/RequestDemo";
import Footer from "../../../../Components/Desktop/Footer/Footer";

function Crm() {
  const navbarStyle = { backgroundColor: "#6458D8", color: "#ffffff" };
  const image = "./images/insurevent_logo_white.svg";

  const uspcrmlistArray = [
    {
      point_dsec: (
        <>
          Comprehensive overview of{" "}
          <span> Business Performance and Analytics.</span>
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Seamless <span>offline policy booking</span> for customer convenience.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Efficient management of <span> in-progress leads </span> and
          <span> upcoming renewals.</span>
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Accurate <span>tracking of commission </span> for better financial
          insights.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Clear <span>visibility of leads </span> in progress.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          <span>Timely Policy Issuance </span> without any hassle.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Enhanced efficiency for <span>reduction in redundant data </span>{" "}
          entry tasks.
        </>
      ),
    },
  ];

  const modulelist = [
    {
      image: "./images/crmmodule_image1.png",
      heading: "Overview",
      innerlist: [
        {
          paraone:
            " Sales dashboard provides a comprehensive view of the generated business",
        },
      ],
    },
    {
      image: "./images/crmmodule_image2.png",
      heading: "Upcoming Renewals",
      innerlist: [
        {
          paraone: "Stay informed about upcoming policy renewals.",
        },
        {
          paraone:
            "Plan and prioritize renewal activities to ensure customer satisfaction.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image3.png",
      heading: "Offline Policy Booking",
      innerlist: [
        {
          paraone: "Ability to book policies offline for customer convenience.",
        },
        {
          paraone:
            "Maintain a record of policies booked through offline channels.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image4.png",
      heading: "Business Analytics",
      innerlist: [
        {
          paraone: "View overall business generated by insurance companies.",
        },
        {
          paraone:
            "Analyze business performance based on insurance product categories.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image5.png",
      heading: "In-Progress Leads",
      innerlist: [
        {
          paraone:
            "Monitor leads that are currently being processed or worked on.",
        },
        {
          paraone: "Track the progress of leads through various stages.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image1.png",
      heading: "Lead Management",
      innerlist: [
        {
          paraone:
            "Efficient lead management system helps address the challenge of managing & effectively engaging prospective buyers",
        },
      ],
    },
    {
      image: "./images/crmmodule_image2.png",
      heading: "Streamlined Policy Issuance",
      innerlist: [
        {
          paraone:
            "Policies are issued without requiring client details to be re-entered, their status & dispositions are seamlessly managed",
        },
        {
          paraone: "Reduces duplication of efforts and saves time.",
        },
      ],
    },
  ];

  return (
    <>
      <Navbar
        style={navbarStyle}
        image={image}
        class1="whiteMenu"
        border="border"
      />

      <Box className="productPage_wrapper">
        <Box className="productHeader_section" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={6} lg={6} alignSelf={"center"}>
              <h1>
                Unleash the Power of Digital Insurance Empower Intermediaries to
                <span> Deliver Seamless B2C Insurance Solutions.</span>
              </h1>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              textAlign={"end"}
              className="text-md-center"
            >
              <img src="./images/crm_header_img.svg" alt="crm" />
            </Grid>
          </Grid>
        </Box>

        {/* product info section for CRM*/}
        <Box className="what_crm" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={4} lg={4} style={{ alignSelf: "center" }}>
              <h6>
                What is <span>CRM?</span>
              </h6>
            </Grid>
            <Grid sm={12} md={4} lg={4}>
              <p>
                Our CRM solution is a game-changer for intermediaries,
                revolutionizing their sales and productivity. By centralizing
                lead management and offering comprehensive customer relationship
                management, intermediaries can effectively convert leads and
                deliver personalized services, fostering higher satisfaction and
                loyalty. Streamlined policy management and automated workflows
                reduce manual effort and enhance accuracy.
              </p>
            </Grid>
            <Grid sm={12} md={4} lg={4}>
              <p>
                Robust analytics provide real-time insights for data-driven
                decision-making, optimizing sales strategies and driving revenue
                growth. With seamless collaboration and mobile accessibility,
                brokers can work efficiently and respond promptly to client
                needs. Additionally, our CRM enables easy access to insurance
                quotes, comparison, and payment link sharing from a single
                dashboard, simplifying the sales process and enhancing customer
                experience.
              </p>
            </Grid>
          </Grid>
        </Box>

        <Box marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="crm_modules_description">
                <Grid container columnSpacing={3} className="row">
                  <Grid sm={12} md={12} lg={6} className="text-md-center">
                    <h5 className="heading mb-1">Modules & Description</h5>
                    <p>
                      Unlock the Full Potential of Our Feature-Packed Solutions!
                    </p>
                    <img src="./images/crm_module_img.svg" alt="" />
                  </Grid>
                  <Grid sm={12} md={12} lg={6}>
                    <div className="crm_module_description_list">
                      <ul>
                        {modulelist.map((data, index) => (
                          <li className="module_innner_list">
                            <div className="module_subinnner_list">
                              <div className="description_img">
                                <img src={data.image} />
                              </div>
                              <div className="description_content">
                                <h6>{data.heading}</h6>
                                <ul>
                                  {data &&
                                    data?.innerlist?.map(
                                      (item: any, indexx) => (
                                        <li>{item.paraone}</li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="usp_section uspCrm" marginBottom={"80px"}>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={6} textAlign={"center"} marginBottom={"28px"}>
              <img src="./images/crm_benefits_img2.svg" alt="" style= {{width:'100%' }} />
            </Grid>
            <Grid xs={6}>
              <h6 className="heading mb-10">
                Our <span>Unique Selling Point!</span>
              </h6>
              <ul className="uspCrmList">
                {uspcrmlistArray.map((data, index) => (
                  <li>
                    <img src="./images/yellow_tick_icon.svg" alt="" />
                    <p className="mt-2">{data.point_dsec}</p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <RequestDemo
          formHeading={
            <>
              Request a<span> Demo</span>
            </>
          }
          formDesc={
            "Experience the Future: Request a Demo and Witness the Power of Our Solutions in Action!"
          }
          formImg_url={"./images/request_demo_img.svg"}
        />
      </Box>
      <Footer />
    </>
  );
}

export default Crm;
