import { Box, Link } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./Footer.scss";
import { ALL_ROUTES } from "../../../Routes/AllRoutes";

function Footer() {
  return (
    <Box className="footer">
      <div className="row">
        <Grid container spacing={3}>
          <Grid sm={6} md={3} lg={3}>
            <div>
              <a href="">
                <img
                  src="./images/footer_insurevent_logo.svg"
                  alt=""
                  height="64"
                  className="mb-10"
                />
              </a>
              <img
                src="./images/footer_boy.svg"
                alt=""
                height="207px"
                className="footer_boy"
              />
            </div>
          </Grid>
          <Grid sm={6} md={3} lg={3}>
            <h6>Products</h6>
            <ul className="product_list">
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.ERP;
                  }}
                >
                  ERP
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.POSP;
                  }}
                >
                  POSP
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.CRM;
                  }}
                >
                  CRM
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.INSURANCE_AGGREGATION;
                  }}
                >
                  IAP & Application
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.SELF_INSPECTION;
                  }}
                >
                  SIA
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.EB;
                  }}
                >
                  Employee Benefit
                </Link>
              </li>
              <li>
                <Link>Embedded Insurance</Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.INTEGRATION_SERVICES;
                  }}
                >
                  Integration Kits & SDK'S
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid sm={6} md={3} lg={3}>
            <h6>Services</h6>
            <ul className="product_list">
              <li>
                <Link>Custom Product Development</Link>
              </li>
              <li>
                <Link>Lead Generation & Digital Marketing</Link>
              </li>
              <li>
                <Link>DIB</Link>
              </li>
              <li>
                <Link>URL Shortening</Link>
              </li>
              <li>
                <Link>SMS & Email Services</Link>
              </li>
            </ul>
          </Grid>
          <Grid sm={6} md={3} lg={3}>
            <h6>Company</h6>
            <ul className="product_list">
              <li>
                <Link>Privacy Policy</Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = ALL_ROUTES.ABOUT;
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link>Terms of Use</Link>
              </li>
            </ul>
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
          <Grid sm={12} md={6} lg={6}>
            <div className="address_detail">
              <a href="mailto:someone@example.com">hello@evervent.in |</a>
              <span> +91 96250 70315</span>
              <p>
                4th Floor, plot no. 341, Industrial Area, Phase 9, Mohali,
                Punjab, India - 160062
              </p>
            </div>
          </Grid>
          <Grid sm={12} md={6} lg={6}>
            <div className="social_sites">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/everventindia"
                    target="_blank"
                  >
                    <img src="./images/fb-icon.png" alt="fb-icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://in.linkedin.com/company/evervent1"
                    target="_blank"
                  >
                    <img src="./images/linkedin-icon.png" alt="linkedin-icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/accounts/login/?next=/evervent_/"
                    target="_blank"
                  >
                    <img src="./images/ig-icon.png" alt="insta-icon" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/" target="_blank">
                    <img src="./images/youtube-icon.png" alt="youtube-icon" />
                  </a>
                </li>
              </ul>
              <p>@Copyright 2023 www.insurvent.com All Rights Reserved.</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Footer;
