import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../FieldTypes/RKTextField/RKTextField";
import CustomButton from "../../CustomButton/CustomButton";
import "../../../Common/CommonScss/ModalPopup.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
// import '../../../Pages/Desktop/CMSPages/CMSPages.scss';

const GetInTouchModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const isMobile = useIsMobile();

  const [formFields, setFormFields] = useState<{
    name: { value: string; warning: boolean };
    subject: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    message: { value: string; warning: boolean };
    company: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    subject: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    message: { value: "", warning: false },
    company: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Grid container>
            <Button className="closePopup" onClick={() => setOpen(false)} />
            {/* all okay  */}
            <Grid xs={12} sm={8}>
              <h3 className="heading">
                <span>Contact</span> US
              </h3>
              <p className="para mb-4">
                "Step into the future of policy management - lets make insurance
                exciting together!"
              </p>
              <Box className="form_section">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Name"}
                      value={formFields.name.value}
                      attrName={["name", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.name.warning}
                      error_messg="Please enter name"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Subject"}
                      value={formFields.subject.value}
                      attrName={["subject", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.subject.warning}
                      error_messg="Please enter subject"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Phone Number"}
                      value={formFields.mobile.value}
                      attrName={["mobile", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.mobile.warning}
                      error_messg="Please enter mobile"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Email"}
                      value={formFields.email.value}
                      attrName={["email", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.email.warning}
                      error_messg="Please enter email"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField "
                      title={"Message"}
                      value={formFields.message.value}
                      attrName={["message", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.message.warning}
                      error_messg="Please enter message"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField "
                      title={"Company"}
                      value={formFields.company.value}
                      attrName={["company", "value", formFields, setFormFields]}
                      value_update={fieldsUpdateState}
                      warn_status={formFields.company.warning}
                      error_messg="Please enter company"
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton
                      class_name="blueXlBtn"
                      text_name="Submit Details"
                      //   onClickFunction={validateForm}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid xs={12} sm={4} display={"flex"} alignItems={"end"} className="img-section">
              <img src="./images/contactus_img.svg" alt="contact" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default GetInTouchModal;
