import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MEmployeeBenefit from "../../Pages/Mobile/MProductPages/MEmployeeBenefit/MEmployeeBenefit";
import EmployeeBenefit from "../../Pages/Desktop/ProductPages/EmployeeBenefit/EmployeeBenefit";

const EBContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MEmployeeBenefit /> : <EmployeeBenefit />;
};

export default EBContainer;
