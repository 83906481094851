import LoopIcon from "@mui/icons-material/Loop";
import { Button } from "@mui/material";
import * as React from "react";
import "./CustomButton.scss";

interface Props {
  class_name?: string;
  text_name: string | any;
  loading?: boolean;
  onClickFunction?: Function;
  disabled?: boolean
}

const CustomButton: React.FC<Props> = ({
  class_name = "greenXlBtn",
  text_name,
  loading,
  onClickFunction,
  disabled,
}) => {
  return (
    <Button
      variant="contained"
      className={class_name}
      onClick={() =>
        onClickFunction === undefined ? () => {} : onClickFunction()
      }
      disabled={disabled}
    >
      {loading === true ? <LoopIcon /> : text_name}
    </Button>
  );
};

export default CustomButton;
