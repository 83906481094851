import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MAboutus from "../../Pages/Mobile/MCMSPages/MAboutus/MAboutus";
import AboutUs from "../../Pages/Desktop/CMSPages/AboutUs/AboutUs";
import MContactus from "../../Pages/Mobile/MCMSPages/MContactus/MContactus";
import Contact from "../../Pages/Desktop/CMSPages/Contactus/Contactus";

const ContactContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MContactus /> : <Contact />;
};

export default ContactContainer;
