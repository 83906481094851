import React from "react";
import "./MFooter.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import { ALL_ROUTES } from "../../../Routes/AllRoutes";
import { useNavigate } from "react-router-dom";

const MFooter = () => {
  const navigate = useNavigate();

  return (
    <Box className="footer_m">
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid xs={6} marginBottom={"24px"}>
          <Link marginBottom={"60px"} display={"inline-block"}>
            <img
              src="./images/footer_insurevent_logo.svg"
              alt="insurvent"
              className="logo"
            />
          </Link>
          <img
            src="./images/footer_boy.svg"
            alt="insurvent"
            className="footer_boy"
          />
        </Grid>
        <Grid xs={6} marginBottom={"24px"}>
          <h4>Products</h4>
          <ul>
            <li>
              <Link
                onClick={() => {
                  navigate(ALL_ROUTES.ERP);
                }}
              >
                ERP
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ALL_ROUTES.POSP);
                }}
              >
                POSP
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ALL_ROUTES.CRM);
                }}
              >
                CRM
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ALL_ROUTES.INSURANCE_AGGREGATION);
                }}
              >
                IAP & Application
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ALL_ROUTES.SELF_INSPECTION);
                }}
              >
                SIA
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ALL_ROUTES.EB);
                }}
              >
                Employee Benefit
              </Link>
            </li>
            <li>
              <Link>Embedded Insurance</Link>
            </li>
            <li>
              <Link>Integration Kits & SDK’S</Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6} marginBottom={"24px"}>
          <h4>Services</h4>
          <ul>
            <li>
              <Link>Custom Product Development</Link>
            </li>
            <li>
              <Link>Lead Generation & Digital Marketing</Link>
            </li>
            <li>
              <Link>DIB</Link>
            </li>
            <li>
              <Link>URL Shortening</Link>
            </li>
            <li>
              <Link>SMS & Email Services</Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6} marginBottom={"24px"}>
          <h4>Company</h4>
          <ul>
            <li>
              <Link>Privacy Policy</Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ALL_ROUTES.ABOUT);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link>Terms of Use</Link>
            </li>
          </ul>

          <ul className="social-icons">
            <li>
              <a href="https://www.facebook.com/everventindia" target="_blank">
                <img src="./images/fb-icon.png" alt="fb-icon" />
              </a>
            </li>
            <li>
              <a
                href="https://in.linkedin.com/company/evervent1"
                target="_blank"
              >
                <img src="./images/linkedin-icon.png" alt="linkedin-icon" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/accounts/login/?next=/evervent_/"
                target="_blank"
              >
                <img src="./images/ig-icon.png" alt="insta-icon" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/" target="_blank">
                <img src="./images/youtube-icon.png" alt="youtube-icon" />
              </a>
            </li>
          </ul>
        </Grid>
        <Grid xs={12}>
          <Box className="footer_db">
            <Grid
              container
              rowSpacing={0}
              columnSpacing={3}
              marginBottom={"10px"}
            >
              <Grid xs={6} textAlign={"right"} borderRight={"1px solid white"}>
                <a
                  href="mailto:hello@evervent.in"
                  style={{ fontSize: "14px", fontWeight: 500, color: "white" }}
                >
                  hello@evervent.in
                </a>
              </Grid>
              <Grid xs={6}>
                <p className="mb_num">+91 96250 70315</p>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={3}
              marginBottom={"10px"}
            >
              <Grid xs={12} textAlign={"center"}>
                <p>
                  4th Floor, plot no. 341, Industrial Area, Phase 9, Mohali,
                  Punjab, India - 160062
                </p>
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={3}>
              <Grid xs={12} textAlign={"center"}>
                <p>@Copyright 2023 www.insurvent.in All Rights Reserved.</p>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MFooter;
