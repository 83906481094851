import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MProductPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MRequestDemo from "../../../../Components/Mobile/MRequestDemo/MRequestDemo";

const MErp = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const erpModuleListArray = [
    {
      image: "./images/erp_module_img5.svg",
      heading: "Analytics and Reporting",
      description:
        "InsurVent ERP solution provides real-time analytics and reporting, giving you insight into key metrics such as policy sales, retention rates, and claims processing times, helping you make data-driven business decisions.",
    },
    {
      image: "./images/erp_module_img2.svg",
      heading: "Claims Processing",
      description:
        "ERP software features claims processing tools that allow you to manage and track their status online, making the process faster and more convenient for both you and yInsurVent customers.",
    },
    {
      image: "./images/erp_module_img3.svg",
      heading: "Real-Time Analytics",
      description:
        "ERP software provides real-time analytics, providing insight into key metrics such as policy sales, retention rates, and claims processing times, helping you make data-driven business decisions.",
    },
    {
      image: "./images/erp_module_img4.svg",
      heading: "Customizable Workflow",
      description:
        "ERP software allows you to customize workflows, creating a more efficient and streamlined business process that meets the specific needs of your digital insurance business.",
    },
    {
      image: "./images/erp_module_img1.svg",
      heading: "Digital Policy Management System",
      description:
        "ERP solution offers policy management tools that allow you to manage policies, including renewals and endorsements, more efficiently.",
    },
    {
      image: "./images/erp_module_img6.svg",
      heading: "Customer Engagement",
      description:
        "ERP software features tools for customer engagement, including digital communication channels and self-service options, helping you provide your customers with a more personalized and innovative insurance buying experience.",
    },
  ];

  const uspErpArray = [
    {
      image: "./images/usp_erp_icon1.svg",
      heading:
        "Seamless integration of ERP and Broker Management System enhances data synchronization and eliminates data silos.",
    },
    {
      image: "./images/usp_erp_icon2.svg",
      heading:
        "Real-time access to critical information for informed decision-making.",
    },
    {
      image: "./images/usp_erp_icon3.svg",
      heading:
        "Advanced reporting and analytics capabilities for actionable insights.",
    },
    {
      image: "./images/usp_erp_icon4.svg",
      heading:
        "Customizable solution to adapt to the unique needs and processes of insurance intermediaries.",
    },
    {
      image: "./images/usp_erp_icon5.svg",
      heading:
        "Improved collaboration and communication among team members and stakeholders.",
    },
    {
      image: "./images/usp_erp_icon6.svg",
      heading:
        "Scalable solution to accommodate business growth and expansion.",
    },
    {
      image: "./images/usp_erp_icon7.svg",
      heading: "Simplified compliance management and regulatory adherence. ",
    },
    {
      image: "./images/usp_erp_icon8.svg",
      heading:
        "Enhanced customer experience through efficient policy management and claims processing.",
    },
    {
      image: "./images/usp_erp_icon9.svg",
      heading: "Reduced operational costs and increased productivity.",
    },
    {
      image: "./images/usp_erp_icon10.svg",
      heading:
        "Dedicated support and training to ensure successful implementation and adoption.",
    },
    {
      image: "./images/usp_erp_icon11.svg",
      heading:
        "Stay ahead of industry trends with regular updates and feature enhancements.",
    },
    {
      image: "./images/usp_erp_icon12.svg",
      heading:
        "Industry expertise and best practices built into the solution to drive success.",
    },
    {
      image: "./images/usp_erp_icon13.svg",
      heading:
        "Gain a competitive advantage by leveraging cutting-edge technology for insurance intermediaries.",
    },
  ];

  const [showUspPoints, setShowUspPoints] = useState(false);
  const defaultItemshow = 4;

  return (
    <>
      <MNavbar />

      <Box className="productpage-wrapper">
        <Box
          className="prouct-header"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            paddingTop={"40px"}
            paddingBottom={"16px"}
          >
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h2>
                Empowering intermediaries with comprehensive
                <span>Insurance Management System </span>for Brokers.
              </h2>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="product_header_img">
                <img src="./images/erp_header_icon.svg" alt="about" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="product_info_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"12px"}>
              <Box className="inner_box">
                <img src="./images/erp_sec_icon.svg" alt="" />
                <h3 className="mb-2 mt-3">
                  What is <span>ERP?</span>
                </h3>
                <p>
                  Experience seamless insurance brokerage operations with our
                  powerful InsurTech solution. By integrating ERP and a Broker
                  Management System, we provide brokers with a comprehensive
                  platform to manage customer relationships, policies, claims,
                  and finances effortlessly. Our solution streamlines workflows,
                  reduces manual tasks, ensures accurate data, and boosts
                  overall efficiency. Bid farewell to fragmented systems and
                  embrace our integrated InsurTech solution for a streamlined
                  and successful brokerage experience.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="module_desc_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3>
                Module & its <span>Description</span>
              </h3>
            </Grid>
            <Grid xs={12} padding={0}>
              <Box>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  autoPlaySpeed={3000}
                  arrows={false}
                  customTransition={"transform 800ms ease-in-out"}
                >
                  {erpModuleListArray.map((data) => (
                    <Box className="erp_module_box">
                      <Box className="icon_box">
                        <img src={data.image} alt="" />
                      </Box>

                      <h5>{data.heading}</h5>
                      <p>{data.description}</p>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="usp_section"
          padding={"20px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h3>
                Our <span>Unique Selling Point!</span>
              </h3>
            </Grid>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <img
                src="./images/usp_erp.svg"
                alt=""
                style={{ margin: "auto" }}
              />
            </Grid>
            <Grid xs={12}>
              <ul className="uspErpList">
                {(showUspPoints === true
                  ? uspErpArray
                  : uspErpArray.slice(0, defaultItemshow)
                ).map((data, index) => (
                  <li>
                    <Box className="iconBox">
                      <img src={data.image} alt="" />
                    </Box>
                    <p>{data.heading}</p>
                  </li>
                ))}
                {uspErpArray.length > defaultItemshow && (
                  <Link
                    color={"#6458D8"}
                    onClick={() => setShowUspPoints(!showUspPoints)}
                  >
                    {showUspPoints === true ? "Read Less" : "Read More"}
                  </Link>
                )}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <MRequestDemo />
      </Box>

      <MFooter />
    </>
  );
};

export default MErp;
