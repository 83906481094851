import React, { useState } from "react";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../ProductPages/ProductPages.scss";
import RequestDemo from "../../../../Components/Desktop/RequestDemo/RequestDemo";
import Footer from "../../../../Components/Desktop/Footer/Footer";

function IntegrationServices() {
  const navbarStyle = { backgroundColor: "#6458D8", color: "#ffffff" };
  const image = "./images/insurevent_logo_white.svg";

  const integrationFeatureArray = [
    {
      image: "./images/integration_expertise_icon.png",
      heading: "Integration Expertise",
      desc: "Insurvent takes pride in being an integration expert. Our team excels at seamlessly connecting with diverse API frameworks provided by insurers and insurance companies, ensuring a harmonious flow of information.",
    },
    {
      image: "./images/agile_integration_icon.png",
      heading: "Agile Integration",
      desc: "We understand that the insurance landscape is diverse, with different companies using various systems. Insurvent's agile integration approach allows us to adapt to these differences, providing a cohesive experience for both insurers and customers.",
    },
    {
      image: "./images/cq_lifecycle_icon.png",
      heading: "Complete Quotation Lifecycle",
      desc: "From accessing and interpreting quotation data to presenting it in a user-friendly interface, Insurvent handles the complete quotation lifecycle with finesse. Our goal is to make the process smooth and efficient.",
    },
    {
      image: "./images/pm_excellence_icon.png",
      heading: "Proposal Management Excellence",
      desc: "Insurvent ensures that proposal submissions are handled effortlessly. Our integration services facilitate quick and accurate proposal management, enhancing the overall efficiency of your operations.",
    },
    {
      image: "./images/pd_mastery_icon.png",
      heading: "Policy Document Mastery",
      desc: "Efficient policy document management is key to a successful insurance operation. Insurvent's services ensure that policy documents are well-managed, easily accessible, and in compliance with industry standards.",
    },
    {
      image: "./images/is_integration_icon.png",
      heading: "Intelligent Schedulers Integration",
      desc: " Enhance your scheduling capabilities with Insurvent. Our integration services seamlessly connect with intelligent schedulers, ensuring that appointments, deadlines, and milestones are managed efficiently.",
    },
  ];

  const insurventAdvantageArray = [
    {
      image: "./images/insurvent_advantage_icon1.png",
      heading: "Focus on Your Business",
      desc: "Let Insurvent take care of the complexities of API integrations. With our services, you can focus on your core business activities, confident that your digital transformation journey is in capable hands.",
    },
    {
      image: "./images/insurvent_advantage_icon2.png",
      heading: "Tailored Solutions",
      desc: " Our team works closely with insurers to understand their unique requirements. We tailor our integration services to fit your specific needs, ensuring a customized solution that aligns perfectly with your operations.",
    },
    {
      image: "./images/insurvent_advantage_icon3.png",
      heading: "Scalability and Adaptability",
      desc: "Insurvent's integration services are designed to grow with you. Whether you're expanding your services or adopting new technologies, our solutions are scalable and adaptable to your changing business landscape.",
    },
  ];

  return (
    <>
      <Navbar
        style={navbarStyle}
        image={image}
        class1="whiteMenu"
        border="border"
      />

      <Box className="productPage_wrapper">
        <Box className="productHeader_section" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={6} lg={6} alignSelf={"center"}>
              <h1>
                Integration Kits & SDKs- Redefining Insurer
                <span>Connections, Communications & Collaborations.</span>
              </h1>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              textAlign={"end"}
              className="text-md-center"
            >
              <img
                src="./images/integration_services_header_img.svg"
                alt="erp"
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="integration_services_features" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h6 className="heading mb-10">
                What Sets
                <span> Insurvent's Integration </span>&
                <span> SDK Services </span> Apart?
              </h6>
            </Grid>
          </Grid>
          <Grid container columnSpacing={4} rowSpacing={6} className="row">
            {integrationFeatureArray.map((data, index) => (
              <Grid sm={12} md={6} lg={4}>
                <Box className="card">
                  <Box className="card-inner">
                    <Box className="card-front">
                      <h5>{data.heading}</h5>
                      <img src={data.image} alt="" />
                    </Box>
                    <Box className="card-back">
                      <h5>{data.heading}</h5>
                      <p>{data.desc}</p>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box className="insurvent_advantage" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h6 className="heading mb-10">
                The Insurvent
                <span> Advantage </span>
              </h6>
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} rowSpacing={0} className="row">
            <Grid sm={12}>
              <Box className="advantage_box_wrapper">
                {insurventAdvantageArray.map((data,index) => (
                  <Box className="advantage_box">
                    <img src={data.image} alt="" />
                    <Box className="content-box">
                      <h5>{data.heading}</h5>
                      <p>
                        {data.desc}
                      </p>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box
                sx={{
                  background: "#F3F2FC",
                  borderRadius: "12px",
                  padding: "24px",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  Ready to elevate your insurer services through seamless API
                  integration? Partner with Insurvent for a connected,
                  efficient, and digitally transformed insurance buying
                  experience. Embrace simplicity, enhance collaboration, and
                  drive success with Insurvent Insurer Services!
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <RequestDemo
          formHeading={
            <>
              Request a<span> Demo</span>
            </>
          }
          formDesc={
            "Experience the Future: Request a Demo and Witness the Power of Our Solutions in Action!"
          }
          formImg_url={"./images/request_demo_img.svg"}
        />
      </Box>
      <Footer />
    </>
  );
}

export default IntegrationServices;
