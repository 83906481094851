import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MCMSPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import RKTextField from "../../../../Components/FieldTypes/RKTextField/RKTextField";

const MContactus = () => {
  const [quoteFields, setQuoteFields] = useState<{
    name: { value: string; warning: boolean };
    subject: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    message: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    subject: { value: "", warning: false },
    email: { value: "", warning: false },
    mobile: { value: "", warning: false },
    message: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  return (
    <>
      <MNavbar />

      <Box className="cmspage-wrapper">
        <Box
          className="top-header"
          padding={"0px 12px"}
          marginTop={"40px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"60px"}>
              <h3 className="mb-2">
                Contact <span> Us</span>
              </h3>
              <p>
                "Step into the future of policy management - lets make insurance
                exciting together!"
              </p>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="contact_sec">
                <img src="./images/contact_page_img.svg" alt="" />
                <Grid container rowSpacing={0} columnSpacing={3}>
                  <Grid xs={12} className="mb-6">
                    <RKTextField
                      class_name="inputField"
                      title={"Name*"}
                      value={quoteFields.name.value}
                      attrName={["name", "value", quoteFields, setQuoteFields]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.name.warning}
                      error_messg="Please enter name"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <RKTextField
                      class_name="inputField"
                      title={"Subject*"}
                      value={quoteFields.subject.value}
                      attrName={[
                        "subject",
                        "value",
                        quoteFields,
                        setQuoteFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.subject.warning}
                      error_messg="Please enter subject"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <RKTextField
                      class_name="inputField"
                      title={"Phone Number*"}
                      value={quoteFields.mobile.value}
                      attrName={[
                        "mobile",
                        "value",
                        quoteFields,
                        setQuoteFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.mobile.warning}
                      error_messg="Please enter mobile number"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <RKTextField
                      class_name="inputField"
                      title={"Email Id*"}
                      value={quoteFields.email.value}
                      attrName={["email", "value", quoteFields, setQuoteFields]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.email.warning}
                      error_messg="Please enter email"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <RKTextField
                      class_name="inputField"
                      title={"Message*"}
                      value={quoteFields.message.value}
                      attrName={[
                        "message",
                        "value",
                        quoteFields,
                        setQuoteFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.message.warning}
                      error_messg="Please enter message"
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton text_name={"Submit"} class_name="blueLgBtn" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="top-header"
          padding={"0px 12px"}
          marginTop={"40px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"36px"}>
              <h3 className="mb-2">Get In touch</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </Grid>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <Box className="contact_details_sec">
                <ul>
                  <li>
                    <img src="./images/address_icon.png" alt="address" />
                    <p className="mt-4">
                      #341, 4th Floor, Phase-9, <br /> Mohali, Punjab, 160062
                    </p>
                  </li>
                  <li>
                    <img src="./images/call_icon.png" alt="call" />
                    <p className="mt-4">
                      +01267777654434 <br /> 091834465555
                    </p>
                  </li>
                  <li>
                    <img src="./images/social_icon.png" alt="social" />
                    <a
                      className="mt-4"
                      href="https://in.linkedin.com/company/evervent1"
                      target="_blank"
                    >
                      www.linkdin.com
                    </a>
                    <br />
                    <a
                      href="https://www.instagram.com/accounts/login/?next=/evervent_/"
                      target="_blank"
                    >
                      www.instagram.com
                    </a>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="company_location_mark">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2005.3936444841415!2d76.74291228094363!3d30.673114197726708!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fedbfa5e9dbcf%3A0x5d06f611fa13fbb3!2sEvervent!5e0!3m2!1sen!2sin!4v1653026267690!5m2!1sen!2sin"
                  width="100%"
                  height="165"
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{ borderRadius: "10px", border: 0 }}
                ></iframe>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <MFooter />
    </>
  );
};

export default MContactus;
