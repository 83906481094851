import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MAboutus from "../../Pages/Mobile/MCMSPages/MAboutus/MAboutus";
import AboutUs from "../../Pages/Desktop/CMSPages/AboutUs/AboutUs";

const AboutContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MAboutus /> : <AboutUs />;
};

export default AboutContainer;
