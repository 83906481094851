import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link, Button, Collapse } from "@mui/material";
import SlidingPanel from "react-sliding-side-panel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../Routes/AllRoutes";

interface Props {
  open: boolean;
  setOpen: any;
}

const MenuPopup = ({ open, setOpen }: Props) => {
  const navigate = useNavigate();
  const [mainMenuActive, setMainMenuActive] = useState("");
  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<
    | null
    | "intermediaries"
    | "insurers"
    | "banks"
    | "nbfc"
    | "oem"
    | "corporates"
    | "misp"
  >(null);
  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open}
        size={100}
        panelClassName="slidinPanel"
        backdropClicked={() => setOpen(false)}
      >
        <>
          <Box className="navbar_m">
            <Grid
              container
              rowSpacing={0}
              columnSpacing={0}
              alignItems={"center"}
            >
              <Grid xs={7} className="logo-section">
                <Link>
                  <img
                    src="./images/insurevent_logo.svg"
                    alt="insurvent"
                    className="logo"
                  />
                </Link>
              </Grid>
              <Grid xs={5} alignSelf={"center"} textAlign={"center"}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setOpen(false);
                  }}
                ></Link>
              </Grid>
            </Grid>
          </Box>
          <Box className="menupopup">
            <Grid container spacing={3} alignItems="center">
              <Grid xs={12}>
                <ul className="main_menu">
                  <li>
                    <Link
                      className={mainMenuActive === "products" ? "active" : ""}
                      onClick={() => {
                        setMainMenuActive("products");
                        setOpenProductMenu(!openProductMenu);
                      }}
                    >
                      <p>Products</p>
                      <ArrowDropDownIcon />
                    </Link>
                    <Collapse in={openProductMenu} timeout="auto" unmountOnExit>
                      <ul className="inner_menu">
                        <li>
                          <Link
                            onClick={() => {
                              setOpenSubMenu((prev) =>
                                prev === "intermediaries"
                                  ? null
                                  : "intermediaries"
                              );
                            }}
                          >
                            <Box className="inner_menu_box">
                              <img
                                src="./images/best_in_market_icon3.svg"
                                alt=""
                              />
                              <p>Intermediaries</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "intermediaries"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.ERP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Enterprise Resource Planning /BMS</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.POSP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon2.svg"
                                      alt=""
                                    />
                                    <p>Point of Sales Person</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.CRM)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon3.svg"
                                      alt=""
                                    />
                                    <p>Customer Relationship Manager</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() =>
                                    navigate(ALL_ROUTES.INSURANCE_AGGREGATION)
                                  }
                                >
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon4.svg"
                                      alt=""
                                    />
                                    <p>
                                      Insurance Aggregation Platform &
                                      Application
                                    </p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() =>
                                    navigate(ALL_ROUTES.SELF_INSPECTION)
                                  }
                                >
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon5.svg"
                                      alt=""
                                    />
                                    <p>Self Inspection Application</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.EB)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon6.svg"
                                      alt=""
                                    />
                                    <p>Employee Benefit Platform</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              setOpenSubMenu((prev) =>
                                prev === "insurers" ? null : "insurers"
                              );
                            }}
                          >
                            <Box className="inner_menu_box">
                              <img src="./images/navbar_insurer.svg" alt="" />
                              <p>Insurers</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "insurers"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Integration Kits & SDK's</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon2.svg"
                                      alt=""
                                    />
                                    <p>Online Transaction Journey</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon4.svg"
                                      alt=""
                                    />
                                    <p>
                                      Integration Support For Sales Partners
                                    </p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                            </ul>
                          </Collapse>
                        </li>
                        {/* <li>
                          <Link
                            onClick={() => {
                              setOpenSubMenu((prev) =>
                                prev === "generalProducts"
                                  ? null
                                  : "generalProducts"
                              );
                            }}
                          >
                            <Box className="inner_menu_box">
                              <img
                                src="./images/navbar_general_products.svg"
                                alt=""
                              />
                              <p>General Products</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "generalProducts"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Self Inspection Application</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon2.svg"
                                      alt=""
                                    />
                                    <p>Enterprise Resource Planning /BMS</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon3.svg"
                                      alt=""
                                    />
                                    <p>Employee Benefit Platform</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon4.svg"
                                      alt=""
                                    />
                                    <p>Point of Sales Person</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon5.svg"
                                      alt=""
                                    />
                                    <p>Customer Relationship Manager</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon6.svg"
                                      alt=""
                                    />
                                    <p>
                                      Insurance Aggregation Platform &
                                      Application
                                    </p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li> */}
                        <li>
                          <Link
                            onClick={() => {
                              setOpenSubMenu((prev) =>
                                prev === "banks" ? null : "banks"
                              );
                            }}
                          >
                            <Box className="inner_menu_box">
                              <img src="./images/navbar_banks.svg" alt="" />
                              <p>Banks</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "banks"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.ERP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Enterprise Resource Planning /BMS</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.CRM)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon3.svg"
                                      alt=""
                                    />
                                    <p>Customer Relationship Manager</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li
                          onClick={() => {
                            setOpenSubMenu((prev) =>
                              prev === "nbfc" ? null : "nbfc"
                            );
                          }}
                        >
                          <Link>
                            <Box className="inner_menu_box">
                              <img src="./images/navbar_nbfc.svg" alt="" />
                              <p>NBFC's</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "nbfc"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.ERP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Enterprise Resource Planning /BMS</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.POSP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon2.svg"
                                      alt=""
                                    />
                                    <p>Point of Sales Person</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                              <li>
                                <Link
                                  onClick={() =>
                                    navigate(ALL_ROUTES.SELF_INSPECTION)
                                  }
                                >
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon5.svg"
                                      alt=""
                                    />
                                    <p>Self Inspection Application</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.EB)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon6.svg"
                                      alt=""
                                    />
                                    <p>Employee Benefit Platform</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              setOpenSubMenu((prev) =>
                                prev === "oem" ? null : "oem"
                              );
                            }}
                          >
                            <Box className="inner_menu_box">
                              <img
                                src="./images/navbar_original_equipment.svg"
                                alt=""
                              />
                              <p>Original Equipment Manufacturers</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "oem"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.ERP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Enterprise Resource Planning /BMS</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.CRM)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon3.svg"
                                      alt=""
                                    />
                                    <p>Customer Relationship Manager</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                            </ul>
                          </Collapse>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              setOpenSubMenu((prev) =>
                                prev === "corporates" ? null : "corporates"
                              );
                            }}
                          >
                            <Box className="inner_menu_box">
                              <img
                                src="./images/navbar_corporates.svg"
                                alt=""
                              />
                              <p>Corporates</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "corporates"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.ERP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Enterprise Resource Planning /BMS</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                              <li>
                                <Link
                                  onClick={() =>
                                    navigate(ALL_ROUTES.INSURANCE_AGGREGATION)
                                  }
                                >
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon4.svg"
                                      alt=""
                                    />
                                    <p>
                                      Insurance Aggregation Platform &
                                      Application
                                    </p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() =>
                                    navigate(ALL_ROUTES.SELF_INSPECTION)
                                  }
                                >
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon5.svg"
                                      alt=""
                                    />
                                    <p>Self Inspection Application</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                            </ul>
                          </Collapse>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              setOpenSubMenu((prev) =>
                                prev === "misp" ? null : "misp"
                              );
                            }}
                          >
                            <Box className="inner_menu_box">
                              <img
                                src="./images/navbar_mispdealer.svg"
                                alt=""
                              />
                              <p>MISP Dealers</p>
                            </Box>
                            <ArrowDropDownIcon />
                          </Link>
                          <Collapse
                            in={openSubMenu === "misp"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ul className="sub_inner_menu">
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.ERP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon1.svg"
                                      alt=""
                                    />
                                    <p>Enterprise Resource Planning /BMS</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.POSP)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon2.svg"
                                      alt=""
                                    />
                                    <p>Point of Sales Person</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              
                              <li>
                                <Link
                                  onClick={() =>
                                    navigate(ALL_ROUTES.SELF_INSPECTION)
                                  }
                                >
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon5.svg"
                                      alt=""
                                    />
                                    <p>Self Inspection Application</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                              <li>
                                <Link onClick={() => navigate(ALL_ROUTES.EB)}>
                                  <Box className="inner_menu_box">
                                    <img
                                      src="./images/best_in_market_icon6.svg"
                                      alt=""
                                    />
                                    <p>Employee Benefit Platform</p>
                                  </Box>
                                  <ArrowDropDownIcon />
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                  <li>
                    <Link
                      className={mainMenuActive === "services" ? "active" : ""}
                      onClick={() => setMainMenuActive("services")}
                    >
                      <p>Services</p>
                      <ArrowDropDownIcon />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={mainMenuActive === "about" ? "active" : ""}
                      onClick={() => {
                        setMainMenuActive("about");
                        navigate(ALL_ROUTES.ABOUT);
                      }}
                    >
                      <p>About</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={mainMenuActive === "contact" ? "active" : ""}
                      onClick={() => {
                        setMainMenuActive("contact");
                        navigate(ALL_ROUTES.CONTACTUS);
                      }}
                    >
                      <p>Contact</p>
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </>
      </SlidingPanel>
    </>
  );
};

export default MenuPopup;
