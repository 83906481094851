import React, { useState } from "react";
import "../GetInTouch/GetInTouch.scss";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../FieldTypes/RKTextField/RKTextField";
import CustomButton from "../../CustomButton/CustomButton";
import { HomepageForm } from "../../../Service/Type/HomepageForm";

function GetInTouch() {
  const [quoteFields, setQuoteFields] = useState<HomepageForm>({
    name: { value: "", warning: false },
    email: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });
  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const validateForm = () => {
    let contactFormFieldData: HomepageForm = quoteFields;
    contactFormFieldData.name.warning =
      quoteFields.name.value.length === 0 ? true : false;
    contactFormFieldData.email.warning =
      quoteFields.email.value.length === 0 ? true : false;
    contactFormFieldData.mobile.warning =
      quoteFields.mobile.value.length === 0 ? true : false;
  };
  return (
    <Box className="getin_touch" marginBottom={"80px"}>
      <Grid container spacing={3} className="row">
        <Grid xs={12} textAlign={"center"}>
          <h5 className="heading mb-2">
            Get In <span>Touch</span>
          </h5>
          <p className="para mb-7">
            "Unleash the thrill of insurance innovation by embracing a world of
            enhanced insurance solutions & personalized support - take the first
            step by getting in touch with our team of experts.
          </p>
        </Grid>
        <Grid
          sm={12}
          md={5}
          lg={6}
          sx={{ alignSelf: "center" }}
          className="text-sm-center"
        >
          <div className="getin_touch_inner">
            <img src="./images/boy_1.svg" alt="" height="401"></img>
          </div>
        </Grid>
        <Grid sm={12} md={7} lg={6}>
          <div className="formSection">
            <Grid container spacing={3}>
              <Grid xs={12} className="mb-4">
                <RKTextField
                  class_name="inputField"
                  title={"Name"}
                  value={quoteFields.name.value}
                  attrName={["name", "value", quoteFields, setQuoteFields]}
                  value_update={fieldsUpdateState}
                  warn_status={quoteFields.name.warning}
                  error_messg="Please enter name"
                />
              </Grid>
              <Grid xs={12} className="mb-4">
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={quoteFields.email.value}
                  attrName={["email", "value", quoteFields, setQuoteFields]}
                  value_update={fieldsUpdateState}
                  warn_status={quoteFields.email.warning}
                  error_messg="Please enter email"
                />
              </Grid>
              <Grid xs={12} className="mb-4">
                <RKTextField
                  class_name="inputField"
                  title={"Phone Number"}
                  value={quoteFields.mobile.value}
                  attrName={["mobile", "value", quoteFields, setQuoteFields]}
                  value_update={fieldsUpdateState}
                  warn_status={quoteFields.mobile.warning}
                  error_messg="Please enter mobile number"
                />
              </Grid>
              <Grid xs={12} textAlign="center" className="ctaBtn">
                <CustomButton
                  class_name="blueXlBtn"
                  text_name="Submit"
                  onClickFunction={validateForm}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GetInTouch;
