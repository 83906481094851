import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MSelfInspection from "../../Pages/Mobile/MProductPages/MSelfInspection/MSelfInspection";
import SelfInspection from "../../Pages/Desktop/ProductPages/SelfInspection/SelfInspection";

const SIContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MSelfInspection /> : <SelfInspection />;
};

export default SIContainer;
