import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MProductPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MRequestDemo from "../../../../Components/Mobile/MRequestDemo/MRequestDemo";

const MInsuranceAggregation = () => {
  const modulelistIaggrArray = [
    {
      image: "./images/aggregation_image_one.png",
      heading: "B2C Website",
      para: "  Design exceptional B2C websites with Intuitive UI/UX for intermediaries, revolutionizing their online presence",
    },
    {
      image: "./images/aggregation_image_three.png",
      heading: "Quote Generation",
      para: "Generates instant quotes based on the client's input, providing real-time pricing estimates for different insurance policies.",
    },
    {
      image: "./images/aggregation_image_six.png",
      heading: "Document Management",
      para: "Stores and manages digital documents related to insurance policies, endorsements, and other relevant files.",
    },
    {
      image: "./images/aggregation_image_seven.png",
      heading: "Payment Processing",
      para: " Integrates secure online payment gateways to enable clients to make premium payments electronically.",
    },
    {
      image: "./images/aggregation_image_nine.png",
      heading: "Communication and Notifications",
      para: "   Enables automated communication and notifications to keep  clients informed about policy updates, renewal reminders, and important announcements.",
    },
    {
      image: "./images/aggregation_image_ten.png",
      heading: "Reporting and Analytics",
      para: " Offers robust reporting and analytics capabilities, providing insights into sales performance, customer trends,  and policy metrics.",
    },
    {
      image: "./images/aggregation_image_eight.png",
      heading: "Customer Portal",
      para: " Provides B2C clients with a personalized portal to access policy details, make changes, and view documents online.",
    },
    {
      image: "./images/aggregation_image_five.png",
      heading: "Policy Issuance",
      para: "  Facilitates the issuance of insurance policies digitally,  eliminating the need for manual paperwork and expediting the process.",
    },
    {
      image: "./images/aggregation_image_four.png",
      heading: "Product Catalog",
      para: " A module that allows brokers to showcase a comprehensive  range of insurance products, including details on coverage, benefits, and pricing.",
    },
    {
      image: "./images/aggregation_image_eleven.png",
      heading: "Integration with Insurance Carriers",
      para: "Integrates with insurance carriers' systems to facilitate seamless data exchange and ensure accurate policy information.",
    },
    {
      image: "./images/aggregation_image_two.png",
      heading: "Online Application",
      para: " Enables B2C clients to complete insurance applications  online, providing a user-friendly interface to input necessary information and select desired coverage options.",
    },
  ];

  const uspIaggregationList = [
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Seamless Digital Experience:</span> Simplify the insurance
          buying process for B2C clients with a frictionless online journey.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image2.png",
      heading: (
        <>
          <span>Comprehensive Product Offering:</span> Access a wide range of
          insurance products to meet diverse client needs.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image3.png",
      heading: (
        <>
          <span>Personalized Recommendations:</span> Utilize advanced algorithms
          for tailored coverage options based on client requirements.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image4.png",
      heading: (
        <>
          <span>Streamlined Application Process:</span> Make applying for
          insurance quick and easy, boosting conversions.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image5.png",
      heading: (
        <>
          <span>Real-time Quoting:</span> Provide instant, accurate pricing
          information for faster decision-making.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image6.png",
      heading: (
        <>
          <span>Secure Online Transactions:</span> Prioritize secure payment
          processing for safe financial transactions.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Automated Policy Issuance:</span> Eliminate manual paperwork
          with efficient policy delivery.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Enhanced Customer Service:</span> Deliver exceptional support,
          communication, and responsiveness.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Data-driven Insights:</span> Utilize valuable analytics for
          informed decision-making and optimization.
        </>
      ),
    },
    {
      image: "./images/aggregationselling_image1.png",
      heading: (
        <>
          <span>Scalability and Flexibility:</span> Adapt to evolving needs,
          ensuring long-term competitiveness.
        </>
      ),
    },
  ];

  const [showUspPoints, setShowUspPoints] = useState(false);
  const defaultItemshow = 4;

  return (
    <>
      <MNavbar />

      <Box className="productpage-wrapper">
        <Box
          className="prouct-header"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            paddingTop={"40px"}
            paddingBottom={"16px"}
          >
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h2>
                Unleash the Power of Digital Insurance Empower Intermediaries to
                Deliver <span>Seamless B2C Insurance Solutions.</span>
              </h2>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="product_header_img">
                <img src="./images/aggregation_headerimg.svg" alt="about" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="product_info_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"12px"}>
              <Box className="inner_box">
                <img src="./images/aggregation_platform_img.svg" alt="" />
                <h3 className="mb-2 mt-3">
                  What is a <span>Web-Aggregation Platform?</span>
                </h3>
                <p>
                  A digital/online insurance buying platform for intermediaries
                  refers to a technology-driven solution that enables the
                  intermediaries to offer their B2C (business-to-consumer)
                  clients a convenient and streamlined way to purchase insurance
                  online. It provides a digital interface where intermediaries
                  can showcase various insurance products, facilitate online
                  applications, collect customer information, and process policy
                  issuance. This platform leverages digital tools and automation
                  to enhance the insurance buying experience, simplifying the
                  process for clients while empowering intermediaries to
                  efficiently manage and deliver insurance solutions to their
                  B2C customers.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="module_desc_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3>
                Module & its <span>Description</span>
              </h3>
            </Grid>
            <Grid xs={12}>
              <ul className="module_ia_list">
                {modulelistIaggrArray.map((data) => (
                  <li>
                    <img src={data.image} alt="" />
                    <h6 className="mt-3 mb-1">{data.heading}</h6>
                    <p>{data.para}</p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="usp_section"
          padding={"20px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h3>
                Our <span>Unique Selling Point!</span>
              </h3>
            </Grid>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <img
                src="./images/sellingpoint_aggregation.svg"
                alt=""
                style={{ margin: "auto" }}
              />
            </Grid>
            <Grid xs={12}>
              <ul className="uspErpList">
                {(showUspPoints === true
                  ? uspIaggregationList
                  : uspIaggregationList.slice(0, defaultItemshow)
                ).map((data, index) => (
                  <li>
                    <Box className="iconBox2">
                      <img src={data.image} alt="" />
                    </Box>
                    <p className="para">{data.heading}</p>
                  </li>
                ))}
                {uspIaggregationList.length > defaultItemshow && (
                  <Link
                    color={"#6458D8"}
                    onClick={() => setShowUspPoints(!showUspPoints)}
                  >
                    {showUspPoints === true ? "Read Less" : "Read More"}
                  </Link>
                )}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <MRequestDemo />
      </Box>

      <MFooter />
    </>
  );
};

export default MInsuranceAggregation;
