import React from "react";
import "./OurInsurancePartner.scss";
import { Box } from "@mui/material";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import Grid from "@mui/material/Unstable_Grid2";
import GetInTouch from "../../../../Components/Desktop/GetInTouch/GetInTouch";
import CountUp from "react-countup";
import Footer from "../../../../Components/Desktop/Footer/Footer";

function OurInsurancePartner() {
  const image = "./images/insurevent_logo.svg";

  const insurer_partner = [
    {
      img: "./images/insurerlogo/aditya_birla_capital.svg",
    },

    {
      img: "./images/insurerlogo/AEGON-Religare-Life-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Agriculture-Insurance-Co-of-India-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Apollo-Munich-Health-Insurance-Company-Limited.svg",
    },

    {
      img: "./images/insurerlogo/Aviva-Life-Insurance-Company-India-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Bajaj-Allianz-General-Insurance-Co-Ltd.svg",
    },

    {
      img: "./images/insurerlogo/Bharti-AXA-Life-Insurance-Company-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Cholamandalam-MS-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/CignaTTK-Health-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Edelweiss-Tokio-Life-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Future-Generali-India-Life-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Go-Digit-General-Insurance.svg",
    },
    {
      img: "./images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/IDBI-Federal-Life-Insurance-Company-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/IndiaFirst-Life-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/ING-Vysya-Life-Insurance-Company-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/kotak_general_insurance.svg",
    },
    {
      img: "./images/insurerlogo/L_and_T_General_Insurance_Company_Limited.svg",
    },
    {
      img: "./images/insurerlogo/Liberty-Videocon-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Magma-HDI-General-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Max-Bupa-Health-Insurance-Company-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Raheja-QBE.svg",
    },
    {
      img: "./images/insurerlogo/Reliance-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Reliance-Life-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Royal_Sundaram.svg",
    },
    {
      img: "./images/insurerlogo/sbi_general_insurance.svg",
    },
    {
      img: "./images/insurerlogo/sbi-life-insurance.svg",
    },
    {
      img: "./images/insurerlogo/Shriram-General-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Star-Health-and-Allied-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Tata-AIA-Life-Insurance.svg",
    },
    {
      img: "./images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
    },
    {
      img: "./images/insurerlogo/Tata-AIG-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/the_new_india_insurance_co_ltd.svg",
    },
    {
      img: "./images/insurerlogo/The-Oriental-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/United-India-General-Insurance-Co-Ltd.svg",
    },
    {
      img: "./images/insurerlogo/Universal-Sompo-General-Insurance-Co-Ltd.svg",
    },
  ];
  return (
    <>
      <Navbar image={image} />
      <Box className="insurance_partner_wrapper">
        {/* insurer partner */}
        <Box
          className="insurer_partner"
          marginBottom={"80px"}
          marginTop={"24px"}
        >
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"} marginBottom={"60px"}>
              <h2 className="heading mb-3">
                Our Insurance <span>Partners</span>
              </h2>
              <p className="para">
                “Forging unbreakable bonds of trust with insurers, we power a
                future where technology and partnership redefine the insurance
                landscape”
              </p>
            </Grid>
            <Grid xs={12}>
              <ul className="insurer_box">
                {insurer_partner.map((data, index) => (
                  <li>
                    <img src={data.img} />
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>
        {/* counter section  */}

        <Box marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="potential_client">
                <ul>
                  <li>
                    <span>
                      <CountUp end={10} duration={5} />
                    </span>
                    Years of Experience
                  </li>
                  <li>
                    <span>
                      <CountUp end={2500} duration={5} />{" "}
                      <span className="inner_span">+</span>
                    </span>
                    Integrations
                  </li>
                  <li>
                    <span>
                      <CountUp end={25} duration={5} />{" "}
                      <span className="inner_span">+</span>
                    </span>
                    Projects Completed
                  </li>
                  <li>
                    <span>
                      <CountUp end={100} duration={5} />
                      <span className="inner_span">+</span>
                    </span>
                    Complex Customisations
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <GetInTouch />
      </Box>
      <Footer />
    </>
  );
}

export default OurInsurancePartner;
