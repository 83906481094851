import React from "react";
import "../../CMSPages/CMSPages.scss";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../../Components/Desktop/Footer/Footer";

function AboutUs() {
  const image = "./images/insurevent_logo.svg";

  const our_goals = [
    {
      img: "./images/ourgoals_image1.png",
      title: "Our mission",
      desc: "Our mission is to empower insurance world stakeholders with cutting-edge software solutions that drive efficiency and innovation in the industry. We aim to deliver future-proof, user-friendly and customizable software products that strive to streamline operations, improve risk management and enhance overall customer experience",
    },
    {
      img: "./images/ourgoals_image2.png",
      title: "Our vision",
      desc: "Our vision is to be the leading provider of enterprise solutions for the insurtech industry. We aim to empower brokers, insurers and customers alike by developing innovative technology that streamlines the insurance process. Our ultimate goal is to help in navigation of the increasingly complex world of insurance with ease and confidence.",
    },
    {
      img: "./images/ourgoals_image3.png",
      title: "Our Expertise",
      desc: "We Build products and solutions for companies to solve complex business problems. Our talented team of engineers and product experts formulate, design, develop, and market your products. We utilize technology to make the process of buying and managing insurance efficient, smooth and user-friendly.",
    },
  ];

  const founder = [
    {
      img: "./images/sunil_image.svg",
      desc: "“At Evervent, we have been into the insurance distribution business for more than a decade and being an early creators of digital distribution technology, we understand the  nuances of insurtech to the core. We are fortunate to be a part of the digital journeys of our clients, helping them transform their insurance business.”",
      name: "- Sunil Juneja",
    },
    {
      img: "./images/ankit_image.svg",
      desc: "“Acceleration in Digital innovation is transforming the Fintech industry worldwide. We are already witnessing use of A.I and block chain to solve complex problems in Insurance. Disruptive and future ready businesses will be those who have technology at the heart of their company.”",
      name: "- Ankit Sachdeva",
    },
  ];

  const journeySection = [
    {
      year: "2025",
      desc: (
        <>
          Global Leaders <br />
          Insurtech Products
        </>
      ),
      class: "lastoneYear",
    },
    {
      year: "2020",
      desc: (
        <>
          Achieved Premium <br />
          benchmark of USD 1M <br />
          throgh online sales.
        </>
      ),
      class: "lasttwoYear",
    },
    {
      year: "2016",
      desc: (
        <>
          Turned into <br /> Insuretech Provider
        </>
      ),
      class: "lastthreeYear",
    },
    {
      year: "2015",
      desc: (
        <>
          Setup Customer contact <br /> center with Tele-assisted <br /> sales
        </>
      ),
      class: "lastfourYear",
    },
    {
      year: "2013",
      desc: (
        <>
          Started insurance web <br /> aggregation portal <br /> “ Quickbima.com
          “
        </>
      ),
      class: "lastfiveYear",
    },
  ];

  return (
    <>
      <Navbar image={image} />
      <Box className="cms_wrapper">
        <Box marginBottom={"80px"} marginTop={"24px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={5} lg={5} style={{ alignSelf: "center" }}>
              <h5 className="heading mb-4">
                About <span>Us</span>
              </h5>
              <p className="para">
                We at Insurvent, believe in revolutionizing the insurance
                industry through our cutting-edge technology. Our team of
                software engineers, and product experts come together to
                synergize their skills and make the process of purchasing and
                managing insurance policies easy and convenient.
              </p>
            </Grid>
            <Grid sm={12} md={7} lg={7} className="about_img">
              <img src="./images/aboutus_img.png" alt="about" />
            </Grid>
          </Grid>
        </Box>
        {/* our goals section */}

        <Box marginBottom={"80px"}>
          <Grid
            container
            spacing={3}
            className="row"
            display={"flex"}
            justifyContent={"center"}
          >
            <Grid xs={12} textAlign={"center"}>
              <h5 className="heading mb-1">
                Our <span>goals</span>
              </h5>
              <p className="para">
                We believe that everyone deserves access to affordable and
                comprehensive insurance coverage thus we strive to bridge this
                gap between different stakeholders of the insurance world.
              </p>
            </Grid>
            {our_goals.map((data, index) => (
              <Grid sm={6} md={6} lg={4}>
                <Box className="ourgoals_box">
                  <Box className="ouricon_box">
                    <span>
                      <img src={data.img} />
                    </span>
                  </Box>
                  <Box>
                    <h6>{data.title}</h6>
                    <p>{data.desc}</p>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* our founders */}
        <Box marginBottom={"100px"} className="founder_section">
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h5 className="heading mb-1">
                Our <span>Founders</span>
              </h5>
              <p className="para mb-4">
                “To accomplish great things, we must not only dream, but also
                plan, not only believe, but sincerely act."
              </p>
            </Grid>
            {founder.map((data, index) => (
              <Grid sm={12} md={6} lg={6} alignSelf={"center"}>
                <Box className="inner_section">
                  <Box className="inner_content">
                    <img src={data.img} />
                    <p>
                      {data.desc} <span>{data.name}</span>
                    </p>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* our journey section */}

        <Box marginBottom={"80px"} className="journey_section">
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h5 className="heading mb-6">
                Our <span>Journey</span>
              </h5>
              <p className="para">
                “ The Journey of a thousand miles begins with one step - Lao Tzu
              </p>
            </Grid>
            <Grid xs={12}>
              <>
                <Grid container spacing={3} className="row">
                  <Grid xs={12}>
                    {journeySection.map((data, index) => (
                      <span className={`journey_content ${data.class}`}>
                        <h6>{data.year}</h6>
                        <p>{data.desc}</p>
                      </span>
                    ))}
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>

        {/* our team section */}
        <Box className="team_section" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h5 className="heading mb-1">
                Our <span>Team</span>
              </h5>
              <p className="para">
                We believe in the magic of team strength. We are a team of 60+
                employees, mainly software engineers, designers and testing
                professionals & this number is only growing. We provide a
                professional environment, numerous learning opportunities & a
                creative space for its employees & that's why people love
                working here. We have one of the best employee retention rates
                and we take pride in being equal opportunity provider.
              </p>
            </Grid>
            <Grid xs={12} className="team_images">
              <div className="grid grid-cols-4 grid-rows-2 gap-4">
                <div className="grid_box">
                  <img src="./images/team_img1.svg" alt="" />
                </div>
                <div className="col-start-1 row-start-2 grid_box">
                  <img src="./images/team_img2.svg" alt="" />
                </div>
                <div className="row-span-2 col-start-2 row-start-1 grid_box">
                  <img src="./images/team_img3.svg" alt="" />
                </div>
                <div className="col-span-2 col-start-3 row-start-1 grid_box">
                  <img src="./images/team_img4.svg" alt="" />
                </div>
                <div className="col-start-3 row-start-2 grid_box">
                  <img src="./images/team_img5.svg" alt="" />
                </div>
                <div className="col-start-4 row-start-2 grid_box">
                  <img src="./images/team_img6.svg" alt="" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default AboutUs;
