import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MProductPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MRequestDemo from "../../../../Components/Mobile/MRequestDemo/MRequestDemo";

const MSelfInspection = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const mdSelfInspectionArray = [
    {
      image_url: "./images/md_si_icon1.svg",
      heading: " Mutlilingual Support, Data Privacy & Zero Infrastructure Cost",
      para: "SIA is available in all the major Indian languages & follows best in class data security and Privacy polices. Clients bear no cost of IT infrastructure, Maintenance or support, everything is handled by Insuvent.",
    },
    {
      image_url: "./images/md_si_icon2.svg",
      heading: "High Resolution Picture/Video Support & No Gallery Uploads",
      para: "Both Video and Pictures can be used to do the inspection based on the underwriting requirements of the client. Users cannot upload any picture from the gallery . All the video/images are clicked in live mode only in the best quality.",
    },
    {
      image_url: "./images/md_si_icon3.svg",
      heading: "Fraud Prevention & Dynamic Link Expiry",
      para: "SIA has built-in checks to prevent fraud during self inspection and covers variety of scenarios by providing the choice of creating link expiry options avaliable as per client's need.",
    },
    {
      image_url: "./images/md_si_icon4.svg",
      heading: "Journey Automation, Geo Location, Date & Time Stamps",
      para: "SIA has automation at its heart and our inspection journeys are extremely simple with minimum inputs from the clients. All the inspections will get linked with the Geo Location of the customer further attaching Date & Time Stamps on each picture/video.",
    },
    {
      image_url: "./images/md_si_icon5.svg",
      heading: " Inspection via SMS & Mobile Browser ",
      para: "The Inspection link is sent to the customers mobile phone, hence No App is required. The Inspection process is completely based on mobile browser and can be used on any smart phone with a camera.",
    },
    {
      image_url: "./images/md_si_icon6.svg",
      heading: "Realtime Assistance",
      para: "Since new technology may require help to use for some customers. Insurvent also has a assistance model available for the users where users are assisted in realtime through our call centre to get the self inspection done.",
    },
    {
      image_url: "./images/md_si_icon7.svg",
      heading:
        "Quality Control, Low bandwidth Tech & Easy to use API integrations",
      para: "Verification of inspection requests based on insurer's guildlines along with usage of propreitary compression technology for better access to networks even 2G/3G & presence pf easy to use API's for integration iwth any sales channel, B2C etc is a guarantee with SIA solution.",
    },
    {
      image_url: "./images/md_si_icon8.svg",
      heading: "Custom Dashboard, Inspection Reports & Custom Reports",
      para: "SIA comes with a host of business intelligence and Analytics features enabling clients to build or automate inspection reports for underwriting records and data management against each request. Clients are also able to get customised reports with automated and downloadable mechanisms.",
    },
  ];

  const uspSiArray = [
    {
      image_url: "./images/usp_si_icon1.svg",
      heading: "360-Degree Solution",
      desc: "A comprehensive solution that addresses the complete renewal process for intermediaries and their clients.",
    },
    {
      image_url: "./images/usp_si_icon2.svg",
      heading: "Works with every device",
      desc: "Enables easy self-inspections through mobile web browser, eliminating the need for additional applications.",
    },
    {
      image_url: "./images/usp_si_icon3.svg",
      heading: "Advanced Image Recognition",
      desc: "Utilizes cutting-edge technology for precise and efficient assessment of inspection data.",
    },
    {
      image_url: "./images/usp_si_icon4.svg",
      heading: "Efficient Underwriting",
      desc: "Speeds up underwriting processes, ensuring quicker policy renewals.",
    },
    {
      image_url: "./images/usp_si_icon5.svg",
      heading: "Compliance Assurance",
      desc: "Guarantees safety and coverage standards are met, reducing the risk of coverage gaps.",
    },
    {
      image_url: "./images/usp_si_icon6.svg",
      heading: "Enhanced Customer Experience",
      desc: "Provides a seamless and user-friendly experience for clients, boosting satisfaction.",
    },
    {
      image_url: "./images/usp_si_icon7.svg",
      heading: "Administrative Simplification",
      desc: "Streamlines operations, reducing administrative burdens for intermediaries.",
    },
    {
      image_url: "./images/usp_si_icon8.svg",
      heading: "Competitive Advantage",
      desc: "Strengthens intermediaries' competitiveness in the digital insurance market.",
    },
    {
      image_url: "./images/usp_si_icon9.svg",
      heading: "Future-Ready Solution",
      desc: "Adaptable to evolving industry needs, keeping intermediaries ahead of the curve.",
    },
  ];

  return (
    <>
      <MNavbar />

      <Box className="productpage-wrapper">
        <Box
          className="prouct-header"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            paddingTop={"40px"}
            paddingBottom={"16px"}
          >
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h2>
                Revolutionize<span>Renewals with Digital Inspections:</span> Your Path to Efficiency and Customer Satisfaction
              </h2>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="product_header_img">
                <img
                  src="./images/self_inspection_header_icon.svg"
                  alt="self-inspection"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="product_info_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"12px"}>
              <Box className="inner_box">
                <img src="./images/sia_sec_icon.svg" alt="" />
                <h3 className="mb-2 mt-3">
                  What is <span>SIA?</span>
                </h3>
                <p>
                  Our cutting-edge SIA, is tailored exclusively for
                  intermediaries, addressing the challenge of expired/lapsed car
                  policy renewals where mandatory inspections are required. This
                  innovative 360 Degree solution empowers intermediaries and
                  their clients by facilitating straightforward self-inspections
                  through mobile devices without the need for an additional
                  application. Leveraging advanced image recognition and data
                  analysis, SIA expedites underwriting processes, verifies
                  safety and coverage compliance, and enhances the overall
                  customer experience. By reducing administrative complexities
                  and preventing coverage gaps, it enables intermediaries to
                  boost customer satisfaction and retention, strengthening their
                  position in the competitive digital insurance landscape.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="module_desc_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3>
                Module & its <span>Description</span>
              </h3>
            </Grid>
            <Grid xs={12}>
              {mdSelfInspectionArray.map((data) => (
                <Box className="si_module_desc_box">
                  <img src={data.image_url} alt="" />
                  <h5 className="mt-4 mb-3">{data.heading}</h5>
                  <p>{data.para}</p>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>

        <Box
          className="usp_section"
          padding={"20px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h3>
                Our <span>Unique Selling Point!</span>
              </h3>
            </Grid>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <img src="./images/USP.svg" alt="" style={{ margin: "auto" }} />
            </Grid>
            <Grid xs={12}>
              <ul className="uspSIlist">
                {uspSiArray.map((data) => (
                  <li>
                    <img src={data.image_url} alt="" />
                    <Box>
                      <h5>{data.heading}</h5>
                      <p>{data.desc}</p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <MRequestDemo />
      </Box>

      <MFooter />
    </>
  );
};

export default MSelfInspection;
