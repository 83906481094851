import React, {useState} from "react";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../ProductPages/ProductPages.scss";
import RequestDemo from "../../../../Components/Desktop/RequestDemo/RequestDemo";
import Footer from "../../../../Components/Desktop/Footer/Footer";

function Erp() {
  const navbarStyle = { backgroundColor: "#6458D8", color: "#ffffff" };
  const image = "./images/insurevent_logo_white.svg";

  const uspErpArray = [
    {
      image: "./images/usp_erp_icon1.svg",
      heading:
        "Seamless integration of ERP and Broker Management System enhances data synchronization and eliminates data silos.",
    },
    {
      image: "./images/usp_erp_icon2.svg",
      heading:
        "Real-time access to critical information for informed decision-making.",
    },
    {
      image: "./images/usp_erp_icon3.svg",
      heading:
        "Advanced reporting and analytics capabilities for actionable insights.",
    },
    {
      image: "./images/usp_erp_icon4.svg",
      heading:
        "Customizable solution to adapt to the unique needs and processes of insurance intermediaries.",
    },
    {
      image: "./images/usp_erp_icon5.svg",
      heading:
        "Improved collaboration and communication among team members and stakeholders.",
    },
    {
      image: "./images/usp_erp_icon6.svg",
      heading:
        "Scalable solution to accommodate business growth and expansion.",
    },
    {
      image: "./images/usp_erp_icon7.svg",
      heading: "Simplified compliance management and regulatory adherence. ",
    },
    {
      image: "./images/usp_erp_icon8.svg",
      heading:
        "Enhanced customer experience through efficient policy management and claims processing.",
    },
    {
      image: "./images/usp_erp_icon9.svg",
      heading: "Reduced operational costs and increased productivity.",
    },
    {
      image: "./images/usp_erp_icon10.svg",
      heading:
        "Dedicated support and training to ensure successful implementation and adoption.",
    },
    {
      image: "./images/usp_erp_icon11.svg",
      heading:
        "Stay ahead of industry trends with regular updates and feature enhancements.",
    },
    {
      image: "./images/usp_erp_icon12.svg",
      heading:
        "Industry expertise and best practices built into the solution to drive success.",
    },
    {
      image: "./images/usp_erp_icon13.svg",
      heading:
        "Gain a competitive advantage by leveraging cutting-edge technology for insurance intermediaries.",
    },
  ];

  const [showUspPoints, setShowUspPoints] = useState(false);
  const defaultItemshow = 4;

  const modulelist = [
    {
      image: "./images/erp_module_img5.svg",
      heading: "Analytics and Reporting",
      description:
        "InsurVent ERP solution provides real-time analytics and reporting, giving you insight into key metrics such as policy sales, retention rates, and claims processing times, helping you make data-driven business decisions.",
      classvalue: "module_box",
    },
    {
      image: "./images/erp_module_img2.svg",
      heading: "Claims Processing",
      description:
        "ERP software features claims processing tools that allow you to manage and track their status online, making the process faster and more convenient for both you and yInsurVent customers.",
      classvalue: `module_box ${"space_top"}`,
    },
    {
      image: "./images/erp_module_img3.svg",
      heading: "Real-Time Analytics",
      description:
        "ERP software provides real-time analytics, providing insight into key metrics such as policy sales, retention rates, and claims processing times, helping you make data-driven business decisions.",
      classvalue: "module_box",
    },
    {
      image: "./images/erp_module_img4.svg",
      heading: "Customizable Workflow",
      description:
        "ERP software allows you to customize workflows, creating a more efficient and streamlined business process that meets the specific needs of your digital insurance business.",
      classvalue: "module_box",
    },
    {
      image: "./images/erp_module_img1.svg",
      heading: "Digital Policy Management System",
      description:
        "ERP solution offers policy management tools that allow you to manage policies, including renewals and endorsements, more efficiently.",
      classvalue: `module_box ${"space_top"}`,
    },
    {
      image: "./images/erp_module_img6.svg",
      heading: "Customer Engagement",
      description:
        "ERP software features tools for customer engagement, including digital communication channels and self-service options, helping you provide your customers with a more personalized and innovative insurance buying experience.",
      classvalue: "module_box",
    },
  ];
 
  return (
    <>
      <Navbar
        style={navbarStyle}
        image={image}
        class1="whiteMenu"
        border="border"
      />

      <Box className="productPage_wrapper">
        <Box className="productHeader_section" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid sm={12} md={6} lg={6} alignSelf={"center"}>
              <h1>
                Empowering intermediaries with comprehensive{" "}
                <span>Insurance Management System for Brokers.</span>
              </h1>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              textAlign={"end"}
              className="text-md-center"
            >
              <img src="./images/erp_product_image.svg" alt="erp" />
            </Grid>
          </Grid>
        </Box>

        <Box className="productInfo_wrapper" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12}>
              <Box className="productInfo_section">
                <Grid container spacing={3} className="row">
                  <Grid xs={6}>
                    <img src={"./images/erp_image.svg"} alt="erp_desc" />
                  </Grid>
                  <Grid xs={6} alignSelf={"center"}>
                    <h6>
                      What is <span>ERP?</span>
                    </h6>
                    <p>
                      Experience seamless insurance brokerage operations with
                      our powerful InsurTech solution. By integrating ERP and a
                      Broker Management System, we provide brokers with a
                      comprehensive platform to manage customer relationships,
                      policies, claims, and finances effortlessly. Our solution
                      streamlines workflows, reduces manual tasks, ensures
                      accurate data, and boosts overall efficiency. Bid farewell
                      to fragmented systems and embrace our integrated InsurTech
                      solution for a streamlined and successful brokerage
                      experience.
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="erpmodule_description" marginBottom={"80px"}>
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h6 className="heading mb-20">
                Module & its <span>Description</span>
              </h6>
            </Grid>

            {modulelist.map((data, index) => (
              <Grid sm={6} md={6} lg={4} textAlign={"center"}>
                <div className={data.classvalue}>
                  <div className="inner_box">
                    <img src={data.image} />
                  </div>
                  <div className="content_box">
                    <h5>{data.heading}</h5>
                    <p>{data.description}</p>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container columnSpacing={3} rowSpacing={0} className="row">
          <Grid xs={12}>
            <Box
              className="usp_section "
              marginBottom={"80px"}
              padding={"40px 28px"}
            >
              <Grid
                container
                rowSpacing={0}
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid xs={6} marginBottom={"28px"}>
                  <h6 className="heading mb-6">
                    Our <span>Unique Selling Point!</span>
                  </h6>
                  <img
                    src="./images/usp_erp.svg"
                    alt=""
                    style={{ margin: "auto" }}
                  />
                </Grid>
                <Grid xs={6}>
                  <ul className="uspErpList">
                    {(showUspPoints === true
                      ? uspErpArray
                      : uspErpArray.slice(0, defaultItemshow)
                    ).map((data, index) => (
                      <li>
                        <Box className="iconBox">
                          <img src={data.image} alt="" />
                        </Box>
                        <p>{data.heading}</p>
                      </li>
                    ))}
                    {uspErpArray.length > defaultItemshow && (
                      <Link
                        color={"#6458D8"}
                        onClick={() => setShowUspPoints(!showUspPoints)}
                      >
                        {showUspPoints === true ? "Read Less" : "Read More"}
                      </Link>
                    )}
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <RequestDemo
          formHeading={
            <>
              Request a<span> Demo</span>
            </>
          }
          formDesc={
            "Experience the Future: Request a Demo and Witness the Power of Our Solutions in Action!"
          }
          formImg_url={"./images/request_demo_img.svg"}
        />
      </Box>
      <Footer />
    </>
  );
}

export default Erp;
