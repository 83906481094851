import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MCMSPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";

const MAboutus = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const ourgoalsArray = [
    {
      image_url: "./images/ourgoals_image1.png",
      heading: "Our Vision",
      para: "Our vision is to be the leading provider of enterprise solutions for the insurtech industry. We aim to empower brokers, insurers and customers alike by developing innovative technology that streamlines the insurance process. Our ultimate goal is to help in navigation of the increasingly complex world of insurance with ease and confidence.",
    },
    {
      image_url: "./images/ourgoals_image2.png",
      heading: "Our Mission",
      para: "Our mission is to empower insurance world stakeholders with cutting-edge software solutions that drive efficiency and innovation in the industry. We aim to deliver future-proof, user-friendly and customizable software products that strive to streamline operations, improve risk management and enhance overall customer experience",
    },
    {
      image_url: "./images/ourgoals_image3.png",
      heading: "Our Expertise",
      para: "We Build products and solutions for companies to solve complex business problems. Our talented team of engineers and product experts formulate, design, develop, and market your products. We utilize technology to make the process of buying and managing insurance efficient, smooth and user-friendly.",
    },
  ];
  const journeyDetailArray = [
    {
      journeyYear: "2025",
      journeyDetail: "Global Leaders Insurtech Products",
    },
    {
      journeyYear: "2020",
      journeyDetail:
        "Achieved Premium benchmark of USD 1M throgh online sales.",
    },
    {
      journeyYear: "2016",
      journeyDetail: "Turned into Insurtech  Provider",
    },
    {
      journeyYear: "2015",
      journeyDetail: "Setup Customer contact center with Tele-assisted sales",
    },
    {
      journeyYear: "2013",
      journeyDetail:
        "Started insurance web aggregation portal “Quickbima.com” ",
    },
  ];

  return (
    <>
      <MNavbar />

      <Box className="cmspage-wrapper">
        <Box
          className="top-header"
          padding={"0px 12px"}
          marginTop={"40px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
              <h3 className="mb-2">
                About <span> Us</span>
              </h3>
              <p>
                We at Insurvent, believe in revolutionizing the insurance
                industry through our cutting-edge technology. Our team of
                software engineers, and product experts come together to
                synergize their skills and make the process of purchasing and
                managing insurance policies easy and convenient.
              </p>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="about_img">
                <img src="./images/aboutus_img.png" alt="about" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="our_goals_sec"
          padding={"0px 12px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
              <h3 className="mb-2">
                Our <span> goals</span>
              </h3>
              <p>
                We believe that everyone deserves access to affordable and
                comprehensive insurance coverage thus we strive to bridge this
                gap between different stakeholders of the insurance world.
              </p>
            </Grid>
            <Grid xs={12}>
              {ourgoalsArray.map((data) => (
                <Box className="id_box">
                  <Box className="icon_box">
                    <span>
                      <img src={data.image_url} alt="" />
                    </span>
                  </Box>

                  <h5>{data.heading}</h5>
                  <p>{data.para}</p>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>

        <Box
          className="our_founders_sec"
          padding={"0px 12px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
              <h3 className="mb-2">
                Our <span> Founders</span>
              </h3>
              <p>
                “To accomplish great things, we must not only dream, but also
                plan, not only believe, but sincerely act."
              </p>
            </Grid>
            <Grid xs={12} marginBottom={"16px"}>
              <Box className="f_desc_box">
                <Box className="profile_box">
                  <img src="./images/sunil_image.svg" alt="" />
                </Box>
                <h5>- Sunil Juneja</h5>
                <p>
                  {" "}
                  “At Evervent, we have been into the insurance distribution
                  business for more than a decade and being an early creators of
                  digital distribution technology, we understand the nuances of
                  insurtech to the core. We are fortunate to be a part of the
                  digital journeys of our clients, helping them transform their
                  insurance business.”
                </p>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box className="f_desc_box">
                <Box className="profile_box">
                  <img src="./images/ankit_image.svg" alt="" />
                </Box>
                <h5>- Ankit Sachdeva</h5>
                <p>
                  “Acceleration in Digital innovation is transforming the
                  Fintech industry worldwide. We are already witnessing use of
                  A.I and block chain to solve complex problems in Insurance.
                  Disruptive and future ready businesses will be those who have
                  technology at the heart of their company.”
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="our_journey_sec"
          padding={"0px 12px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
              <h3 className="mb-2">
                Our <span> Journey</span>
              </h3>
              <p>
                “ The Journey of a thousand miles begins with one step” - Lao
                Tzu
              </p>
            </Grid>
            <Grid xs={12} className="journey_detail_outer" paddingTop={"16px"} paddingBottom={"16px"}>
              <ul className="journey_detail_list">
                {journeyDetailArray.map((data) => (
                  <li>
                    <img src="./images/journey_locate_icon.svg" alt="" />
                    <Box className="d_box">
                      <h6>{data.journeyYear}</h6>
                      <p>{data.journeyDetail}</p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="our_team_sec"
          padding={"0px 12px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"40px"}>
              <h3 className="mb-2">
                Our <span> Team</span>
              </h3>
              <p>
                We believe in the magic of team strength. We are a team of 60+
                employees, mainly software engineers, designers and testing
                professionals & this number is only growing. We provide a
                professional environment, numerous learning opportunities & a
                creative space for its employees & that's why people love
                working here. We have one of the best employee retention rates
                and we take pride in being equal opportunity provider.
              </p>
            </Grid>
            <Grid xs={12} className="team_pics">
              <div className="grid grid-cols-2 grid-rows-4 gap-3">
                <div className="gridBox">
                  <img src="./images/team_pic1.svg" alt="" />
                </div>
                <div className="row-span-2 gridBox">
                  <img src="./images/team_pic2.svg" alt="" />
                </div>
                <div className="gridBox">
                  <img src="./images/team_pic3.svg" alt="" />
                </div>
                <div className="col-span-2 row-start-3 gridBox">
                  <img src="./images/team_pic4.svg" alt="" />
                </div>
                <div className="row-start-4 gridBox">
                  <img src="./images/team_pic5.svg" alt="" />
                </div>
                <div className="row-start-4 gridBox">
                  <img src="./images/team_pic6.svg" alt="" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <MFooter />
    </>
  );
};

export default MAboutus;
