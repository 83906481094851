import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MProductPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MRequestDemo from "../../../../Components/Mobile/MRequestDemo/MRequestDemo";

const MCrm = () => {
  const crmmodulelist = [
    {
      image: "./images/crmmodule_image1.png",
      heading: "Overview",
      innerlist: [
        {
          innerPoint:
            " Sales dashboard provides a comprehensive view of the generated business",
        },
      ],
    },
    {
      image: "./images/crmmodule_image2.png",
      heading: "Upcoming Renewals",
      innerlist: [
        {
          innerPoint: "Stay informed about upcoming policy renewals.",
        },
        {
          innerPoint:
            "Plan and prioritize renewal activities to ensure customer satisfaction.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image3.png",
      heading: "Offline Policy Booking",
      innerlist: [
        {
          innerPoint: "Ability to book policies offline for customer convenience.",
        },
        {
          innerPoint:
            "Maintain a record of policies booked through offline channels.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image4.png",
      heading: "Business Analytics",
      innerlist: [
        {
          innerPoint: "View overall business generated by insurance companies.",
        },
        {
          innerPoint:
            "Analyze business performance based on insurance product categories.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image5.png",
      heading: "In-Progress Leads",
      innerlist: [
        {
          innerPoint:
            "Monitor leads that are currently being processed or worked on.",
        },
        {
          innerPoint: "Track the progress of leads through various stages.",
        },
      ],
    },
    {
      image: "./images/crmmodule_image1.png",
      heading: "Lead Management",
      innerlist: [
        {
          innerPoint:
            "Efficient lead management system helps address the challenge of managing & effectively engaging prospective buyers",
        },
      ],
    },
    {
      image: "./images/crmmodule_image2.png",
      heading: "Streamlined Policy Issuance",
      innerlist: [
        {
          innerPoint:
            "Policies are issued without requiring client details to be re-entered, their status & dispositions are seamlessly managed",
        },
        {
          innerPoint: "Reduces duplication of efforts and saves time.",
        },
      ],
    },
  ];

  const uspcrmlistArray = [
    {
      point_dsec: (
        <>
          Comprehensive overview of{" "}
          <span> Business Performance and Analytics.</span>
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Seamless <span>offline policy booking</span> for customer convenience.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Efficient management of <span> in-progress leads </span> and
          <span> upcoming renewals.</span>
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Accurate <span>tracking of commission </span> for better financial
          insights.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Clear <span>visibility of leads </span> in progress.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          <span>Timely Policy Issuance </span> without any hassle.
        </>
      ),
    },
    {
      point_dsec: (
        <>
          Enhanced efficiency for <span>reduction in redundant data </span>{" "}
          entry tasks.
        </>
      ),
    },
  ];

  return (
    <>
      <MNavbar />

      <Box className="productpage-wrapper">
        <Box
          className="prouct-header"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            paddingTop={"40px"}
            paddingBottom={"16px"}
          >
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h2>
                Elevating intermediary excellence for <span>unmatched client relationships</span>
              </h2>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box className="product_header_img">
                <img src="./images/crm_header_img.svg" alt="about" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="product_info_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"12px"}>
              <Box className="inner_box">
                {/* <img src="./images/posp_sec_icon.svg" alt="" /> */}
                <h3 className="mb-2 mt-3">
                  What is <span>CRM?</span>
                </h3>
                <p className="mb-5" style={{ textAlign: "justify" }}>
                  Our CRM solution is a game-changer for intermediaries,
                  revolutionizing their sales and productivity. By centralizing
                  lead management and offering comprehensive customer
                  relationship management, intermediaries can effectively
                  convert leads and deliver personalized services, fostering
                  higher satisfaction and loyalty. Streamlined policy management
                  and automated workflows reduce manual effort and enhance
                  accuracy.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Robust analytics provide real-time insights for data-driven
                  decision-making, optimizing sales strategies and driving
                  revenue growth. With seamless collaboration and mobile
                  accessibility, brokers can work efficiently and respond
                  promptly to client needs. Additionally, our CRM enables easy
                  access to insurance quotes, comparison, and payment link
                  sharing from a single dashboard, simplifying the sales process
                  and enhancing customer experience. 
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="crm_module_desc_section"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
              <h3 className="mb-2">
                Module & its <span>Description</span>
              </h3>
              <p>Unlock the Full Potential of Our Feature-Packed Solutions!</p>
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <img
                src="./images/crm_module_img.svg"
                alt=""
                className="module_sec_img"
              />
            </Grid>
            <Grid xs={12}>
              <ul>
                {crmmodulelist.map((data, index) => (
                  <li>
                    <img src={data.image} alt="" />
                    <Box className="module_point_box">
                      <h6>{data.heading}</h6>
                      <ul className="points_list">
                        {data.innerlist.map((innerdata) => (
                          <li>{innerdata.innerPoint}</li>
                        ))}
                      </ul>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="usp_section uspCrm"
          padding={"0px 12px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} marginBottom={"28px"}>
              <h3 className="mb-2">
                USP & <span>Benefits</span>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </Grid>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <img
                src="./images/crm_benefits_img2.svg"
                alt=""
                style={{ margin: "auto" }}
              />
            </Grid>
            <Grid xs={12}>
              <ul className="uspCrmList">
                {uspcrmlistArray.map((data, index) => (
                  <li>
                    <img src="./images/yellow_tick_icon.svg" alt="" />
                    <p className="mt-2">{data.point_dsec}</p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <MRequestDemo />
      </Box>

      <MFooter />
    </>
  );
};

export default MCrm;
