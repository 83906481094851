import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./../MCMSPages.scss";
import MNavbar from "../../../../Components/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Components/Mobile/MFooter/MFooter";
import MRequestDemo from "../../../../Components/Mobile/MRequestDemo/MRequestDemo";
import CountUp from "react-countup";

const MOurInsurancePartner = () => {

    const insurer_partner = [
      {
        img: "./images/insurerlogo/aditya_birla_capital.svg",
      },

      {
        img: "./images/insurerlogo/AEGON-Religare-Life-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Agriculture-Insurance-Co-of-India-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Apollo-Munich-Health-Insurance-Company-Limited.svg",
      },

      {
        img: "./images/insurerlogo/Aviva-Life-Insurance-Company-India-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Bajaj-Allianz-General-Insurance-Co-Ltd.svg",
      },

      {
        img: "./images/insurerlogo/Bharti-AXA-Life-Insurance-Company-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Cholamandalam-MS-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/CignaTTK-Health-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Edelweiss-Tokio-Life-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Future-Generali-India-Life-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Go-Digit-General-Insurance.svg",
      },
      {
        img: "./images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/IDBI-Federal-Life-Insurance-Company-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/IndiaFirst-Life-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/ING-Vysya-Life-Insurance-Company-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/kotak_general_insurance.svg",
      },
      {
        img: "./images/insurerlogo/L_and_T_General_Insurance_Company_Limited.svg",
      },
      {
        img: "./images/insurerlogo/Liberty-Videocon-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Magma-HDI-General-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Max-Bupa-Health-Insurance-Company-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Raheja-QBE.svg",
      },
      {
        img: "./images/insurerlogo/Reliance-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Reliance-Life-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Royal_Sundaram.svg",
      },
      {
        img: "./images/insurerlogo/sbi_general_insurance.svg",
      },
      {
        img: "./images/insurerlogo/sbi-life-insurance.svg",
      },
      {
        img: "./images/insurerlogo/Shriram-General-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Star-Health-and-Allied-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Tata-AIA-Life-Insurance.svg",
      },
      {
        img: "./images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
      },
      {
        img: "./images/insurerlogo/Tata-AIG-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/the_new_india_insurance_co_ltd.svg",
      },
      {
        img: "./images/insurerlogo/The-Oriental-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/United-India-General-Insurance-Co-Ltd.svg",
      },
      {
        img: "./images/insurerlogo/Universal-Sompo-General-Insurance-Co-Ltd.svg",
      },
    ];

  return (
    <>
      <MNavbar />

      <Box className="cmspage-wrapper">
        <Box
          className="top-header"
          padding={"0px 12px"}
          marginTop={"40px"}
          marginBottom={"40px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"}>
              <h3 className="mb-2">
                Our Insurance <span>Partners</span>
              </h3>
              <p>
                “Forging unbreakable bonds of trust with insurers, we power a
                future where technology and partnership redefine the insurance
                landscape”
              </p>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="partners-section"
          padding={"0px 12px"}
          marginBottom={"50px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} textAlign={"center"}>
              <ul>
                {insurer_partner.map((data, index) => (
                  <li>
                    <img src={data.img} />
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="achievements_counter"
          marginBottom={"40px"}
          padding={"30px 12px"}
        >
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12}>
              <ul>
                <li>
                  <span>
                    <CountUp end={10} duration={5} /> +
                  </span>
                  <br />
                  Years of Experience
                </li>
                <li>
                  <span>
                    <CountUp end={2500} duration={5} />{" "}
                    <span className="inner_span">+</span>
                  </span>
                  <br />
                  Integrations
                </li>
                <li>
                  <span>
                    <CountUp end={25} duration={5} />{" "}
                    <span className="inner_span">+</span>
                  </span>
                  <br />
                  Projects Completed
                </li>
                <li>
                  <span>
                    <CountUp end={100} duration={5} />
                    <span className="inner_span">+</span>
                  </span>
                  <br />
                  Complex Customisations
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <MRequestDemo />
      </Box>

      <MFooter />
    </>
  );
};

export default MOurInsurancePartner;
