import React, { useState } from "react";
import { Box, Link, Rating, Stack, Tab, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MRequestDemo.scss";
import CustomButton from "../../CustomButton/CustomButton";
import RKTextField from "../../FieldTypes/RKTextField/RKTextField";

const MRequestDemo = () => {
    const [quoteFields, setQuoteFields] = useState<{
      name: { value: string; warning: boolean };
      organization: { value: string; warning: boolean };
      email: { value: string; warning: boolean };
      mobile: { value: string; warning: boolean };
      message: { value: string; warning: boolean };
    }>({
      name: { value: "", warning: false },
      organization: { value: "", warning: false },
      email: { value: "", warning: false },
      mobile: { value: "", warning: false },
      message: { value: "", warning: false },
    });

    const fieldsUpdateState = (attrName: any, value: any) => {
      attrName[3]({
        ...attrName[2],
        [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
      });
    };

  return (
    <>
      <Box className="requestDemo" marginBottom={"40px"} padding={"0px 12px"}>
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid xs={12} textAlign={"center"}>
            <h3 className="mb-5">
              Request a <span>Demo</span>
            </h3>
            <p className="mb-5">
              Experience the Future: Request a Demo and Witness the Power of Our
              Solutions in Action!
            </p>
          </Grid>
          <Grid xs={12}>
            <Box className="img-sec" marginBottom={"24px"}>
              <img src="./images/request_demo_img.png" alt="" />
            </Box>
            <Box className="field-sec">
              <Grid container rowSpacing={0} columnSpacing={3}>
                <Grid xs={12} className="mb-5">
                  <RKTextField
                    class_name="inputField"
                    title={"Name"}
                    value={quoteFields.name.value}
                    attrName={["name", "value", quoteFields, setQuoteFields]}
                    value_update={fieldsUpdateState}
                    warn_status={quoteFields.name.warning}
                    error_messg="Please enter name"
                  />
                </Grid>
                <Grid xs={12} className="mb-5">
                  <RKTextField
                    class_name="inputField"
                    title={"Organization"}
                    value={quoteFields.organization.value}
                    attrName={[
                      "organization",
                      "value",
                      quoteFields,
                      setQuoteFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={quoteFields.organization.warning}
                    error_messg="Please enter organization"
                  />
                </Grid>

                <Grid xs={12} className="mb-5">
                  <RKTextField
                    class_name="inputField"
                    title={"Phone Number"}
                    value={quoteFields.mobile.value}
                    attrName={["mobile", "value", quoteFields, setQuoteFields]}
                    value_update={fieldsUpdateState}
                    warn_status={quoteFields.mobile.warning}
                    error_messg="Please enter mobile number"
                  />
                </Grid>
                <Grid xs={12} className="mb-5">
                  <RKTextField
                    class_name="inputField"
                    title={"Email Id"}
                    value={quoteFields.email.value}
                    attrName={["email", "value", quoteFields, setQuoteFields]}
                    value_update={fieldsUpdateState}
                    warn_status={quoteFields.email.warning}
                    error_messg="Please enter email"
                  />
                </Grid>
                <Grid xs={12} className="mb-5">
                  <RKTextField
                    class_name="inputField"
                    title={"Message"}
                    value={quoteFields.message.value}
                    attrName={["message", "value", quoteFields, setQuoteFields]}
                    value_update={fieldsUpdateState}
                    warn_status={quoteFields.message.warning}
                    error_messg="Please enter message"
                  />
                </Grid>
                <Grid xs={12} textAlign="center" className="ctaBtn">
                  <CustomButton text_name={"Submit"} class_name="blueLgBtn" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MRequestDemo;
