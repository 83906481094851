import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MIntegrationServices from "../../Pages/Mobile/MProductPages/MIntegartionServices/MIntegrationServices";
import IntegrationServices from "../../Pages/Desktop/ProductPages/IntegrationServices/IntegrationServices";

const IntegrationServicesContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MIntegrationServices /> : <IntegrationServices />;
};

export default IntegrationServicesContainer;
